/** @jsxImportSource @emotion/react */
import React from "react";
import { useParams } from "react-router-dom";
import BreadCrumbsLink from "../../../components/landing/BreadCrumbsLink";
import { css } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import { getPortfolioById } from "../../../redux/features/landing/marketing";
import PortfolioDetail from "../../../components/landing/PortfolioDetail";

export default function PortfolioMarketing() {
  let params = useParams();
  const portfolio_id_data = useSelector(
    (state) => state.marketingLandingReducer.portfolio_id_data
  );
  const loading_portfolio_id = useSelector(
    (state) => state.marketingLandingReducer.loading_portfolio_id
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPortfolioById(params.portfolioId));
  }, [dispatch, params]);

  const breadcrumbData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Marketing Communications",
      link: "/marketing-communications",
    },
    {
      title: "Portfolio",
      link: "",
    },
  ];

  return (
    <div>
      <div
        css={css`
          display: block;
          width: max-content;
          margin: 40px auto;
          @media only screen and (max-width: 720px) {
            margin: 20px auto;
          }
        `}
      >
        <BreadCrumbsLink titleBreadcrumbs={breadcrumbData} />
      </div>

      <PortfolioDetail
        data={portfolio_id_data}
        loading={loading_portfolio_id}
        backLink="/marketing-communications"
      />
    </div>
  );
}
