import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBanner = createAsyncThunk(
  "get/bannerSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/banner?flag=banner_spice_it_up`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const putImageBanner = createAsyncThunk(
  "put/imagebannerSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/banner/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getTitleDesc = createAsyncThunk(
  "get/TitleDescSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post/slug/spice-it-up`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getTitleDescCSM = createAsyncThunk(
  "get/TitleDescCSMSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post/slug/spice-it-up-content-strategy-management`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateTitleDesc = createAsyncThunk(
  "put/updateTitleDescSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    let params = {
      title: arg.title,
      description: arg.desc,
      linkedin: arg.instagram,
      youtube: arg.youtube,
      image: arg.image,
      post_type: "single_page",
    };
    if (params.image === null) {
      delete params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/slug/spice-it-up`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const updateTitleDescCSM = createAsyncThunk(
  "put/updateTitleDescCSMSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    let params = {
      title: arg.title,
      description: arg.desc,
      image: arg.image,
      post_type: "single_page",
    };
    if (params.image === null) {
      delete params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/slug/spice-it-up-content-strategy-management`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

//
//
// Portfolio
export const getPortfolio = createAsyncThunk(
  "get/portfolioSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post?post_type=spice_it_up_portfolio`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createPortfolio = createAsyncThunk(
  "create/portfolioSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/post`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.brandName,
          description: arg.brandDescription,
          post_type: "spice_it_up_portfolio",
          image: arg.youtubeLink ? "" : arg.image,
          detail: arg.portfolioDetails,
          youtube: arg.youtubeLink,
          post_images: arg.postImages,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updatePortfolio = createAsyncThunk(
  "update/portfolioSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    arg.postImages = arg.postImages.map((el) => {
      if (el.image.includes("https")) {
        return { image: null };
      } else {
        return { image: el.image };
      }
    });
    const data_params = {
      title: arg.brandName,
      description: arg.brandDescription,
      detail: arg.portfolioDetails,
      post_type: "spice_it_up_portfolio",
      image: arg.youtubeLink ? "" : arg.image,
      youtube: arg.youtubeLink,
      post_images: arg.postImages,
    };
    if (data_params.image.includes("http")) {
      delete data_params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data_params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deletePortfolio = createAsyncThunk(
  "delete/portfolioSpiceAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const spiceItUpSlice = createSlice({
  name: "spiceItUpSlice",
  initialState: {
    loading_banner: false,
    banner_data: [],
    loading_upload_banner: false,

    loading: false,
    loading_upload: false,
    title: "",
    description: "",
    instagram: "",
    youtube: "",
    image_spice: [],

    loading_csm: false,
    loading_upload_csm: false,
    title_csm: "",
    description_csm: "",
    image_csm: [],

    portfolio_datas: [],
    loading_portfolio: false,
    loading_update_portfolio: false,
  },
  reducers: {
    addMore: (state) => {},
  },
  extraReducers: {
    [getBanner.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBanner.rejected]: (state, action) => {
      state.loading_banner = false;
    },
    //   ---------- || ----------
    [putImageBanner.pending]: (state, { payload }) => {
      state.loading_upload_banner = true;
    },
    [putImageBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
      } else {
      }
      state.loading_upload_banner = false;
    },
    [putImageBanner.rejected]: (state, action) => {
      state.loading_upload_banner = false;
    },
    //   ---------- || ----------
    [getTitleDesc.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getTitleDesc.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.title = payload.data.title;
        state.description = payload.data.description;
        state.instagram = payload.data.linkedin;
        state.youtube = payload.data.youtube;
        state.image_spice = [
          {
            id: payload.data.id,
            image: payload.data.image,
          },
        ];
      } else {
        state.title = "";
        state.description = "";
        state.image_spice = [];
      }
      state.loading = false;
    },
    [getTitleDesc.rejected]: (state, action) => {
      state.loading = false;
    },
    // --- || ---
    [getTitleDescCSM.pending]: (state, { payload }) => {
      state.loading_csm = true;
    },
    [getTitleDescCSM.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.title_csm = payload.data.title;
        state.description_csm = payload.data.description;
        state.image_csm = [
          {
            id: payload.data.id,
            image: payload.data.image,
          },
        ];
      } else {
        state.title_csm = "";
        state.description_csm = "";
        state.image_csm = [];
      }
      state.loading_csm = false;
    },
    [getTitleDescCSM.rejected]: (state, action) => {
      state.loading_csm = false;
    },
    // --- || ---
    [updateTitleDesc.pending]: (state, { payload }) => {
      state.loading_upload = true;
    },
    [updateTitleDesc.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_upload = false;
    },
    [updateTitleDesc.rejected]: (state, action) => {
      state.loading_upload = false;
    },
    // --- || ---
    [updateTitleDescCSM.pending]: (state, { payload }) => {
      state.loading_upload_csm = true;
    },
    [updateTitleDescCSM.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_upload_csm = false;
    },
    [updateTitleDescCSM.rejected]: (state, action) => {
      state.loading_upload_csm = false;
    },
    //
    //
    //
    //   Portfolio || ----------
    [getPortfolio.pending]: (state, { payload }) => {
      state.loading_portfolio = true;
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_datas = payload.data;
      } else {
        state.portfolio_datas = [];
      }
      state.loading_portfolio = false;
    },
    [getPortfolio.rejected]: (state, action) => {
      state.loading_portfolio = false;
    },
    [createPortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [createPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [createPortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [updatePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [updatePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [updatePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [deletePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [deletePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [deletePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore } = spiceItUpSlice.actions;

export default spiceItUpSlice.reducer;
