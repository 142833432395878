import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import ScrollToTop from "./components/scrollToTop";
// Pages
import Home from "./pages/home";
import PromotionProduction from "./pages/promotion-production";
import MarketingCommunications from "./pages/marketing/index";
import PublicRelation from "./pages/public-relation/index";
import SpiceItUp from "./pages/spice-it-up/index";
import Portfolio from "./pages/portfolio";
import CompanyPortfolio from "./pages/portfolio/company/index";
import PersonalPortfolio from "./pages/portfolio/personal/index";
import AboutUs from "./pages/about-us";
import News from "./pages/news";
import PortfolioPromotionProduction from "./pages/promotion-production/portfolio/_id";
import PortfolioMarketing from "./pages/marketing/portfolio/_id";
import PortfolioPublicRelation from "./pages/public-relation/portfolio/_id";
import PortfolioCompany from "./pages/portfolio/company/_id";
import PortfolioPersonal from "./pages/portfolio/personal/_id";
import PortfolioSpiceItUp from "./pages/spice-it-up/portfolio/_id";
// Global Components
import NavBar from "./components/landing/Navbar";
import Footer from "./components/landing/Footer";

// Authentication
import LayoutParentAuth from "./components/authentication/LayoutParent";
import LoginAuth from "./pages/authentication";
import ForgotPassword from "./pages/authentication/forgot-password";
import ResetPassword from "./pages/authentication/reset-password";

// Admin Pages
import DrawerAdmin from "./components/admin/SideBar";
import HomepageAdmin from "./pages/admin/homepage";
import AboutUsAdmin from "./pages/admin/about-us";
import PromotionAdmin from "./pages/admin/promotion";
import MarketingCommunicationAdmin from "./pages/admin/marketing-communication";
import PublicRelationAdmin from "./pages/admin/public-relation";
import SpiceItUpAdmin from "./pages/admin/spice-it-up";
import CompanyAdmin from "./pages/admin/company";
import PersonalAdmin from "./pages/admin/personal";
import NewsAdmin from "./pages/admin/news";
import MemberAdmin from "./pages/admin/member";
import ContactAdmin from "./pages/admin/contact";
import MessagesAdmin from "./pages/admin/messages";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

import Notfound from "./pages/notfound";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

// For GET requests
axios.interceptors.request.use(
  (req) => {
    // Add configurations here
    if (req.status === 200) {
    }
    return req;
  },
  (err) => {
    if (err.response.status === 401) {
      toast.error("Token is no longer valid, please logout and login again", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeButton: false,
      });
    } else {
      toast.error("An error occurred");
    }
    if (err.toJSON().message === "Network Error") {
      toast.error("Please check your connection", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeButton: false,
      });
    }
    return Promise.reject(err);
  }
);

// For POST requests
axios.interceptors.response.use(
  (res) => {
    // Add configurations here
    if (res.status === 200) {
    }
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      toast.error("Token is no longer valid, please logout and login again", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeButton: false,
      });
    } else {
      toast.error("An error occurred");
    }
    if (err.toJSON().message === "Network Error") {
      toast.error("Please check your connection", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeButton: false,
      });
    }
    return Promise.reject(err);
  }
);

let theme = createTheme({});
const themeDef = createTheme(theme, {
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#005486",
      darker: "#053e85",
    },
    secondary: {
      main: "#E35B29",
    },
    neutral: {
      main: "#FFFFFF",
      contrastText: "#fff",
    },
    inherit: {
      main: "#000",
    },
  },
  components: {
    MuiOutlinedInput: {
      variants: [
        {
          props: { variant: "inputauth" },
          style: {
            backgroundColor: "#f2f6f9",
            fontFamily: "'DM Sans', sans-serif",
            color: "#005486",
            height: "3em",
            border: "0",
          },
        },
      ],
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [theme.breakpoints.up("lg")]: {
            maxWidth: "1440px",
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "btn-admin" },
          style: {
            backgroundColor: "#F58127",
            color: "#FFFFFF",
            borderRadius: "8px !important",
            padding: "8px !important",
            "&:hover": {
              background: "#f15f28",
            },
          },
        },
        {
          props: { variant: "btn-admin-outline-orange" },
          style: {
            backgroundColor: "transparent",
            color: "#F58127",
            borderRadius: "8px !important",
            border: "1px solid #F58127",
            padding: "8px !important",
            "&:hover": {
              background: "#f6f6f6",
            },
          },
        },
        {
          props: { variant: "btn-admin-outline-red" },
          style: {
            backgroundColor: "transparent",
            color: "#FF0000",
            borderRadius: "8px !important",
            border: "1px solid #FF0000",
            padding: "8px !important",
            "&:hover": {
              background: "#f6f6f6",
            },
          },
        },
        {
          props: { variant: "btn-admin-outline-green" },
          style: {
            backgroundColor: "transparent",
            color: "#3CBB00",
            borderRadius: "8px !important",
            border: "1px solid #3CBB00",
            padding: "8px !important",
            "&:hover": {
              background: "#f6f6f6",
            },
          },
        },
        {
          props: { variant: "btn-admin-green" },
          style: {
            backgroundColor: "#3CBB00",
            color: "#FFFFFF",
            borderRadius: "8px !important",
            padding: "8px !important",
            "&:hover": {
              background: "#f15f28",
            },
          },
        },
        {
          props: { variant: "btn-admin-red" },
          style: {
            backgroundColor: "#FF0000",
            color: "#FFFFFF",
            borderRadius: "8px !important",
            padding: "8px !important",
            "&:hover": {
              background: "#f15f28",
            },
          },
        },
      ],
    },
  },
});

function LayoutLanding() {
  return (
    <React.Fragment>
      <NavBar />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
}

let loggedIn = localStorage.getItem("token");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={themeDef}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LayoutLanding />}>
            <Route index={true} element={<Home />} />

            <Route path="promotion-production">
              <Route index={true} element={<PromotionProduction />} />
              <Route index={false} path="portfolio">
                <Route
                  index={false}
                  path=":portfolioId"
                  element={<PortfolioPromotionProduction />}
                />
              </Route>
            </Route>

            <Route path="marketing-communications">
              <Route index={true} element={<MarketingCommunications />} />
              <Route index={false} path="portfolio">
                <Route
                  index={false}
                  path=":portfolioId"
                  element={<PortfolioMarketing />}
                />
              </Route>
            </Route>

            <Route path="public-relation">
              <Route index={true} element={<PublicRelation />} />
              <Route index={false} path="portfolio">
                <Route
                  index={false}
                  path=":portfolioId"
                  element={<PortfolioPublicRelation />}
                />
              </Route>
            </Route>

            <Route path="spiceitup">
              <Route index={true} element={<SpiceItUp />} />
              <Route index={false} path="portfolio">
                <Route
                  index={false}
                  path=":portfolioId"
                  element={<PortfolioSpiceItUp />}
                />
              </Route>
            </Route>

            <Route path="portfolio">
              <Route index={true} element={<Portfolio />} />

              <Route index={false} path="company">
                <Route index={true} element={<CompanyPortfolio />} />
                <Route index={false} path="portfolio">
                  <Route
                    index={false}
                    path=":portfolioId"
                    element={<PortfolioCompany />}
                  />
                </Route>
              </Route>

              <Route index={false} path="personal">
                <Route index={true} element={<PersonalPortfolio />} />
                <Route index={false} path="portfolio">
                  <Route
                    index={false}
                    path=":portfolioId"
                    element={<PortfolioPersonal />}
                  />
                </Route>
              </Route>
            </Route>

            <Route index={false} path="about-us" element={<AboutUs />} />
            <Route path="news">
              <Route index={false} path=":newsSlug" element={<News />} />
            </Route>
          </Route>

          {/* Authentication Pages */}
          <Route
            path="/authentication"
            element={
              !loggedIn ? (
                <LayoutParentAuth />
              ) : (
                <Navigate replace to="/admin" />
              )
            }
          >
            <Route index={true} element={<LoginAuth />} />
            <Route
              index={false}
              path="forgot-password"
              element={<ForgotPassword />}
            />
            <Route path="reset-password">
              <Route
                index={false}
                path=":tokenId"
                element={<ResetPassword />}
              />
            </Route>
          </Route>

          {/* Admin Pages */}
          <Route
            path="/admin"
            element={
              !loggedIn ? (
                <Navigate replace to="/authentication" />
              ) : (
                <DrawerAdmin />
              )
            }
          >
            <Route index={true} element={<HomepageAdmin />} />
            <Route index={false} path="about-us" element={<AboutUsAdmin />} />
            <Route
              index={false}
              path="promotion"
              element={<PromotionAdmin />}
            />
            <Route
              index={false}
              path="marketing-communication"
              element={<MarketingCommunicationAdmin />}
            />
            <Route
              index={false}
              path="public-relation"
              element={<PublicRelationAdmin />}
            />
            <Route
              index={false}
              path="spice-it-up"
              element={<SpiceItUpAdmin />}
            />
            <Route index={false} path="company" element={<CompanyAdmin />} />
            <Route index={false} path="personal" element={<PersonalAdmin />} />
            <Route index={false} path="news" element={<NewsAdmin />} />
            <Route index={false} path="member" element={<MemberAdmin />} />
            <Route index={false} path="contact" element={<ContactAdmin />} />
            <Route index={false} path="messages" element={<MessagesAdmin />} />
          </Route>
          <Route path="*" element={<Notfound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
