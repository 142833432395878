/** @jsxImportSource @emotion/react */
import React from "react";
import BannerTitleBGg from "../../components/landing/BannerTitleBGg";
import { css } from "@emotion/react";
import BreadCrumbsLink from "../../components/landing/BreadCrumbsLink";
import ContentSpiceItUp from "../../components/landing/spiceitup/ContentSpiceItUp";
import Contact from "../../components/landing/Contact";
import { useSelector, useDispatch } from "react-redux";
import { getBanner } from "../../redux/features/landing/company";
import { getBannerPersonal } from "../../redux/features/landing/personal";

export default function Portfolio() {
  const banner_data_personal = useSelector(
    (state) => state.personalLandingReducer.banner_data
  );

  const banner_data_company = useSelector(
    (state) => state.companyLandingReducer.banner_data
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBannerPersonal());
    dispatch(getBanner());
  }, [dispatch]);

  const contentDatas = [
    {
      id: 1,
      title: "Company",
      desc: "",
      socialMedia: false,
      ig: "",
      youtube: "",
      image: banner_data_company?.filter(
        (el) => el.slug === "banner_company_1"
      )[0]?.image,
      buttonLink: "/portfolio/company",
      reverse: true,
    },
    {
      id: 2,
      title: "Personal",
      desc: "",
      socialMedia: false,
      ig: "",
      youtube: "",
      image: banner_data_personal?.filter(
        (el) => el.slug === "banner_personal_1"
      )[0]?.image,
      buttonLink: "/portfolio/personal",
      reverse: false,
    },
  ];

  const breadcrumbData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Portfolio",
      link: "",
    },
  ];
  return (
    <div>
      <BannerTitleBGg
        title={"Our Portfolio"}
        image={"images/portfolio-bg-banner.png"}
      />
      <div
        css={css`
          display: block;
          width: max-content;
          margin: 25px auto 25px auto;
        `}
      >
        <BreadCrumbsLink titleBreadcrumbs={breadcrumbData} />
      </div>

      {contentDatas.map((el) => {
        return (
          <ContentSpiceItUp
            key={el.id}
            title={el.title}
            description={el.desc}
            socialMedia={el.socialMedia}
            instagram={el.ig}
            youtube={el.youtube}
            image={el.image}
            reverseGrid={el.reverse}
            themeColor={"#006BFF"}
            buttonShow={true}
            buttonTitle="Portfolio"
            buttonLink={el.buttonLink}
            css={css`
              margin-bottom: 20px;
            `}
          />
        );
      })}
      <Contact />
    </div>
  );
}
