import React from "react";
import FormDataGroup from "../../components/admin/FormDataGroup";
import Separator from "../../components/admin/Separator";
import { useSelector, useDispatch } from "react-redux";
import { getContact, contactUpdate } from "../../redux/features/admin/contact";

export default function ContactAdmin() {
  const contact_data = useSelector(
    (state) => state.contactReducer.contact_data
  );
  const loading_contact = useSelector(
    (state) => state.contactReducer.loading_contact
  );
  const loading_update = useSelector(
    (state) => state.contactReducer.loading_update
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getContact());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = async (e) => {
    await dispatch(contactUpdate(e));
    dispatch(getContact());
  };
  return (
    <div>
      <FormDataGroup
        title="Company Information"
        data={contact_data}
        address
        telephone
        email
        loadingData={loading_contact}
        loading={loading_update}
        saveData={(e) => updateData(e)}
        resetValue={() => dispatch(getContact())}
      />
      <Separator />
      <FormDataGroup
        title="Social Media Link"
        data={contact_data}
        instagram
        facebook
        twitter
        linkedIn
        youtube
        loadingData={loading_contact}
        loading={loading_update}
        saveData={(e) => updateData(e)}
        resetValue={() => dispatch(getContact())}
      />
    </div>
  );
}
