import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBanner = createAsyncThunk(
  "get/bannerSpiceLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/banner?per_page=17&search=banner_spice_it_up&banner_type=general`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getDetailSpiceItUp = createAsyncThunk(
  "get/detailSpiceItUpSpiceLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/slug/spice-it-up`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getDetailContentStrategy = createAsyncThunk(
  "get/detailContentStrategySpiceLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/slug/spice-it-up-content-strategy-management`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getPortfolio = createAsyncThunk(
  "get/portfolioSpiceLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post?per_page=${arg}&post_type=spice_it_up_portfolio`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getPortfolioById = createAsyncThunk(
  "get/portfolioByIdSpiceLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const spiceItUpLandingSlice = createSlice({
  name: "spiceItUpLandingSlice",
  initialState: {
    loading_banner: false,
    banner_data: [],

    loading_detail_spice: false,
    detail_spice_data: null,

    loading_detail_content_strategy: false,
    detail_content_strategy_data: null,

    loading_portfolio: false,
    portfolio_data: [],

    loading_portfolio_id: false,
    portfolio_id_data: null,
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [getBanner.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBanner.rejected]: (state, action) => {
      state.loading_banner = false;
    },

    // Detail Spice it Up
    [getDetailSpiceItUp.pending]: (state, { payload }) => {
      state.loading_detail_spice = true;
    },
    [getDetailSpiceItUp.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.detail_spice_data = payload.data;
      } else {
        state.detail_spice_data = null;
      }
      state.loading_detail_spice = false;
    },
    [getDetailSpiceItUp.rejected]: (state, action) => {
      state.loading_detail_spice = false;
    },

    // Detail Content Strategy
    [getDetailContentStrategy.pending]: (state, { payload }) => {
      state.loading_detail_content_strategy = true;
    },
    [getDetailContentStrategy.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.detail_content_strategy_data = payload.data;
      } else {
        state.detail_content_strategy_data = null;
      }
      state.loading_detail_content_strategy = false;
    },
    [getDetailContentStrategy.rejected]: (state, action) => {
      state.loading_detail_content_strategy = false;
    },

    // Portfolio
    [getPortfolio.pending]: (state, { payload }) => {
      state.loading_portfolio = true;
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_data = payload;
      } else {
        state.portfolio_data = [];
      }
      state.loading_portfolio = false;
    },
    [getPortfolio.rejected]: (state, action) => {
      state.loading_portfolio = false;
    },

    // Portfolio By Id
    [getPortfolioById.pending]: (state, { payload }) => {
      state.loading_portfolio_id = true;
    },
    [getPortfolioById.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_id_data = payload.data;
      } else {
        state.portfolio_id_data = null;
      }
      state.loading_portfolio_id = false;
    },
    [getPortfolioById.rejected]: (state, action) => {
      state.loading_portfolio_id = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = spiceItUpLandingSlice.actions;

export default spiceItUpLandingSlice.reducer;
