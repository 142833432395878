/** @jsxImportSource @emotion/react */
import React from "react";
import { Button } from "@mui/material";
import { SingleUploadImageInsideDialogCss } from "../../styles/admin/dialog-admin_css";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { toast } from "react-toastify";

SingleUploadImageInsideDialog.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  imageDimension: PropTypes.string,
  maxFile: PropTypes.string,
  supportedFile: PropTypes.string,
  savedFile: PropTypes.func,
};

export default function SingleUploadImageInsideDialog({
  title,
  image,
  imageDimension,
  maxFile,
  supportedFile,
  savedFile,
}) {
  const inputFileRefSingleUpload = React.useRef();
  const [imageFile, setImageFile] = React.useState("");

  React.useEffect(() => {
    setImageFile(image);
  }, [image]);

  const handleFileRead = async (event, img_dimension) => {
    const file = event.target.files[0];

    const onUpload = async () => {
      setImageFile(URL.createObjectURL(file));
      const base64 = await convertBase64(file);
      savedFile(base64);
    };

    const img_dimension_split = img_dimension
      .replace(" ", "")
      .replace(" ", "")
      .split("x");

    if (file.size > 2 * (1024 * 1024)) {
      toast.error(`Size terlalu besar, maksimal ${file.size} MB!`);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const { height, width } = image;
          if (
            height !== Number(img_dimension_split[1]) &&
            width !== Number(img_dimension_split[0])
          ) {
            toast.error(
              `Ukuran dimensi gambar tidak sesuai, ukuran yang diharuskan ${img_dimension}!`
            );
            event.target.value = null;
            return false;
          } else {
            onUpload();
          }
        };
      };
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteImage = () => {
    setImageFile("");
    savedFile("");
    inputFileRefSingleUpload.current.value = null;
  };

  return (
    <div css={SingleUploadImageInsideDialogCss.self} className="table-datas">
      <h4>{title}</h4>
      <div className="table-datas__item">
        {imageFile ? (
          <div
            className="table-datas__item--thumbnail"
            css={css`
              background-image: url("${imageFile}");
            `}
          ></div>
        ) : (
          <React.Fragment>
            <img
              src="/icons/admin/image-upload-dum.svg"
              className="table-datas__item--dum-thumbnail"
              alt=""
            />
            <div className="table-datas__item--detail">
              <p>
                <small>Image Dimension : {imageDimension}</small>
                <br />
                <small>Maximum File Size : {maxFile}</small>
                <br />
                <small>Supported File : {supportedFile}</small>
              </p>
            </div>
          </React.Fragment>
        )}
        <Button
          variant="btn-admin-outline-orange"
          onClick={() => inputFileRefSingleUpload.current.click()}
        >
          Upload Image
        </Button>
        <h5
          className="table-datas__item--delete-btn"
          onClick={() => deleteImage("")}
        >
          Delete
        </h5>
      </div>
      <input
        type="file"
        ref={inputFileRefSingleUpload}
        accept="image/png, image/jpeg, image/jpg"
        onChange={(e) => handleFileRead(e, imageDimension)}
        css={css`
          position: absolute;
          opacity: 0;
        `}
      />
    </div>
  );
}
