/** @jsxImportSource @emotion/react */
import * as React from "react";
import PropTypes from "prop-types";
import { Grid, OutlinedInput } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DialogAdminCss } from "../../styles/admin/dialog-admin_css";
import TextAreaEditor from "./TextAreaEditor";
import SingleUploadImageInsideDialog from "./SingleUploadImageInsideDialog";
import MasonryImagesInsideDialog from "./MasonryImagesInsideDialog";
import { css } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";
import { toast } from "react-toastify";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "unset",
    width: "auto",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "850px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

CustomizedDialogs.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  data: PropTypes.object,
  youtubeLink: PropTypes.bool,
  headingTitle: PropTypes.bool,
  brandName: PropTypes.bool,
  contentDesription: PropTypes.bool,
  brandDescription: PropTypes.bool,
  portfolioDetails: PropTypes.bool,
  masonryImages: PropTypes.bool,
  date: PropTypes.bool,
  category: PropTypes.bool,
  singleImageUpload: PropTypes.object,
  memberName: PropTypes.bool,
  positionJob: PropTypes.bool,
  linkedIn: PropTypes.bool,
  memberDescription: PropTypes.bool,
  saveData: PropTypes.func,
  youtubeOnImage: PropTypes.bool,
};

export default function CustomizedDialogs({
  open = false,
  close,
  data,
  youtubeLink = false,
  headingTitle = false,
  brandName = false,
  contentDesription = false,
  brandDescription = false,
  portfolioDetails = false,
  masonryImages = false,
  date = false,
  category = false,
  singleImageUpload = {
    show: false,
    title: "",
    imageDimension: "",
    maxFile: "",
    supportedFile: "",
  },
  memberName = false,
  positionJob = false,
  linkedIn = false,
  memberDescription = false,
  saveData,
  youtubeOnImage = false,
}) {
  const [openDialog, setOpenDialog] = React.useState(open);

  const [youtubeLinkValue, setYoutubeLinkValue] = React.useState("");
  const [headingTitleValue, setHeadingTitleValue] = React.useState("");
  const [brandNameValue, setBrandNameValue] = React.useState("");

  const [brandDescriptionValue, setBrandDescriptionValue] = React.useState("");
  const [brandDescriptionValueEditor, setBrandDescriptionValueEditor] =
    React.useState("");

  const [portfolioDetailsValue, setPortfolioDetailsValue] = React.useState("");
  const [portfolioDetailsValueEditor, setPortfolioDetailsValueEditor] =
    React.useState("");

  const [contentDesriptionValue, setContentDesriptionValue] =
    React.useState("");
  const [contentDesriptionValueEditor, setContentDesriptionValueEditor] =
    React.useState("");

  const [singleImageUploadState, setSingleImageUploadState] =
    React.useState("");

  const [masonryImageState, setMasonryImageState] = React.useState([]);
  const [dateState, setDateState] = React.useState(new Date());
  const [categoryState, setCategoryState] = React.useState("");

  const [memberNameState, setMemberNameState] = React.useState("");
  const [positionJobState, setPositionJobState] = React.useState("");
  const [linkedInState, setLinkedInState] = React.useState("");

  const [memberDescriptionState, setMemberDescriptionState] =
    React.useState("");
  const [memberDescriptionStateEditor, setMemberDescriptionStateEditor] =
    React.useState("");

  React.useEffect(() => {
    if (open) {
      setOpenDialog(true);
    }

    setYoutubeLinkValue(data.youtube || "");
    setHeadingTitleValue(data.title || "");
    setBrandNameValue(data.title || "");

    setContentDesriptionValue(data.description || "");
    setContentDesriptionValueEditor(data.description || "");

    if (brandDescription) {
      setBrandDescriptionValue(data.description || "");
      setBrandDescriptionValueEditor(data.description || "");
    }

    if (portfolioDetails) {
      setPortfolioDetailsValue(data.detail || "");
      setPortfolioDetailsValueEditor(data.detail || "");
    }

    setSingleImageUploadState(data.image || "");
    setDateState(data.date ? new Date(data.date) : new Date());
    setCategoryState(data.category || "");

    (async () => {
      setMasonryImageState([
        {
          image:
            data.post_images && data?.post_images.length > 0
              ? await getBase64FromUrl(data.post_images[0]?.image)
              : "",
        },
        {
          image:
            data.post_images && data?.post_images.length > 0
              ? await getBase64FromUrl(data.post_images[1]?.image)
              : "",
        },
        {
          image:
            data.post_images && data?.post_images.length > 0
              ? await getBase64FromUrl(data.post_images[2]?.image)
              : "",
        },
        {
          image:
            data.post_images && data?.post_images.length > 0
              ? await getBase64FromUrl(data.post_images[3]?.image)
              : "",
        },
        {
          image:
            data.post_images && data?.post_images.length > 0
              ? await getBase64FromUrl(data.post_images[4]?.image)
              : "",
        },
      ]);
    })();

    setMemberNameState(data.title || "");
    setPositionJobState(data.sub_title || "");
    setLinkedInState(data.linkedin || "");

    setMemberDescriptionState(data.description || "");
    setMemberDescriptionStateEditor(data.description || "");
  }, [open, data, brandDescription, portfolioDetails]);

  const closeDialog = () => {
    close();
    setOpenDialog(false);
  };

  const getBase64FromUrl = async (url) => {
    if (url) {
      const data = await fetch(
        `${url.replace("https://api.moleculaindonesia.co.id", "")}`
      );
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    } else {
      return "";
    }
  };

  const saveDataFunc = () => {
    let validateForm = true;
    let dataSend = {
      id: data.id,
      youtubeLink:
        youtubeLink && data.youtube
          ? youtubeLinkValue || (validateForm = false)
          : youtubeOnImage
          ? youtubeLinkValue || (validateForm = true)
          : "",
      headingTitle: headingTitle
        ? headingTitleValue || (validateForm = false)
        : "",
      brandName: brandName ? brandNameValue || (validateForm = false) : "",
      brandDescription: brandDescription
        ? brandDescriptionValueEditor || (validateForm = false)
        : "",
      portfolioDetails: portfolioDetails
        ? portfolioDetailsValueEditor || (validateForm = false)
        : "",
      contentDesription: contentDesription
        ? contentDesriptionValueEditor || (validateForm = false)
        : "",
      isMainPost: data.is_main_post,
      image:
        !data?.youtube && singleImageUpload?.show
          ? singleImageUploadState || (validateForm = false)
          : youtubeOnImage
          ? singleImageUploadState || (validateForm = false)
          : "",
      postImages: masonryImages
        ? masonryImageState.length > 0 &&
          masonryImageState.filter((el) => el.image === "").length === 0
          ? masonryImageState
          : (validateForm = false)
        : "",
      date: date
        ? dateState
          ? format(dateState, "yyyy/MM/dd")
          : (validateForm = false)
        : "",
      category: category ? categoryState || (validateForm = false) : "",
      memberName: memberName ? memberNameState || (validateForm = false) : "",
      positionJob: positionJob
        ? positionJobState || (validateForm = false)
        : "",
      linkedIn: linkedIn ? linkedInState || (validateForm = false) : "",
      memberDescription: memberDescription
        ? memberDescriptionStateEditor || (validateForm = false)
        : "",
    };
    if (!validateForm) {
      toast.error(`Data belum dilengkapi`);
    } else {
      saveData(dataSend);
      setOpenDialog(false);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        css={DialogAdminCss.self}
        open={openDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeDialog}
        >
          {data.title}
        </BootstrapDialogTitle>
        <DialogContent
          css={css`
            padding-top: 15px !important;
          `}
        >
          <form className="form-group-control">
            {singleImageUpload.show && !data.youtube ? (
              <SingleUploadImageInsideDialog
                title={singleImageUpload.title}
                image={data.image}
                imageDimension={singleImageUpload.imageDimension}
                maxFile={singleImageUpload.maxFile}
                supportedFile={singleImageUpload.supportedFile}
                savedFile={(e) => setSingleImageUploadState(e)}
              />
            ) : (
              ""
            )}

            {youtubeLink && data.youtube && !youtubeOnImage ? (
              <div className="form-group">
                <h4>
                  <b>Youtube Link</b>
                </h4>
                <OutlinedInput
                  id="link"
                  value={youtubeLinkValue}
                  onInput={(e) => setYoutubeLinkValue(e.target.value)}
                  placeholder="Paste your youtube link here"
                  type="text"
                />
              </div>
            ) : (
              ""
            )}

            {headingTitle ? (
              <div className="form-group">
                <h4>
                  <b>Heading Title</b>
                </h4>
                <OutlinedInput
                  id="title"
                  value={headingTitleValue}
                  onInput={(e) => setHeadingTitleValue(e.target.value)}
                  placeholder=""
                  type="text"
                />
              </div>
            ) : (
              ""
            )}

            {brandName ? (
              <div className="form-group">
                <h4>
                  <b>Brand Name</b>
                </h4>
                <OutlinedInput
                  id="brand-name"
                  value={brandNameValue}
                  onInput={(e) => setBrandNameValue(e.target.value)}
                  placeholder=""
                  type="text"
                />
              </div>
            ) : (
              ""
            )}

            {memberName ? (
              <div className="form-group">
                <h4>
                  <b>Member Name</b>
                </h4>
                <OutlinedInput
                  id="member-name"
                  value={memberNameState}
                  onInput={(e) => setMemberNameState(e.target.value)}
                  placeholder=""
                  type="text"
                />
              </div>
            ) : (
              ""
            )}

            <Grid container spacing={2}>
              {date ? (
                <Grid item md={4}>
                  <div className="form-group">
                    <h4>
                      <b>Date</b>
                    </h4>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="Date"
                        inputFormat="MMM dd,yyyy"
                        value={dateState}
                        onChange={(e) => setDateState(e)}
                        renderInput={(params) => (
                          <OutlinedInput notched={false} {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
              ) : (
                ""
              )}

              {category ? (
                <Grid item md={4}>
                  <div className="form-group">
                    <h4>
                      <b>Category</b>
                    </h4>
                    <OutlinedInput
                      id="category"
                      value={categoryState}
                      onInput={(e) => setCategoryState(e.target.value)}
                      placeholder=""
                      type="text"
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}

              {positionJob ? (
                <Grid item md={4}>
                  <div className="form-group">
                    <h4>
                      <b>Position/Job</b>
                    </h4>
                    <OutlinedInput
                      id="category"
                      value={positionJobState}
                      onInput={(e) => setPositionJobState(e.target.value)}
                      placeholder=""
                      type="text"
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}

              {linkedIn ? (
                <Grid item md={4}>
                  <div className="form-group">
                    <h4>
                      <b>Linkedin Link</b>
                    </h4>
                    <OutlinedInput
                      id="category"
                      value={linkedInState}
                      onInput={(e) => setLinkedInState(e.target.value)}
                      placeholder=""
                      type="text"
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}
            </Grid>

            {brandDescription ? (
              <div className="form-group">
                <h4>
                  <b>Brand Description</b>
                </h4>
                <TextAreaEditor
                  value={brandDescriptionValue}
                  handleChange={(e) => setBrandDescriptionValueEditor(e)}
                />
              </div>
            ) : (
              ""
            )}

            {masonryImages ? (
              <MasonryImagesInsideDialog
                images={data.post_images}
                savedFile={(e) => setMasonryImageState(e)}
              />
            ) : (
              ""
            )}

            {contentDesription ? (
              <div className="form-group">
                <h4>
                  <b>Content Description</b>
                </h4>
                <TextAreaEditor
                  value={contentDesriptionValue}
                  handleChange={(e) => setContentDesriptionValueEditor(e)}
                />
              </div>
            ) : (
              ""
            )}

            {portfolioDetails ? (
              <div className="form-group">
                <h4>
                  <b>Portfolio Details</b>
                </h4>
                <TextAreaEditor
                  value={portfolioDetailsValue}
                  handleChange={(e) => setPortfolioDetailsValueEditor(e)}
                />
              </div>
            ) : (
              ""
            )}

            {memberDescription ? (
              <div className="form-group">
                <h4>
                  <b>Brand Description</b>
                </h4>
                <TextAreaEditor
                  value={memberDescriptionState}
                  handleChange={(e) => setMemberDescriptionStateEditor(e)}
                />
              </div>
            ) : (
              ""
            )}

            {youtubeOnImage ? (
              <div className="form-group">
                <h4>
                  <b>Youtube Link News</b>
                </h4>
                <OutlinedInput
                  id="link"
                  value={youtubeLinkValue}
                  onInput={(e) => setYoutubeLinkValue(e.target.value)}
                  placeholder="Paste your youtube link here"
                  type="text"
                />
              </div>
            ) : (
              ""
            )}
          </form>

          <div className="action-content">
            <Button variant="btn-admin" onClick={() => saveDataFunc()}>
              Save changes
            </Button>
            <Button variant="btn-admin-outline-red" onClick={closeDialog}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
