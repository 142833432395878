import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBanner = createAsyncThunk(
  "get/bannerAboutUsAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/banner?flag=banner_about_us`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const putImageBanner = createAsyncThunk(
  "put/imagebannerAboutUsAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/banner/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateAboutUsDesc = createAsyncThunk(
  "put/updateAboutDescAboutUsAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/slug/about-us`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: "About Us",
          description: arg.desc,
          post_type: "single_page",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getAboutUsDesc = createAsyncThunk(
  "get/AboutDescAboutUsAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post/slug/about-us`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const aboutUsSlice = createSlice({
  name: "aboutUs",
  initialState: {
    loading_banner: false,
    banner_data: [],
    loading_upload_banner: false,
    loading_description: false,
    loading_update_description: false,
    description_text: "",
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [getBanner.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBanner.rejected]: (state, action) => {
      state.loading_banner = false;
    },
    //   ---------- || ----------
    [putImageBanner.pending]: (state, { payload }) => {
      state.loading_upload_banner = true;
    },
    [putImageBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
      } else {
      }
      state.loading_upload_banner = false;
    },
    [putImageBanner.rejected]: (state, action) => {
      state.loading_upload_banner = false;
    },
    //   ---------- || ----------
    [updateAboutUsDesc.pending]: (state, { payload }) => {
      state.loading_update_description = true;
    },
    [updateAboutUsDesc.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // state.description_text = payload.data.description;
      } else {
        // state.description_text = "";
      }
      state.loading_update_description = false;
    },
    [updateAboutUsDesc.rejected]: (state, action) => {
      state.loading_update_description = false;
    },
    [getAboutUsDesc.pending]: (state, { payload }) => {
      state.loading_description = true;
    },
    [getAboutUsDesc.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.description_text = payload.data.description;
      } else {
        state.description_text = "";
      }
      state.loading_description = false;
    },
    [getAboutUsDesc.rejected]: (state, action) => {
      state.loading_description = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = aboutUsSlice.actions;

export default aboutUsSlice.reducer;
