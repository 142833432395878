import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getContact = createAsyncThunk(
  "get/contactLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/configuration?flag=contact_`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const contactLandingSlice = createSlice({
  name: "contactLandingSlice",
  initialState: {
    loading_contact: false,
    contact_data: [],
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [getContact.pending]: (state, { payload }) => {
      state.loading_contact = true;
    },
    [getContact.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.contact_data = payload.data;
      } else {
        state.contact_data = [];
      }
      state.loading_contact = false;
    },
    [getContact.rejected]: (state, action) => {
      state.loading_contact = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = contactLandingSlice.actions;

export default contactLandingSlice.reducer;
