import { css } from "@emotion/react";

export const TextAreaEditorCss = (type = "minimize") => ({
  self: css`
    position: relative;
    .rdw-editor-wrapper {
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      background-color: white;
      overflow: hidden;
      .rdw-editor-main {
        min-height: ${type === "minimize" ? "300px" : "400px"};
      }
      .rdw-editor-toolbar {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 1px solid #76ccff;
        background: #76ccff;
        .rdw-option-wrapper {
          background: #76ccff;
          border: 1px solid #76ccff;
          overflow: hidden;
        }
      }
      .public-DraftStyleDefault-block {
        margin: 0.3em 1em;
      }
    }
    .btn-screen {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 20px;
      cursor: pointer;
    }
  `,
});
