/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Button } from "@mui/material";
import { TeamsCss } from "../../../styles/landing/about-us_css";
import "../../../loader-plugin";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PropTypes from "prop-types";

Teams.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default function Teams({ data, loading }) {
  const settingOwl = {
    loop: true,
    margin: 80,
    items: 3,
    autoWidth: true,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    responsive: {
      0: {
        items: 2,
        margin: 30,
        autoWidth: true,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div css={TeamsCss.self}>
      {loading ? (
        ""
      ) : (
        <OwlCarousel className="owl-theme" {...settingOwl}>
          {data?.length > 0 &&
            data?.map((el) => {
              return (
                <div key={el.id} className="box-team">
                  <h1>{el.title}</h1>
                  <p className="position-team">{el.sub_title}</p>
                  <div
                    css={css`
                      background-image: url("${el.image}");
                    `}
                    className="img-team"
                  />
                  <div
                    className="detail-team"
                    dangerouslySetInnerHTML={{
                      __html: el.description?.replaceAll("<p></p>", "<br/>"),
                    }}
                  />
                  <Button
                    variant="contained"
                    color="inherit"
                    disableElevation
                    onClick={() =>
                      el.linkedin ? window.open(el.linkedin, "_blank") : ""
                    }
                  >
                    LinkedIn
                  </Button>
                </div>
              );
            })}
        </OwlCarousel>
      )}
    </div>
  );
}
