import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

// Portfolio
export const getPortfolio = createAsyncThunk(
  "get/portfolioNewsAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post?post_type=news`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createPortfolio = createAsyncThunk(
  "create/portfolioNewsAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/post`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.headingTitle,
          description: arg.contentDesription,
          date: arg.date,
          category: arg.category,
          post_type: "news",
          image: arg.image,
          youtube: arg.youtubeLink,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updatePortfolio = createAsyncThunk(
  "update/portfolioNewsAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    const data_params = {
      title: arg.headingTitle,
      description: arg.contentDesription,
      date: arg.date,
      category: arg.category,
      post_type: "news",
      image: arg.image,
      youtube: arg.youtubeLink,
    };
    if (data_params.image.includes("http")) {
      delete data_params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data_params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deletePortfolio = createAsyncThunk(
  "delete/portfolioNewsAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const newsSlice = createSlice({
  name: "newsSlice",
  initialState: {
    portfolio_datas: [],
    loading_portfolio: false,
    loading_update_portfolio: false,
  },
  reducers: {
    addMore: (state) => {},
  },
  extraReducers: {
    //   Portfolio || ----------
    [getPortfolio.pending]: (state, { payload }) => {
      state.loading_portfolio = true;
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_datas = payload.data;
      } else {
        state.portfolio_datas = [];
      }
      state.loading_portfolio = false;
    },
    [getPortfolio.rejected]: (state, action) => {
      state.loading_portfolio = false;
    },
    [createPortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [createPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [createPortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [updatePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [updatePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [updatePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [deletePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [deletePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [deletePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore } = newsSlice.actions;

export default newsSlice.reducer;
