import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../redux/features/authentication";
import homePageReducer from "./features/admin/homepage";
import aboutUsReducer from "./features/admin/about-us";
import promotionReducer from "./features/admin/promotion";
import marketingReducer from "./features/admin/marketing-communication";
import publicRelationReducer from "./features/admin/public-relation";
import spiceItUpReducer from "./features/admin/spice-it-up";
import companyReducer from "./features/admin/company";
import personalReducer from "./features/admin/personal";
import newsReducer from "./features/admin/news";
import memberReducer from "./features/admin/member";
import contactReducer from "./features/admin/contact";
import MessagesReducer from "./features/admin/messages";
import globalAdminReducer from "./features/admin/global";

import homeLandingReducer from "./features/landing/home";
import promotionLandingReducer from "./features/landing/promotion";
import marketingLandingReducer from "./features/landing/marketing";
import publicRelationLandingReducer from "./features/landing/public-relation";
import companyLandingReducer from "./features/landing/company";
import personalLandingReducer from "./features/landing/personal";
import aboutUsLandingReducer from "./features/landing/about-us";
import spiceItUpLandingReducer from "./features/landing/spice-it-up";
import newsLandingReducer from "./features/landing/news";
import contactLandingReducer from "./features/landing/contact";
import messagesLandingReducer from "./features/landing/messages";

export default configureStore({
  reducer: {
    login: loginReducer,
    homePageReducer: homePageReducer,
    aboutUsReducer: aboutUsReducer,
    promotionReducer: promotionReducer,
    marketingReducer: marketingReducer,
    publicRelationReducer: publicRelationReducer,
    spiceItUpReducer: spiceItUpReducer,
    companyReducer: companyReducer,
    personalReducer: personalReducer,
    newsReducer: newsReducer,
    memberReducer: memberReducer,
    contactReducer: contactReducer,
    MessagesReducer: MessagesReducer,
    globalAdminReducer: globalAdminReducer,

    homeLandingReducer: homeLandingReducer,
    promotionLandingReducer: promotionLandingReducer,
    marketingLandingReducer: marketingLandingReducer,
    publicRelationLandingReducer: publicRelationLandingReducer,
    companyLandingReducer: companyLandingReducer,
    personalLandingReducer: personalLandingReducer,
    aboutUsLandingReducer: aboutUsLandingReducer,
    spiceItUpLandingReducer: spiceItUpLandingReducer,
    newsLandingReducer: newsLandingReducer,
    contactLandingReducer: contactLandingReducer,
    messagesLandingReducer: messagesLandingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
