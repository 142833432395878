import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBanner = createAsyncThunk(
  "get/bannerCompanyAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/banner?flag=banner_company`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const putImageBanner = createAsyncThunk(
  "put/imagebannerCompanyAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/banner/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateTitle = createAsyncThunk(
  "put/updateTitleCompanyAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/slug/company`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.title,
          description: "description",
          post_type: "single_page",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getTitle = createAsyncThunk(
  "get/TitleCompanyAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post/slug/company`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);
//
//
// Portfolio
export const getPortfolio = createAsyncThunk(
  "get/portfolioCompanyAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post?post_type=company_portfolio`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createPortfolio = createAsyncThunk(
  "create/portfolioCompanyAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/post`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.brandName,
          description: arg.brandDescription,
          post_type: "company_portfolio",
          image: arg.youtubeLink ? "" : arg.image,
          detail: arg.portfolioDetails,
          youtube: arg.youtubeLink,
          post_images: arg.postImages,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updatePortfolio = createAsyncThunk(
  "update/portfolioCompanyAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    arg.postImages = arg.postImages.map((el) => {
      if (el.image.includes("https")) {
        return { image: null };
      } else {
        return { image: el.image };
      }
    });
    const data_params = {
      title: arg.brandName,
      description: arg.brandDescription,
      detail: arg.portfolioDetails,
      post_type: "company_portfolio",
      image: arg.youtubeLink ? "" : arg.image,
      youtube: arg.youtubeLink,
      post_images: arg.postImages,
    };
    if (data_params.image.includes("http")) {
      delete data_params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data_params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deletePortfolio = createAsyncThunk(
  "delete/portfolioCompanyAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const companySlice = createSlice({
  name: "companySlice",
  initialState: {
    loading_banner: false,
    banner_data: [],
    loading_upload_banner: false,

    loading_update: false,
    loading: false,
    title: "",

    portfolio_datas: [],
    loading_portfolio: false,
    loading_update_portfolio: false,
  },
  reducers: {
    addMore: (state) => {},
  },
  extraReducers: {
    [getBanner.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBanner.rejected]: (state, action) => {
      state.loading_banner = false;
    },
    //   ---------- || ----------
    [putImageBanner.pending]: (state, { payload }) => {
      state.loading_upload_banner = true;
    },
    [putImageBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
      } else {
      }
      state.loading_upload_banner = false;
    },
    [putImageBanner.rejected]: (state, action) => {
      state.loading_upload_banner = false;
    },
    //   ---------- || ----------
    [updateTitle.pending]: (state, { payload }) => {
      state.loading_update = true;
    },
    [updateTitle.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update = false;
    },
    [updateTitle.rejected]: (state, action) => {
      state.loading_update = false;
    },
    [getTitle.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getTitle.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.title = payload.data.title;
      } else {
        state.title = "";
      }
      state.loading = false;
    },
    [getTitle.rejected]: (state, action) => {
      state.loading = false;
    },
    //
    //
    //
    //   Portfolio || ----------
    [getPortfolio.pending]: (state, { payload }) => {
      state.loading_portfolio = true;
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_datas = payload.data;
      } else {
        state.portfolio_datas = [];
      }
      state.loading_portfolio = false;
    },
    [getPortfolio.rejected]: (state, action) => {
      state.loading_portfolio = false;
    },
    [createPortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [createPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [createPortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [updatePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [updatePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [updatePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [deletePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [deletePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [deletePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore } = companySlice.actions;

export default companySlice.reducer;
