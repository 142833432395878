import { css } from "@emotion/react";

export const SidebarCss = {
  self: css`
    .css-15k0a43-MuiButtonBase-root-MuiMenuItem-root {
      background-color: white !important;
    }
    .css-35tnjk-MuiButtonBase-root-MuiListItemButton-root:hover {
      color: #7a797d;
    }
    .css-9qr8e9-MiniDrawer {
      min-height: 95px !important;
    }
  `,
};
