/** @jsxImportSource @emotion/react */
import { Grid, Button, css } from "@mui/material";
import React from "react";
import { MasonryImagesInsideDialogCss } from "../../styles/admin/dialog-admin_css";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

MasonryImagesInsideDialog.propTypes = {
  images: PropTypes.array,
  savedFile: PropTypes.func,
};

export default function MasonryImagesInsideDialog({ images, savedFile }) {
  const inputFileRefMasonry1 = React.useRef();
  const inputFileRefMasonry2 = React.useRef();
  const inputFileRefMasonry3 = React.useRef();
  const inputFileRefMasonry4 = React.useRef();
  const inputFileRefMasonry5 = React.useRef();

  const [imageFile1, setImageFile1] = React.useState("");
  const [imageFile2, setImageFile2] = React.useState("");
  const [imageFile3, setImageFile3] = React.useState("");
  const [imageFile4, setImageFile4] = React.useState("");
  const [imageFile5, setImageFile5] = React.useState("");

  React.useEffect(() => {
    (async () => {
      setImageFile1(
        images.length > 0 && images[0]?.image ? images[0].image : ""
      );
      setImageFile2(
        images.length > 0 && images[1]?.image ? images[1].image : ""
      );
      setImageFile3(
        images.length > 0 && images[2]?.image ? images[2].image : ""
      );
      setImageFile4(
        images.length > 0 && images[3]?.image ? images[3].image : ""
      );
      setImageFile5(
        images.length > 0 && images[4]?.image ? images[4].image : ""
      );
    })();
  }, [images]);

  const handleFileRead = async (event, index, img_dimension) => {
    const file = event.target.files[0];

    const img_dimension_split = img_dimension
      .replace(" ", "")
      .replace(" ", "")
      .split("x");

    if (file.size > 2 * (1024 * 1024)) {
      toast.error(`Size terlalu besar, maksimal ${file.size} MB!`);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = async () => {
          const { height, width } = image;
          if (
            height !== Number(img_dimension_split[1]) &&
            width !== Number(img_dimension_split[0])
          ) {
            toast.error(
              `Ukuran dimensi gambar tidak sesuai, ukuran yang diharuskan ${img_dimension}!`
            );
            file.target.value = null;
            return false;
          } else {
            switch (index) {
              case 1:
                setImageFile1(await convertBase64(file));
                break;
              case 2:
                setImageFile2(await convertBase64(file));
                break;
              case 3:
                setImageFile3(await convertBase64(file));
                break;
              case 4:
                setImageFile4(await convertBase64(file));
                break;
              default:
                setImageFile5(await convertBase64(file));
            }
            let data_file = [
              {
                image: imageFile1,
              },
              {
                image: imageFile2,
              },
              {
                image: imageFile3,
              },
              {
                image: imageFile4,
              },
              {
                image: imageFile5,
              },
            ];
            data_file[index - 1].image = await convertBase64(file);
            savedFile(data_file);
          }
        };
      };
    }
  };

  // const getBase64FromUrl = async (url) => {
  //   const data = await fetch(
  //     `${url.replace("https://api.moleculaindonesia.co.id", "")}`
  //   );

  //   const blob = await data.blob();
  //   return new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(blob);
  //     reader.onloadend = () => {
  //       const base64data = reader.result;
  //       resolve(base64data);
  //     };
  //   });
  // };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <h4>Upload Image (Portfolio)</h4>
      <div css={MasonryImagesInsideDialogCss.self}>
        <input
          type="file"
          ref={inputFileRefMasonry1}
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => handleFileRead(e, 1, "1023 x 572")}
          css={css`
            position: absolute;
            opacity: 0;
          `}
        />
        <input
          type="file"
          ref={inputFileRefMasonry2}
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => handleFileRead(e, 2, "1024 x 844")}
          css={css`
            position: absolute;
            opacity: 0;
          `}
        />
        <input
          type="file"
          ref={inputFileRefMasonry3}
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => handleFileRead(e, 3, "1024 x 844")}
          css={css`
            position: absolute;
            opacity: 0;
          `}
        />
        <input
          type="file"
          ref={inputFileRefMasonry4}
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => handleFileRead(e, 4, "1023 x 572")}
          css={css`
            position: absolute;
            opacity: 0;
          `}
        />
        <input
          type="file"
          ref={inputFileRefMasonry5}
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => handleFileRead(e, 5, "1024 x 1826")}
          css={css`
            position: absolute;
            opacity: 0;
          `}
        />
        <Grid container spacing={2}>
          <Grid item md={9} xs={9}>
            <Grid spacing={2} container>
              <Grid item md={8} xs={8}>
                <div className="uploader-box">
                  {imageFile1 ? (
                    <React.Fragment>
                      <div
                        className="img-showed grid-8"
                        css={css`
                          background-image: url("${imageFile1}");
                        `}
                      ></div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img
                        src="/icons/admin/image-upload-dum.svg"
                        className="grid-8"
                        alt=""
                      />
                      <div className="uploader-box__details-text grid-8">
                        <p>
                          <small>Image Dimension : 1023 x 572</small>
                          <br />
                          <small>Supported File : JPG,JPEG,PNG</small>
                          <br />
                          <small>Maximum File Size : 2.0 MB</small>
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                  <Button
                    variant="btn-admin-outline-orange"
                    className="grid-8"
                    onClick={() => inputFileRefMasonry1.current.click()}
                  >
                    Upload Image
                  </Button>
                </div>
              </Grid>
              <Grid item md={4} xs={4}>
                <div className="uploader-box">
                  {imageFile2 ? (
                    <div
                      className="img-showed grid-4"
                      css={css`
                        background-image: url("${imageFile2}");
                      `}
                    ></div>
                  ) : (
                    <React.Fragment>
                      <img
                        src="/icons/admin/image-upload-dum.svg"
                        className="grid-4"
                        alt=""
                      />
                      <div className="uploader-box__details-text grid-4">
                        <p>
                          <small>Image Dimension : 1024 x 844</small>
                          <br />
                          <small>Supported File : JPG,JPEG,PNG</small>
                          <br />
                          <small>Maximum File Size : 2.0 MB</small>
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                  <Button
                    variant="btn-admin-outline-orange"
                    className="grid-4"
                    onClick={() => inputFileRefMasonry2.current.click()}
                  >
                    Upload Image
                  </Button>
                </div>
              </Grid>
            </Grid>

            <Grid
              spacing={2}
              container
              css={css`
                margin-top: 0px;
              `}
            >
              <Grid item md={4} xs={4}>
                <div className="uploader-box">
                  {imageFile3 ? (
                    <div
                      className="img-showed grid-4"
                      css={css`
                        background-image: url("${imageFile3}");
                      `}
                    ></div>
                  ) : (
                    <React.Fragment>
                      <img
                        src="/icons/admin/image-upload-dum.svg"
                        className="grid-4"
                        alt=""
                      />
                      <div className="uploader-box__details-text grid-4">
                        <p>
                          <small>Image Dimension : 1024 x 844</small>
                          <br />
                          <small>Supported File : JPG,JPEG,PNG</small>
                          <br />
                          <small>Maximum File Size : 2.0 MB</small>
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                  <Button
                    variant="btn-admin-outline-orange"
                    className="grid-4"
                    onClick={() => inputFileRefMasonry3.current.click()}
                  >
                    Upload Image
                  </Button>
                </div>
              </Grid>
              <Grid item md={8} xs={8}>
                <div className="uploader-box">
                  {imageFile4 ? (
                    <div
                      className="img-showed grid-8"
                      css={css`
                        background-image: url("${imageFile4}");
                      `}
                    ></div>
                  ) : (
                    <React.Fragment>
                      <img
                        src="/icons/admin/image-upload-dum.svg"
                        className="grid-8"
                        alt=""
                      />
                      <div className="uploader-box__details-text grid-8">
                        <p>
                          <small>Image Dimension : 1023 x 572</small>
                          <br />
                          <small>Supported File : JPG,JPEG,PNG</small>
                          <br />
                          <small>Maximum File Size : 2.0 MB</small>
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                  <Button
                    variant="btn-admin-outline-orange"
                    className="grid-8"
                    onClick={() => inputFileRefMasonry4.current.click()}
                  >
                    Upload Image
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={3} xs={3}>
            <div className="uploader-box tall">
              {imageFile5 ? (
                <div
                  className="img-showed grid-4"
                  css={css`
                    background-image: url("${imageFile5}");
                  `}
                ></div>
              ) : (
                <React.Fragment>
                  <img
                    src="/icons/admin/image-upload-dum.svg"
                    className="grid-4"
                    alt=""
                  />
                  <div className="uploader-box__details-text grid-4 tall">
                    <p>
                      <small>Image Dimension : 1024 x 1826</small>
                      <br />
                      <small>Supported File : JPG,JPEG,PNG</small>
                      <br />
                      <small>Maximum File Size : 2.0 MB</small>
                    </p>
                  </div>
                </React.Fragment>
              )}
              <Button
                variant="btn-admin-outline-orange"
                className="grid-4"
                onClick={() => inputFileRefMasonry5.current.click()}
              >
                Upload Image
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
