/** @jsxImportSource @emotion/react */
import React from "react";
import { Link, useParams } from "react-router-dom";
import BreadCrumbsLink from "../components/landing/BreadCrumbsLink";
import { css } from "@emotion/react";
import { Container } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { getNewsBySlug } from "../redux/features/landing/news";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { getId } from "../components/YoutubeIdGetter";

export default function News() {
  let params = useParams();
  const news_slug_data = useSelector(
    (state) => state.newsLandingReducer.news_slug_data
  );
  const loading_news_slug = useSelector(
    (state) => state.newsLandingReducer.loading_news_slug
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getNewsBySlug(params.newsSlug));
  }, [dispatch, params]);

  return (
    <div
      css={css`
        margin: 40px 0;
      `}
    >
      <div
        css={css`
          margin: 0 auto;
          display: block;
          width: fit-content;
        `}
      >
        <BreadCrumbsLink
          titleBreadcrumbs={[
            { title: "Home", link: "/" },
            { title: "News", link: "" },
          ]}
        />
      </div>

      <Container>
        {loading_news_slug ? (
          <React.Fragment>
            <Skeleton
              width="60%"
              css={css`
                margin-bottom: 25px;
              `}
            />
            <Skeleton
              variant="rectangular"
              height={300}
              css={css`
                margin-bottom: 25px;
              `}
            />
            <Skeleton />
            <Skeleton />
            <Skeleton width="60%" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                color: #e35b29;
              `}
            >
              <h3>
                {news_slug_data
                  ? moment(news_slug_data?.data?.date).format("MMM DD")
                  : ""}
              </h3>
              <h3
                css={css`
                  margin-left: auto;
                `}
              >
                {news_slug_data?.data?.category}
              </h3>
            </div>
            <h1>{news_slug_data?.data?.title}</h1>
            <img
              src={news_slug_data?.data?.image}
              css={css`
                width: 100%;
                height: 470px;
                margin: 20px 0;
                @media only screen and (max-width: 720px) {
                  height: 225px;
                }
              `}
              alt=""
            />
            <p
              dangerouslySetInnerHTML={{
                __html: news_slug_data?.data?.description?.replaceAll(
                  "<p></p>",
                  "<br/>"
                ),
              }}
            />

            {news_slug_data?.data?.youtube ? (
              <iframe
                src={`//www.youtube-nocookie.com/embed/${getId(
                  news_slug_data?.data?.youtube
                )}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="Embedded youtube"
                css={css`
                  margin-top: 30px;
                  height: 500px;
                  width: 100%;
                  @media only screen and (max-width: 720px) {
                    height: 225px;
                  }
                `}
              />
            ) : (
              ""
            )}

            <div
              css={css`
                display: flex;
                align-items: center;
                margin: 50px 0;
              `}
            >
              {news_slug_data?.nav?.prev?.slug ? (
                <Link
                  css={css`
                    color: #4285f4;
                    &:hover {
                      color: #4285f4;
                    }
                  `}
                  to={`/news/${news_slug_data?.nav?.prev?.slug}`}
                >
                  <span>Previous News</span>
                </Link>
              ) : (
                ""
              )}

              {news_slug_data?.nav?.next?.slug ? (
                <Link
                  css={css`
                    margin-left: auto;
                    color: #4285f4;
                    &:hover {
                      color: #4285f4;
                    }
                  `}
                  to={`/news/${news_slug_data?.nav?.next?.slug}`}
                >
                  <span>Next News</span>
                </Link>
              ) : (
                ""
              )}
            </div>
          </React.Fragment>
        )}
      </Container>
    </div>
  );
}
