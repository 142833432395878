/** @jsxImportSource @emotion/react */
import React from "react";
import { Outlet } from "react-router-dom";
import { AuthCss } from "../../styles/admin/auth_css";

export default function LayoutParentAuth() {
  return (
    <div css={AuthCss.self}>
      <div className="header">
        <img src="/icons/molecula-icon.svg" alt="" />
      </div>
      <div className="box-form">
        <Outlet />
      </div>
    </div>
  );
}
