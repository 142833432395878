import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBanner = createAsyncThunk(
  "get/bannerHomeAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/banner?flag=banner_home_page`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getYoutubeVideos = createAsyncThunk(
  "get/youtube_videosHomeAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post?post_type=home_page_youtube_video`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const putImageBanner = createAsyncThunk(
  "put/imagebannerHomeAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/banner/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createYoutubeVideo = createAsyncThunk(
  "create/youtubeVideoHomeAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/post`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.headingTitle,
          description: arg.contentDesription,
          post_type: "home_page_youtube_video",
          is_main_post: false,
          youtube: arg.youtubeLink,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateYoutubeVideo = createAsyncThunk(
  "create/youtubeVideoHomeAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.is_main_post !== undefined ? arg.title : arg.headingTitle,
          description:
            arg.is_main_post !== undefined
              ? arg.description
              : arg.contentDesription,
          post_type: "home_page_youtube_video",
          is_main_post:
            arg.is_main_post !== undefined
              ? arg.is_main_post
                ? false
                : true
              : arg.isMainPost,
          youtube:
            arg.is_main_post !== undefined ? arg.youtube : arg.youtubeLink,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const homePageSlice = createSlice({
  name: "homePageSlice",
  initialState: {
    loading_banner: false,
    banner_data: [],
    loading_upload: false,
    loading_youtube_video: false,
    youtube_videos: [],

    loading_save_data: false,
  },
  reducers: {
    addMore: (state) => {
      state.videos.push({
        thumbnail: "/icons/admin/youtube-dum-icon.svg",
        title: "Youtube Video",
      });
    },
    deleteItem: (state, index) => {
      state.videos.splice(index, 1);
    },
  },
  extraReducers: {
    [getBanner.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBanner.rejected]: (state, action) => {
      state.loading_banner = false;
    },
    //   ---------- || ----------
    [getYoutubeVideos.pending]: (state, { payload }) => {
      state.loading_youtube_video = true;
    },
    [getYoutubeVideos.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.youtube_videos = payload.data;
      } else {
        state.youtube_videos = [];
      }
      state.loading_youtube_video = false;
    },
    [getYoutubeVideos.rejected]: (state, action) => {
      state.loading_youtube_video = false;
    },
    //   ---------- || ----------
    [putImageBanner.pending]: (state, { payload }) => {
      state.loading_upload = true;
    },
    [putImageBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
      } else {
      }
      state.loading_upload = false;
    },
    [putImageBanner.rejected]: (state, action) => {
      state.loading_upload = false;
    },
    //   ---------- || ----------
    [createYoutubeVideo.pending]: (state, { payload }) => {
      state.loading_save_data = true;
    },
    [createYoutubeVideo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
      } else {
      }
      state.loading_save_data = false;
    },
    [createYoutubeVideo.rejected]: (state, action) => {
      state.loading_save_data = false;
    },
    //   ---------- || ----------
    [updateYoutubeVideo.pending]: (state, { payload }) => {
      state.loading_save_data = true;
    },
    [updateYoutubeVideo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
      } else {
      }
      state.loading_save_data = false;
    },
    [updateYoutubeVideo.rejected]: (state, action) => {
      state.loading_save_data = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = homePageSlice.actions;

export default homePageSlice.reducer;
