/** @jsxImportSource @emotion/react */
import React from "react";
import { OutlinedInput, FormHelperText, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AuthCss } from "../../styles/admin/auth_css";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword } from "../../redux/features/authentication";
import { useFormik } from "formik";
import * as yup from "yup";

export default function ResetPassword() {
  let params = useParams();
  const [eyeOpen, setEyeOpen] = React.useState(false);

  let loadingLogin = useSelector((state) => state.login.loadingLogin);
  let errorLogin = useSelector((state) => state.login.error);
  let successLogin = useSelector((state) => state.login.success);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (successLogin) {
      window.location.href = "/authentication";
    }
  }, [loadingLogin, successLogin]);

  const validationSchema = yup.object({
    newPassword: yup
      .string("Enter your new password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("New password is required"),
    newPasswordConfirm: yup
      .string("Enter confirmation password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Confirmation password is required")
      .oneOf([yup.ref("newPassword")], "Your passwords do not match."),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      newPasswordConfirm: "",
      token: params.tokenId,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(resetPassword(values));
    },
  });
  return (
    <div css={AuthCss.formBoard}>
      <div className="title">
        <h2>Reset Your Password</h2>
        <p>Please enter your new password</p>
      </div>

      {errorLogin ? (
        <Alert severity="error">Token salah atau sudah tidak berlaku!</Alert>
      ) : (
        ""
      )}

      <form onSubmit={formik.handleSubmit} className="form-group-control">
        <div className="form-group">
          <h5>
            <b>Enter your new password</b>
          </h5>
          <OutlinedInput
            placeholder="Enter your new password"
            name="newPassword"
            type="password"
            variant="inputauth"
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            value={formik.values.newPassword}
            onChange={formik.handleChange}
          />
          <FormHelperText id="new-password">
            <small>
              {formik.touched.newPassword && formik.errors.newPassword}
            </small>
          </FormHelperText>
        </div>

        <div className="form-group">
          <h5>
            <b>Confirm your new password</b>
          </h5>
          <OutlinedInput
            placeholder="confirm your new password"
            name="newPasswordConfirm"
            type="password"
            variant="inputauth"
            error={
              formik.touched.newPasswordConfirm &&
              Boolean(formik.errors.newPasswordConfirm)
            }
            value={formik.values.newPasswordConfirm}
            onChange={formik.handleChange}
          />
          {eyeOpen ? (
            <VisibilityOffOutlinedIcon
              className="visibility-eye"
              onClick={() => setEyeOpen(false)}
            />
          ) : (
            <VisibilityOutlinedIcon
              className="visibility-eye"
              onClick={() => setEyeOpen(true)}
            />
          )}
          <FormHelperText id="confirm-password">
            <small>
              {formik.touched.newPasswordConfirm &&
                formik.errors.newPasswordConfirm}
            </small>
          </FormHelperText>
        </div>

        <LoadingButton
          variant="btn-admin"
          disableElevation
          type="submit"
          loading={loadingLogin}
          disabled={loadingLogin}
        >
          Submit
        </LoadingButton>
      </form>
    </div>
  );
}
