/** @jsxImportSource @emotion/react */
import React from "react";
import { OutlinedInput, FormHelperText, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AuthCss } from "../../styles/admin/auth_css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/features/authentication";
import { useFormik } from "formik";
import * as yup from "yup";

export default function ForgotPassword() {
  let loadingLogin = useSelector((state) => state.login.loadingLogin);
  let errorLogin = useSelector((state) => state.login.error);
  let successLogin = useSelector((state) => state.login.success);
  //   let dataRes = useSelector((state) => state.login.dataRes);
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(forgotPassword(values));
    },
  });

  return (
    <div css={AuthCss.formBoard}>
      <div className="title">
        <h2>Forgot Password</h2>
        <p>Please enter your e-mail</p>
      </div>

      {errorLogin ? <Alert severity="error">Email belum terdaftar!</Alert> : ""}
      {successLogin ? <Alert severity="success">Email terkirim!</Alert> : ""}

      <form onSubmit={formik.handleSubmit} className="form-group-control">
        <div className="form-group">
          <h5>
            <b> Email</b>
          </h5>
          <OutlinedInput
            id="email"
            name="email"
            label="Email"
            placeholder="Example@mail.com"
            type="email"
            variant="inputauth"
            error={formik.touched.email && Boolean(formik.errors.email)}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <FormHelperText id="email">
            <small>{formik.touched.email && formik.errors.email}</small>
          </FormHelperText>
        </div>
        <LoadingButton
          variant="btn-admin"
          disableElevation
          type="submit"
          loading={loadingLogin}
          disabled={loadingLogin}
        >
          Send
        </LoadingButton>
      </form>

      <Link to="/authentication">
        <p className="link-to">
          <small>cancel</small>
        </p>
      </Link>
    </div>
  );
}
