/** @jsxImportSource @emotion/react */
import React from "react";
import { SponsorsCss } from "../../styles/landing/globals_css";
import "../../loader-plugin";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PropTypes from "prop-types";

Sponsors.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default function Sponsors({ data, loading }) {
  const settingOwl = {
    loop: data?.length > 4 ? true : false,
    margin: 30,
    items: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    autoWidth: false,
    dots: false,
    responsive: {
      0: {
        items: 2,
        margin: 10,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <div css={SponsorsCss.self}>
      {loading ? (
        ""
      ) : (
        <OwlCarousel className="owl-theme" {...settingOwl}>
          {data?.length > 0 &&
            data?.map((el, i) => {
              return <img key={i} src={el.image} alt="" />;
            })}
        </OwlCarousel>
      )}
    </div>
  );
}
