import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBannerPersonal = createAsyncThunk(
  "get/bannerPersonalLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/banner?per_page=5&search=banner_personal&banner_type=general`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getDetail = createAsyncThunk(
  "get/detailPersonalLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/slug/personal`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getPortfolio = createAsyncThunk(
  "get/portfolioPersonalLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post?per_page=${arg}&post_type=personal_portfolio`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getPortfolioById = createAsyncThunk(
  "get/portfolioByIdPersonalLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const personalLandingSlice = createSlice({
  name: "personalLandingSlice",
  initialState: {
    loading_banner: false,
    banner_data: [],

    loading_detail: false,
    detail_data: null,

    loading_portfolio: false,
    portfolio_data: [],

    loading_portfolio_id: false,
    portfolio_id_data: null,
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [getBannerPersonal.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBannerPersonal.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBannerPersonal.rejected]: (state, action) => {
      state.loading_banner = false;
    },

    // Detail
    [getDetail.pending]: (state, { payload }) => {
      state.loading_detail = true;
    },
    [getDetail.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.detail_data = payload.data;
      } else {
        state.detail_data = null;
      }
      state.loading_detail = false;
    },
    [getDetail.rejected]: (state, action) => {
      state.loading_detail = false;
    },

    // Portfolio
    [getPortfolio.pending]: (state, { payload }) => {
      state.loading_portfolio = true;
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_data = payload;
      } else {
        state.portfolio_data = [];
      }
      state.loading_portfolio = false;
    },
    [getPortfolio.rejected]: (state, action) => {
      state.loading_portfolio = false;
    },

    // Portfolio By Id
    [getPortfolioById.pending]: (state, { payload }) => {
      state.loading_portfolio_id = true;
    },
    [getPortfolioById.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_id_data = payload.data;
      } else {
        state.portfolio_id_data = null;
      }
      state.loading_portfolio_id = false;
    },
    [getPortfolioById.rejected]: (state, action) => {
      state.loading_portfolio_id = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = personalLandingSlice.actions;

export default personalLandingSlice.reducer;
