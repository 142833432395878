/** @jsxImportSource @emotion/react */
import { Button, Checkbox, Pagination, Skeleton } from "@mui/material";
import React from "react";
import DialogMessageDetail from "../../components/admin/DialogMessageDetail";
import { MessagesCss } from "../../styles/admin/messages_css";
import { useSelector, useDispatch } from "react-redux";
import {
  getMessages,
  getDetailMessage,
  deleteMessage,
  setReadUnfetch,
} from "../../redux/features/admin/messages";
import { format } from "date-fns";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BackdropLoading from "../../components/admin/BackdropLoading";
import { css } from "@mui/material";
import { toast } from "react-toastify";

export default function MessagesAdmin() {
  const MySwal = withReactContent(Swal);
  const messages_data = useSelector(
    (state) => state.MessagesReducer.messages_data
  );

  const meta_data = useSelector((state) => state.MessagesReducer.meta_data);

  const loading_message = useSelector(
    (state) => state.MessagesReducer.loading_message
  );

  const loading_delete = useSelector(
    (state) => state.MessagesReducer.loading_delete
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMessages(1));
  }, [dispatch]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [openDialogEditor, setOpenDialogEditor] = React.useState(false);
  const [idSelected, setIdSelected] = React.useState("");
  const [dataArray, setdataArray] = React.useState([]);

  const openDialogFunc = (id) => {
    dispatch(setReadUnfetch(id));
    dispatch(getDetailMessage(id));
    setIdSelected(id);
    setOpenDialogEditor(true);
  };

  const handleSelected = (e) => {
    if (e.target.checked === true) {
      setdataArray([...dataArray, e.target.value]);
    } else if (e.target.checked === false) {
      let freshArray = dataArray.filter((val) => val !== e.target.value);
      setdataArray([...freshArray]);
    }
  };

  const deleteMessageFunc = (idSingleDelete = "") => {
    if (dataArray.length <= 0 && !idSingleDelete) {
      toast.error(`Belum ada pesan yang ditandai!`);
    } else {
      MySwal.fire({
        title: (
          <p
            css={css`
              color: #000000;
              font-size: 22px;
            `}
          >
            Are you sure to delete{" "}
            {idSingleDelete ? "this message?" : "all selected messages?"}
          </p>
        ),
        confirmButtonText: "Delete",
        confirmButtonColor: "#FF0000",
        cancelButtonText: "Cancel",
        showCancelButton: true,
      }).then(async (res) => {
        if (res.isDismissed) {
        } else {
          await dispatch(deleteMessage(idSingleDelete || dataArray.join(",")));
          dispatch(getMessages(1));
        }
      });
    }
  };
  return (
    <div css={MessagesCss.self}>
      <BackdropLoading loading={loading_delete} />
      <div className="header">
        <h3>Messages</h3>
        <p>
          <small>Total : {meta_data?.pagination.total} Messages</small>
        </p>
        <Button variant="btn-admin-red" onClick={() => deleteMessageFunc()}>
          Delete selected
        </Button>
      </div>

      <div className="list-messages-table">
        {loading_message
          ? [...Array(10)].map((e, index) => {
              return <Skeleton key={index} variant="rounded" height={50} />;
            })
          : messages_data?.map((el, i) => {
              return (
                <div
                  key={el.id}
                  className={`list-messages-table__message ${
                    el.open_count > 0 ? "" : "unread"
                  }`}
                >
                  <Checkbox
                    {...label}
                    onChange={(e) => handleSelected(e)}
                    checked={dataArray.includes(el.id)}
                    value={el.id}
                  />
                  <h4
                    className="list-messages-table__message--name"
                    onClick={() => openDialogFunc(el.id)}
                  >
                    {el.name}
                  </h4>
                  <p onClick={() => openDialogFunc(el.id)}>{el.message}</p>
                  <img
                    src="/icons/admin/delete-left.svg"
                    alt=""
                    onClick={() => deleteMessageFunc(el.id)}
                  />
                  <h4
                    className="list-messages-table__message--date"
                    onClick={() => openDialogFunc(el.id)}
                  >
                    {format(new Date(el.created_at), "dd MMM")}
                  </h4>
                </div>
              );
            })}
      </div>

      <Pagination
        count={meta_data?.pagination.total_pages}
        shape="rounded"
        onChange={(event, page) => dispatch(getMessages(page))}
      />

      <DialogMessageDetail
        open={openDialogEditor}
        data={messages_data?.filter((e) => e.id === idSelected)[0]}
        close={() => setOpenDialogEditor(false)}
      />
    </div>
  );
}
