import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBannerPromotion = createAsyncThunk(
  "get/bannerPromotionLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/banner?per_page=5&search=banner_promotion&banner_type=general`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getPromotionDetail = createAsyncThunk(
  "get/promotionDetailPromotionLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/slug/promotion`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getBrandLogo = createAsyncThunk(
  "get/brandLogoPromotionLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/banner?per_page=99&banner_type=promotion_brand_logo`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getDesignStory = createAsyncThunk(
  "get/designStoryPromotionLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post?per_page=15&post_type=promotion_design_story&search=`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getPortfolio = createAsyncThunk(
  "get/portfolioPromotionLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post?per_page=${arg}&post_type=promotion_portfolio`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getPortfolioById = createAsyncThunk(
  "get/portfolioByIdPromotionLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const homeLandingSlice = createSlice({
  name: "homeLandingSlice",
  initialState: {
    loading_banner: false,
    banner_data: [],

    loading_promotion_detail: false,
    promotion_detail_data: null,

    loading_brand_logo: false,
    brand_logo_data: [],

    loading_design_story: false,
    design_story_data: [],

    loading_portfolio: false,
    portfolio_data: [],

    loading_portfolio_id: false,
    portfolio_id_data: null,
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [getBannerPromotion.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBannerPromotion.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBannerPromotion.rejected]: (state, action) => {
      state.loading_banner = false;
    },

    // Promotion Detail
    [getPromotionDetail.pending]: (state, { payload }) => {
      state.loading_promotion_detail = true;
    },
    [getPromotionDetail.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.promotion_detail_data = payload.data;
      } else {
        state.promotion_detail_data = null;
      }
      state.loading_promotion_detail = false;
    },
    [getPromotionDetail.rejected]: (state, action) => {
      state.loading_promotion_detail = false;
    },

    // Brand Logo
    [getBrandLogo.pending]: (state, { payload }) => {
      state.loading_brand_logo = true;
    },
    [getBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.brand_logo_data = payload.data;
      } else {
        state.brand_logo_data = [];
      }
      state.loading_brand_logo = false;
    },
    [getBrandLogo.rejected]: (state, action) => {
      state.loading_brand_logo = false;
    },

    // Design Story
    [getDesignStory.pending]: (state, { payload }) => {
      state.loading_design_story = true;
    },
    [getDesignStory.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.design_story_data = payload.data;
      } else {
        state.design_story_data = [];
      }
      state.loading_design_story = false;
    },
    [getDesignStory.rejected]: (state, action) => {
      state.loading_design_story = false;
    },

    // Portfolio
    [getPortfolio.pending]: (state, { payload }) => {
      state.loading_portfolio = true;
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_data = payload;
      } else {
        state.portfolio_data = [];
      }
      state.loading_portfolio = false;
    },
    [getPortfolio.rejected]: (state, action) => {
      state.loading_portfolio = false;
    },

    // Portfolio By Id
    [getPortfolioById.pending]: (state, { payload }) => {
      state.loading_portfolio_id = true;
    },
    [getPortfolioById.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_id_data = payload.data;
      } else {
        state.portfolio_id_data = null;
      }
      state.loading_portfolio_id = false;
    },
    [getPortfolioById.rejected]: (state, action) => {
      state.loading_portfolio_id = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = homeLandingSlice.actions;

export default homeLandingSlice.reducer;
