/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import ContentSpiceItUp from "../../components/landing/spiceitup/ContentSpiceItUp";
import Portfolio from "../../components/landing/Portfolio";
import Contact from "../../components/landing/Contact";
import BreadCrumbsLink from "../../components/landing/BreadCrumbsLink";
import Carousel from "../../components/landing/home/Carousel";
import { Container } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  getBanner,
  getDetailSpiceItUp,
  getDetailContentStrategy,
  getPortfolio,
} from "../../redux/features/landing/spice-it-up";

export default function SpiceItUp() {
  const banner_data = useSelector(
    (state) => state.spiceItUpLandingReducer.banner_data
  );
  const loading_banner = useSelector(
    (state) => state.spiceItUpLandingReducer.loading_banner
  );

  const detail_spice_data = useSelector(
    (state) => state.spiceItUpLandingReducer.detail_spice_data
  );
  const loading_detail_spice = useSelector(
    (state) => state.spiceItUpLandingReducer.loading_detail_spice
  );

  const detail_content_strategy_data = useSelector(
    (state) => state.spiceItUpLandingReducer.detail_content_strategy_data
  );
  const loading_detail_content_strategy = useSelector(
    (state) => state.spiceItUpLandingReducer.loading_detail_content_strategy
  );

  const portfolio_data = useSelector(
    (state) => state.spiceItUpLandingReducer.portfolio_data
  );
  const loading_portfolio = useSelector(
    (state) => state.spiceItUpLandingReducer.loading_portfolio
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBanner());
    dispatch(getDetailSpiceItUp());
    dispatch(getDetailContentStrategy());
    dispatch(getPortfolio(6));
  }, [dispatch]);

  const breadcrumbData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Spice it up",
      link: "",
    },
  ];

  return (
    <div>
      <div
        css={css`
          position: relative;
        `}
      >
        <Carousel data={banner_data} loading={loading_banner} story="" />
        <div
          css={css`
            position: absolute;
            bottom: -250px;
            left: -100px;
            background-image: url(images/logo-spiceitup-bg.png);
            background-size: 100%;
            background-position: 20%;
            background-repeat: no-repeat;
            height: 450px;
            width: 550px;
            z-index: -1;
            @media only screen and (max-width: 720px) {
              height: 200px;
              width: 75%;
              bottom: -179px;
              left: -25px;
            }
          `}
        ></div>
        <img
          src="images/logo-spiceitup.png"
          alt="logo spiceitup"
          css={css`
            position: absolute;
            bottom: -50px;
            left: 50px;
            @media only screen and (max-width: 720px) {
              bottom: -25px;
              left: 10px;
              width: 25%;
            }
          `}
        />
      </div>
      <Container>
        <div
          css={css`
            width: fit-content;
            margin: 30px auto 60px auto;
            @media only screen and (max-width: 720px) {
              margin: 10px 0 50px auto;
            }
          `}
        >
          <BreadCrumbsLink titleBreadcrumbs={breadcrumbData} />
        </div>
      </Container>

      <ContentSpiceItUp
        title={detail_spice_data?.title}
        description={detail_spice_data?.description}
        socialMedia={true}
        instagram={detail_spice_data?.linkedin}
        youtube={detail_spice_data?.youtube}
        image={detail_spice_data?.image}
        reverseGrid={false}
        loading={loading_detail_spice}
        css={css`
          margin-bottom: 20px;
        `}
      />

      <ContentSpiceItUp
        title={detail_content_strategy_data?.title}
        description={detail_content_strategy_data?.description}
        socialMedia={false}
        instagram={detail_content_strategy_data?.instagram}
        youtube={detail_content_strategy_data?.youtube}
        image={detail_content_strategy_data?.image}
        reverseGrid={true}
        loading={loading_detail_content_strategy}
        css={css`
          margin-bottom: 30px;
        `}
      />

      <Portfolio
        data={portfolio_data}
        loading={loading_portfolio}
        colorTheme="#E35B29"
        baseLink="/spiceitup/portfolio/"
        loadHide={(e) => dispatch(getPortfolio(e === "load" ? 999 : 6))}
      />
      <Contact />
    </div>
  );
}
