/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { Container } from "@mui/system";
import { NavbarCss } from "../../styles/landing/globals_css";
import { Link, useLocation } from "react-router-dom";
import { css } from "@mui/system";

export default function NavbarDesktop() {
  useEffect(() => {
    document.addEventListener("scroll", () => {
      let imageLogo = document.querySelector("img");
      if (window.scrollY > 150) {
        imageLogo?.classList.add("scrolled");
      } else {
        imageLogo?.classList.remove("scrolled");
      }
    });
  }, []);

  let locationHome = useLocation().pathname === "/" ? true : false;

  return (
    <div css={NavbarCss(locationHome).self}>
      <Container maxWidth="lg" css={NavbarCss(locationHome).container}>
        <div css={NavbarCss(locationHome).boxListMenu}>
          <Link
            to="/"
            css={css`
              color: ${useLocation().pathname === "/" ? "#de5b28" : "inherit"};
            `}
          >
            Home
          </Link>
          <Link
            to="/promotion-production"
            css={css`
              color: ${useLocation().pathname === "/promotion-production"
                ? "#de5b28"
                : "inherit"};
            `}
          >
            Promotion & Production
          </Link>
          <Link
            to="/marketing-communications"
            css={css`
              color: ${useLocation().pathname === "/marketing-communications"
                ? "#de5b28"
                : "inherit"};
            `}
          >
            Marketing <br /> Communications
          </Link>
        </div>
        <Link to="/">
          <img src="/icons/molecula-icon.svg" alt="molecula" />
        </Link>
        <div css={NavbarCss(locationHome).boxListMenu} className="right">
          <Link
            to="/public-relation"
            css={css`
              color: ${useLocation().pathname === "/public-relation"
                ? "#de5b28"
                : "inherit"};
            `}
          >
            Public Relations as <br /> Reputation Management
          </Link>
          <Link
            to="/portfolio"
            css={css`
              color: ${useLocation().pathname === "/portfolio"
                ? "#de5b28"
                : "inherit"};
            `}
          >
            Portfolio
          </Link>
          <Link
            to="/about-us"
            css={css`
              color: ${useLocation().pathname === "/about-us"
                ? "#de5b28"
                : "inherit"};
            `}
          >
            About Us
          </Link>
        </div>
      </Container>
    </div>
  );
}
