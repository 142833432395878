import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBanner = createAsyncThunk(
  "get/bannerHomeLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/banner?per_page=17&search=banner_home_pag&banner_type=general`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getYoutubeList = createAsyncThunk(
  "get/youtubeListHomeLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post?per_page=6&post_type=home_page_youtube_video&search=`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getNews = createAsyncThunk(
  "get/newsHomeLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post?per_page=${arg}&post_type=news`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getBannerSpice = createAsyncThunk(
  "get/banner-spiceHomeLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/banner?per_page=6&search=banner_spice_it_up&banner_type=general`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const homeLandingSlice = createSlice({
  name: "homeLandingSlice",
  initialState: {
    loading_banner: false,
    banner_data: [],

    loading_youtube: false,
    youtube_data: [],

    loading_news: false,
    news_data: [],

    loading_banner_spice: false,
    banner_spice_data: [],
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [getBanner.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBanner.rejected]: (state, action) => {
      state.loading_banner = false;
    },

    // Youtube List
    [getYoutubeList.pending]: (state, { payload }) => {
      state.loading_youtube = true;
    },
    [getYoutubeList.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.youtube_data = payload.data;
      } else {
        state.youtube_data = [];
      }
      state.loading_youtube = false;
    },
    [getYoutubeList.rejected]: (state, action) => {
      state.loading_youtube = false;
    },

    // News
    [getNews.pending]: (state, { payload }) => {
      state.loading_news = true;
    },
    [getNews.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.news_data = payload;
      } else {
        state.news_data = [];
      }
      state.loading_news = false;
    },
    [getNews.rejected]: (state, action) => {
      state.loading_news = false;
    },

    // Spice it up
    [getBannerSpice.pending]: (state, { payload }) => {
      state.loading_banner_spice = true;
    },
    [getBannerSpice.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_spice_data = payload.data;
      } else {
        state.banner_spice_data = [];
      }
      state.loading_banner_spice = false;
    },
    [getBannerSpice.rejected]: (state, action) => {
      state.loading_banner_spice = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = homeLandingSlice.actions;

export default homeLandingSlice.reducer;
