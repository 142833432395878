/** @jsxImportSource @emotion/react */
import React from "react";
import { Grid } from "@mui/material";
import { css } from "@emotion/react";
import { FooterCss } from "../../styles/landing/globals_css";
import { useSelector, useDispatch } from "react-redux";
import { getContact } from "../../redux/features/landing/contact";
import { getDetail } from "../../redux/features/landing/about-us";
import { Link } from "react-router-dom";
import YouTubeIcon from "@mui/icons-material/YouTube";

export default function Footer() {
  const contact_data = useSelector(
    (state) => state.contactLandingReducer.contact_data
  );
  const loading_contact = useSelector(
    (state) => state.contactLandingReducer.loading_contact
  );
  // Detail
  const detail_data = useSelector(
    (state) => state.aboutUsLandingReducer.detail_data
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getContact());
    dispatch(getDetail());
  }, [dispatch]);

  return (
    <div>
      <Grid container>
        <Grid item md={3} css={FooterCss.sitemap}>
          <h2>
            <b>Sitemap</b>
          </h2>
          <Link to="/">Home</Link>
          <Link to="/promotion-production">Promotion & Production</Link>
          <Link to="/marketing-communications">Marketing Communications</Link>
          <Link to="/public-relation">
            Public Relations as Reputation Management
          </Link>
          <Link to="/portfolio">Portfolio</Link>
          <Link to="/about-us">About Us</Link>
        </Grid>
        <Grid item md={6} css={FooterCss.aboutUs}>
          <h2>
            <b>About Us</b>
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: detail_data?.description?.replaceAll("<p></p>", "<br/>"),
            }}
          />
          <div className="socmed">
            <a
              href={contact_data ? contact_data[4]?.value : null}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="icons/instagram-icon-white.svg" alt="" />
            </a>
            <a
              href={contact_data ? contact_data[5]?.value : null}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="icons/fb-icon-white.svg" alt="" />
            </a>
            <a
              href={contact_data ? contact_data[3]?.value : null}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="icons/twitter-icon-white.svg" alt="" />
            </a>
            <a
              href={contact_data ? contact_data[6]?.value : null}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="icons/linkedin-icon-white.svg" alt="" />
            </a>
            <a
              href={contact_data ? contact_data[7]?.value : null}
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon
                color="white"
                sx={{
                  marginTop: "2.5px",
                  marginLeft: "-3px",
                  "&:hover": {
                    color: "white",
                  },
                }}
              />
            </a>
          </div>
        </Grid>
        {loading_contact ? (
          ""
        ) : (
          <Grid item md={3} css={FooterCss.contact}>
            <h2>
              <b>Contact</b>
            </h2>
            <div className="text-contact">
              <p
                css={css`
                  margin-right: 15px;
                `}
              >
                A.
              </p>
              <p>{contact_data ? contact_data[0]?.value : null}</p>
            </div>
            <div className="text-contact">
              <p
                css={css`
                  margin-right: 15px;
                `}
              >
                T.
              </p>
              <p>{contact_data ? contact_data[1]?.value : null}</p>
            </div>
            <div className="text-contact">
              <p
                css={css`
                  margin-right: 15px;
                `}
              >
                E.
              </p>
              <p>{contact_data ? contact_data[2]?.value : null}</p>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
