import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getMember = createAsyncThunk(
  "get/memberAboutUsLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post?per_page=20&post_type=member&search=`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getBanner = createAsyncThunk(
  "get/bannerAboutUsLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/banner?per_page=17&search=banner_about_us`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getDetail = createAsyncThunk(
  "get/detailAboutUsLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/slug/about-us`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const memberLandingSlice = createSlice({
  name: "memberLandingSlice",
  initialState: {
    loading_member: false,
    member_data: [],

    loading_banner: false,
    banner_data: [],

    loading_detail: false,
    detail_data: null,
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [getMember.pending]: (state, { payload }) => {
      state.loading_member = true;
    },
    [getMember.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.member_data = payload.data;
      } else {
        state.member_data = [];
      }
      state.loading_member = false;
    },
    [getMember.rejected]: (state, action) => {
      state.loading_member = false;
    },
    // Banner
    [getBanner.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBanner.rejected]: (state, action) => {
      state.loading_banner = false;
    },
    // Detail
    [getDetail.pending]: (state, { payload }) => {
      state.loading_detail = true;
    },
    [getDetail.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.detail_data = payload.data;
      } else {
        state.detail_data = [];
      }
      state.loading_detail = false;
    },
    [getDetail.rejected]: (state, action) => {
      state.loading_detail = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = memberLandingSlice.actions;

export default memberLandingSlice.reducer;
