/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";

BackdropLoading.propTypes = {
  loading: PropTypes.bool,
};

export default function BackdropLoading({ loading = false }) {
  return (
    <React.Fragment>
      {loading ? (
        <div
          css={css`
            position: fixed;
            height: 100vh;
            width: 100%;
            background-color: rgb(0 0 0 / 24%);
            z-index: 9999;
            top: 0;
            left: 0;
          `}
        >
          <img
            src="/icons/loading-icon.png"
            alt=""
            css={css`
              animation: spinning 2s infinite;
              position: absolute;
              top: 45%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 4.5%;
              @keyframes spinning {
                0% {
                  transform: rotate(0);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            `}
          />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
