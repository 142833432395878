/** @jsxImportSource @emotion/react */
import React from "react";
import ImagesUpload from "../../components/admin/ImagesUpload";
import ContentNoDialog from "../../components/admin/ContentNoDialog";
import MediaUploader from "../../components/admin/MediaUploader";
import Separator from "../../components/admin/Separator";
import { css } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTitle,
  updateTitle,
  getBanner,
  putImageBanner,
  getPortfolio,
  createPortfolio,
  updatePortfolio,
  deletePortfolio,
} from "../../redux/features/admin/personal";

export default function PersonalAdmin() {
  const banner_data = useSelector((state) => state.personalReducer.banner_data);
  const loading_banner = useSelector(
    (state) => state.personalReducer.loading_banner
  );
  const loading_upload = useSelector(
    (state) => state.personalReducer.loading_upload_banner
  );
  const title_text = useSelector((state) => state.personalReducer.title);
  const loading = useSelector((state) => state.personalReducer.loading);
  const loading_update_title_text = useSelector(
    (state) => state.personalReducer.loading_update
  );

  //   Portfolio
  const loading_portfolio = useSelector(
    (state) => state.personalReducer.loading_portfolio
  );
  const loading_update_portfolio = useSelector(
    (state) => state.personalReducer.loading_update_portfolio
  );
  const portfolio_datas = useSelector(
    (state) => state.personalReducer.portfolio_datas
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTitle());
    dispatch(getBanner());
    dispatch(getPortfolio());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadImage = async (el) => {
    await dispatch(putImageBanner(el));
    dispatch(getBanner());
  };

  const updateTitleFunc = async (values) => {
    await dispatch(updateTitle(values));
    dispatch(getTitle());
  };

  //   Portfolio
  const addItemPortfolio = async (event) => {
    await dispatch(createPortfolio(event));
    dispatch(getPortfolio());
  };

  const updateItemPortfolio = async (event) => {
    await dispatch(updatePortfolio(event));
    dispatch(getPortfolio());
  };

  const deleteItemPortfolio = async (e) => {
    await dispatch(deletePortfolio(e));
    dispatch(getPortfolio());
  };
  const data_details_banner = [
    {
      img_dimension: "1440 x 228",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
  ];

  const data_details_cover = [
    {
      img_dimension: "1026 x 1146",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
  ];

  return (
    <div>
      <ImagesUpload
        title="Upload Banner Image (Personal)"
        data={banner_data
          ?.filter((e) => e.slug === "banner_personal_header")
          .map((el, i) => {
            return {
              id: el.id,
              image: el.image,
              img_dimension: data_details_banner[i].img_dimension,
              max_size: data_details_banner[i].max_size,
              supported_file: data_details_banner[i].supported_file,
            };
          })}
        loadingData={loading_banner}
        loading={loading_upload}
        uploadFunc={(e) => uploadImage(e)}
      />

      <div
        css={css`
          margin-top: 20px;
        `}
      >
        <ImagesUpload
          title="Upload Cover Image (Personal)"
          data={banner_data
            ?.filter((e) => e.slug === "banner_personal_1")
            .map((el, i) => {
              return {
                id: el.id,
                image: el.image,
                img_dimension: data_details_cover[i].img_dimension,
                max_size: data_details_cover[i].max_size,
                supported_file: data_details_cover[i].supported_file,
              };
            })}
          loadingData={loading_banner}
          loading={loading_upload}
          uploadFunc={(e) => uploadImage(e)}
        />
      </div>

      <ContentNoDialog
        title={{ show: true, value: title_text }}
        description={{ show: false, value: "" }}
        instagram={{ show: false, value: "" }}
        youtube={{ show: false, value: "" }}
        loading={loading}
        loadingSave={loading_update_title_text}
        saveFunc={(e) => updateTitleFunc(e)}
        resetValue={() => dispatch(getTitle())}
      />
      <Separator />
      <MediaUploader
        title="Portfolio"
        data={portfolio_datas}
        loading={loading_portfolio}
        saveDataLoading={loading_update_portfolio}
        formOnDialog={{
          singleImageUpload: {
            show: true,
            title: "Upload Image (Brand Logo)",
            imageDimension: "1029 x 576",
            maxFile: "2.0 MB",
            supportedFile: "PNG,JPG",
          },
          youtubeLink: true,
          brandName: true,
          portfolioDetails: true,
          brandDescription: true,
          masonryImages: true,
        }}
        addItem={(e) => addItemPortfolio(e)}
        deleteItem={(e) => deleteItemPortfolio(e)}
        saveData={(e) => updateItemPortfolio(e)}
        type="image/video"
        imageDimension="1029 x 576"
        maxFile="2.0 MB"
        supportedFile="PNG,JPG"
      />
    </div>
  );
}
