/** @jsxImportSource @emotion/react */
import React from "react";
import ImagesUpload from "../../components/admin/ImagesUpload";
import ContentNoDialog from "../../components/admin/ContentNoDialog";
import MediaUploader from "../../components/admin/MediaUploader";
import Separator from "../../components/admin/Separator";
import { useSelector, useDispatch } from "react-redux";
import {
  getTitleDesc,
  updateTitleDesc,
  getBanner,
  putImageBanner,
  getBrandLogo,
  createBrandLogo,
  updateBrandLogo,
  deleteBrandLogo,
  getDesignStory,
  createDesignStory,
  deleteDesignStory,
  updateDesignStory,
  getPortfolio,
  createPortfolio,
  updatePortfolio,
  deletePortfolio,
} from "../../redux/features/admin/promotion";

export default function PromotionAdmin() {
  // Banner
  const banner_data = useSelector(
    (state) => state.promotionReducer.banner_data
  );
  const loading_banner = useSelector(
    (state) => state.promotionReducer.loading_banner
  );
  const loading_upload = useSelector(
    (state) => state.promotionReducer.loading_upload_banner
  );

  //   Title Description
  const title_text = useSelector((state) => state.promotionReducer.title);
  const desc_text = useSelector((state) => state.promotionReducer.description);
  const loading = useSelector((state) => state.promotionReducer.loading);
  const loading_save_description = useSelector(
    (state) => state.promotionReducer.loading_save_description
  );

  // Brand Logo
  const loading_brand_logo = useSelector(
    (state) => state.promotionReducer.loading_brand_logo
  );
  const loading_update_brand_logo = useSelector(
    (state) => state.promotionReducer.loading_update_brand_logo
  );
  const brand_logo_datas = useSelector(
    (state) => state.promotionReducer.brand_logo_datas
  );

  //   Design Story
  const loading_design_story = useSelector(
    (state) => state.promotionReducer.loading_design_story
  );
  const loading_update_design_story = useSelector(
    (state) => state.promotionReducer.loading_update_design_story
  );
  const design_story_datas = useSelector(
    (state) => state.promotionReducer.design_story_datas
  );

  //   Portfolio
  const loading_portfolio = useSelector(
    (state) => state.promotionReducer.loading_portfolio
  );
  const loading_update_portfolio = useSelector(
    (state) => state.promotionReducer.loading_update_portfolio
  );
  const portfolio_datas = useSelector(
    (state) => state.promotionReducer.portfolio_datas
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTitleDesc());
    dispatch(getBanner());
    dispatch(getBrandLogo());
    dispatch(getDesignStory());
    dispatch(getPortfolio());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadImage = async (el) => {
    await dispatch(putImageBanner(el));
    dispatch(getBanner());
  };

  const updateTitleDescFunc = async (values) => {
    await dispatch(updateTitleDesc(values));
    dispatch(getTitleDesc());
  };

  const addItemBrandLogo = async () => {
    await dispatch(createBrandLogo(""));
    dispatch(getBrandLogo());
  };

  const updateItemBrandLogo = async (e) => {
    await dispatch(updateBrandLogo(e));
    dispatch(getBrandLogo());
  };

  const deleteItemBrandLogo = async (e) => {
    await dispatch(deleteBrandLogo(e));
    dispatch(getBrandLogo());
  };

  const addItemDesignStory = async (event) => {
    await dispatch(createDesignStory(event));
    dispatch(getDesignStory());
  };

  const updateItemDesignStory = async (event) => {
    await dispatch(updateDesignStory(event));
    dispatch(getDesignStory());
  };

  const deleteItemDesignStory = async (e) => {
    await dispatch(deleteDesignStory(e));
    dispatch(getDesignStory());
  };

  //   Portfolio
  const addItemPortfolio = async (event) => {
    await dispatch(createPortfolio(event));
    dispatch(getPortfolio());
  };

  const updateItemPortfolio = async (event) => {
    await dispatch(updatePortfolio(event));
    dispatch(getPortfolio());
  };

  const deleteItemPortfolio = async (e) => {
    await dispatch(deletePortfolio(e));
    dispatch(getPortfolio());
  };

  const data_details_banner = [
    {
      img_dimension: "1407 x 1273",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
  ];

  return (
    <div>
      <ImagesUpload
        title="Upload Banner Image"
        data={banner_data?.map((el, i) => {
          return {
            id: el.id,
            image: el.image,
            img_dimension: data_details_banner[i].img_dimension,
            max_size: data_details_banner[i].max_size,
            supported_file: data_details_banner[i].supported_file,
          };
        })}
        detailsImage={data_details_banner}
        loadingData={loading_banner}
        loading={loading_upload}
        uploadFunc={(e) => uploadImage(e)}
      />
      <ContentNoDialog
        title={{ show: true, value: title_text }}
        description={{ show: true, value: desc_text }}
        instagram={{ show: false, value: "" }}
        youtube={{ show: false, value: "" }}
        loading={loading}
        loadingSave={loading_save_description}
        saveFunc={(e) => updateTitleDescFunc(e)}
        resetValue={() => dispatch(getTitleDesc())}
      />
      <Separator />
      <MediaUploader
        title="Upload Brand Logo"
        noDialog={true}
        data={brand_logo_datas}
        loading={loading_brand_logo}
        saveDataLoading={loading_update_brand_logo}
        addItem={() => addItemBrandLogo()}
        uploadDataDirect={(e) => updateItemBrandLogo(e)}
        deleteItem={(e) => deleteItemBrandLogo(e)}
        type="image"
        imageDimension=""
        maxFile="2.0 MB"
        supportedFile="PNG"
      />
      <Separator />
      <MediaUploader
        title="Design Story"
        data={design_story_datas}
        loading={loading_design_story}
        saveDataLoading={loading_update_design_story}
        addItem={(e) => addItemDesignStory(e)}
        deleteItem={(e) => deleteItemDesignStory(e)}
        formOnDialog={{
          headingTitle: true,
          contentDesription: true,
          singleImageUpload: {
            show: true,
            title: "Upload Image",
            imageDimension: "1440 x 629",
            maxFile: "2.0 MB",
            supportedFile: "JPG,JPEG,PNG",
          },
        }}
        saveData={(e) => updateItemDesignStory(e)}
        type="image"
        imageDimension="1742 x 761"
        maxFile="2.0 MB"
        supportedFile="JPG,JPEG,PNG"
      />
      <Separator />
      <MediaUploader
        title="Portfolio"
        data={portfolio_datas}
        loading={loading_portfolio}
        saveDataLoading={loading_update_portfolio}
        formOnDialog={{
          singleImageUpload: {
            show: true,
            title: "Upload Image (Brand Logo)",
            imageDimension: "1029 x 576",
            maxFile: "2.0 MB",
            supportedFile: "PNG,JPG",
          },
          youtubeLink: true,
          brandName: true,
          portfolioDetails: true,
          brandDescription: true,
          masonryImages: true,
        }}
        addItem={(e) => addItemPortfolio(e)}
        deleteItem={(e) => deleteItemPortfolio(e)}
        saveData={(e) => updateItemPortfolio(e)}
        type="image/video"
        imageDimension="1052 x 589"
        maxFile="2.0 MB"
        supportedFile="PNG,JPG"
      />
    </div>
  );
}
