/** @jsxImportSource @emotion/react */
import React from "react";
import { ContentNoDialogCss } from "../../styles/admin/content-no-dialog_css";
import { OutlinedInput, Button, Grid } from "@mui/material";
import TextAreaEditor from "./TextAreaEditor";
import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import Skeleton from "@mui/material/Skeleton";
import BackdropLoading from "./BackdropLoading";

ContentNoDialog.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  instagram: PropTypes.object,
  youtube: PropTypes.object,
  saveFunc: PropTypes.func,
  loading: PropTypes.bool,
  loadingSave: PropTypes.bool,
  resetValue: PropTypes.func,
};

export default function ContentNoDialog({
  title = { show: false, value: "" },
  description = { show: false, value: "" },
  instagram = { show: false, value: "" },
  youtube = { show: false, value: "" },
  saveFunc = () => {},
  loading = false,
  loadingSave = false,
  resetValue = () => {},
}) {
  const [descValue, setDescValue] = React.useState("");
  const [descValueFromEditor, setDescValueFromEditor] = React.useState("");

  const [titleValue, setTitleValue] = React.useState("");
  const [instagramValue, setInstagramValue] = React.useState("");
  const [youtubeValue, setYoutubeValue] = React.useState("");

  React.useEffect(() => {
    setTitleValue(title.value || "");

    setDescValue(description.value || "");
    // setDescValueFromEditor(description.value || "");

    setInstagramValue(instagram.value || "");
    setYoutubeValue(youtube.value || "");
  }, [title, description, instagram, youtube]);

  // const cancelFunc = () => {
  //   setTitleValue(title.value || "");

  //   setDescValue(description.value || "");
  //   setDescValueFromEditor(description.value || "");

  //   setInstagramValue(instagram.value || "");
  //   setYoutubeValue(youtube.value || "");
  // };

  const loading_skeleton = (
    <div className="loading-box">
      <Skeleton animation="wave" className="skeleton-wave-form" />
      <OutlinedInput placeholder="" type="text" />
    </div>
  );
  return (
    <div css={ContentNoDialogCss.self}>
      <BackdropLoading loading={loadingSave} />
      <form className="form-group-control">
        {title.show ? (
          <div className="form-group">
            <h3>
              <b>Title</b>
            </h3>
            {loading ? (
              loading_skeleton
            ) : (
              <OutlinedInput
                id="title"
                value={titleValue}
                onInput={(e) => setTitleValue(e.target.value)}
                placeholder=""
                type="text"
              />
            )}
          </div>
        ) : (
          ""
        )}

        {description.show ? (
          <div className="form-group">
            <h3>
              <b>Description</b>
            </h3>
            {loading ? (
              <TextAreaEditor loading={loading} />
            ) : (
              <TextAreaEditor
                value={descValue}
                handleChange={(e) => setDescValueFromEditor(e)}
              />
            )}
          </div>
        ) : (
          ""
        )}

        <Grid container spacing={3}>
          {instagram.show ? (
            <Grid item md={6}>
              <div className="form-group">
                <h3>
                  <b>Instagram</b>
                </h3>
                {loading ? (
                  loading_skeleton
                ) : (
                  <OutlinedInput
                    id="instagram"
                    value={instagramValue}
                    onInput={(e) => setInstagramValue(e.target.value)}
                    placeholder=""
                    type="text"
                  />
                )}
              </div>
            </Grid>
          ) : (
            ""
          )}
          {youtube.show ? (
            <Grid item md={6}>
              <div className="form-group">
                <h3>
                  <b>Youtube</b>
                </h3>
                {loading ? (
                  loading_skeleton
                ) : (
                  <OutlinedInput
                    id="youtube"
                    value={youtubeValue}
                    onInput={(e) => setYoutubeValue(e.target.value)}
                    placeholder=""
                    type="text"
                  />
                )}
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </form>

      <div className="action-content">
        <LoadingButton
          variant="btn-admin"
          loading={loading}
          disabled={loading}
          onClick={() =>
            saveFunc({
              title: titleValue,
              desc: descValueFromEditor || descValue,
              instagram: instagramValue,
              youtube: youtubeValue,
            })
          }
        >
          Save changes
        </LoadingButton>
        <Button variant="btn-admin-outline-red" onClick={() => resetValue()}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
