import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBanner = createAsyncThunk(
  "get/bannerMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/banner?flag=banner_marketing`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const putImageBanner = createAsyncThunk(
  "put/imagebannerMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/banner/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getTitleDesc = createAsyncThunk(
  "get/TitleDescMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post/slug/marketing`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateTitleDesc = createAsyncThunk(
  "put/updateTitleDescMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/slug/marketing`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.title,
          description: arg.desc,
          post_type: "single_page",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

//
//
//
// Brand Logo
export const getBrandLogo = createAsyncThunk(
  "get/brandlogoMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/banner?banner_type=marketing_brand_logo`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createBrandLogo = createAsyncThunk(
  "create/brandLogoMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/banner`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: "-",
          banner_type: "marketing_brand_logo",
          image: arg,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateBrandLogo = createAsyncThunk(
  "update/brandLogoMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/banner/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: "-",
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deleteBrandLogo = createAsyncThunk(
  "delete/brandLogoMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/banner/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

//
//
// DESIGN STORY
export const getContentStrategy = createAsyncThunk(
  "get/contentStrategyMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post?post_type=marketing_design_story`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createContentStrategy = createAsyncThunk(
  "create/ContentStrategyMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/post`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.headingTitle,
          description: arg.contentDesription,
          post_type: "marketing_design_story",
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateContentStrategy = createAsyncThunk(
  "update/ContentStrategyMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    const data_params = {
      title: arg.headingTitle,
      description: arg.contentDesription,
      post_type: "marketing_design_story",
      image: arg.image,
    };
    if (data_params.image.includes("https")) {
      delete data_params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data_params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deleteContentStrategy = createAsyncThunk(
  "delete/ContentStrategyMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

//
//
// Portfolio
export const getPortfolio = createAsyncThunk(
  "get/portfolioMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post?post_type=marketing_portfolio`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createPortfolio = createAsyncThunk(
  "create/portfolioMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/post`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.brandName,
          description: arg.brandDescription,
          post_type: "marketing_portfolio",
          image: arg.youtubeLink ? "" : arg.image,
          detail: arg.portfolioDetails,
          youtube: arg.youtubeLink,
          post_images: arg.postImages,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updatePortfolio = createAsyncThunk(
  "update/portfolioMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    arg.postImages = arg.postImages.map((el) => {
      if (el.image.includes("https")) {
        return { image: null };
      } else {
        return { image: el.image };
      }
    });
    const data_params = {
      title: arg.brandName,
      description: arg.brandDescription,
      detail: arg.portfolioDetails,
      post_type: "marketing_portfolio",
      image: arg.image,
      youtube: arg.youtubeLink,
      post_images: arg.postImages,
    };
    if (data_params.image.includes("http")) {
      delete data_params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data_params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deletePortfolio = createAsyncThunk(
  "delete/portfolioMarketingAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const marketingSlice = createSlice({
  name: "marketingSlice",
  initialState: {
    loading_banner: false,
    banner_data: [],
    loading_upload_banner: false,

    loading: false,
    loading_update: false,
    title: "",
    description: "",

    brand_logo_datas: [],
    loading_brand_logo: false,
    loading_update_brand_logo: false,

    content_strategy_datas: [],
    loading_content_strategy: false,
    loading_update_content_strategy: false,

    portfolio_datas: [],
    loading_portfolio: false,
    loading_update_portfolio: false,
  },
  reducers: {
    addMore: (state) => {},
  },
  extraReducers: {
    [getBanner.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBanner.rejected]: (state, action) => {
      state.loading_banner = false;
    },
    //   ---------- || ----------
    [putImageBanner.pending]: (state, { payload }) => {
      state.loading_upload_banner = true;
    },
    [putImageBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
      } else {
      }
      state.loading_upload_banner = false;
    },
    [putImageBanner.rejected]: (state, action) => {
      state.loading_upload_banner = false;
    },
    //   ---------- || ----------
    [updateTitleDesc.pending]: (state, { payload }) => {
      state.loading_update = true;
    },
    [updateTitleDesc.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update = false;
    },
    [updateTitleDesc.rejected]: (state, action) => {
      state.loading_update = false;
    },
    [getTitleDesc.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getTitleDesc.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.title = payload.data.title;
        state.description = payload.data.description;
      } else {
        state.title = "";
        state.description = "";
      }
      state.loading = false;
    },
    [getTitleDesc.rejected]: (state, action) => {
      state.loading = false;
    },
    //
    //
    //
    //   Brand Logo || ----------
    [getBrandLogo.pending]: (state, { payload }) => {
      state.loading_brand_logo = true;
    },
    [getBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.brand_logo_datas = payload.data;
      } else {
        state.brand_logo_datas = [];
      }
      state.loading_brand_logo = false;
    },
    [getBrandLogo.rejected]: (state, action) => {
      state.loading_brand_logo = false;
    },
    [createBrandLogo.pending]: (state, { payload }) => {
      state.loading_update_brand_logo = true;
    },
    [createBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_brand_logo = false;
    },
    [createBrandLogo.rejected]: (state, action) => {
      state.loading_update_brand_logo = false;
    },
    [updateBrandLogo.pending]: (state, { payload }) => {
      state.loading_update_brand_logo = true;
    },
    [updateBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_brand_logo = false;
    },
    [updateBrandLogo.rejected]: (state, action) => {
      state.loading_update_brand_logo = false;
    },
    [deleteBrandLogo.pending]: (state, { payload }) => {
      state.loading_update_brand_logo = true;
    },
    [deleteBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_brand_logo = false;
    },
    [deleteBrandLogo.rejected]: (state, action) => {
      state.loading_update_brand_logo = false;
    },
    //
    //
    //
    //   Design Story || ----------
    [getContentStrategy.pending]: (state, { payload }) => {
      state.loading_content_strategy = true;
    },
    [getContentStrategy.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.content_strategy_datas = payload.data;
      } else {
        state.content_strategy_datas = [];
      }
      state.loading_content_strategy = false;
    },
    [getContentStrategy.rejected]: (state, action) => {
      state.loading_content_strategy = false;
    },
    [createContentStrategy.pending]: (state, { payload }) => {
      state.loading_update_content_strategy = true;
    },
    [createContentStrategy.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_content_strategy = false;
    },
    [createContentStrategy.rejected]: (state, action) => {
      state.loading_update_content_strategy = false;
    },
    //   ---------- || ----------
    [updateContentStrategy.pending]: (state, { payload }) => {
      state.loading_update_content_strategy = true;
    },
    [updateContentStrategy.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_content_strategy = false;
    },
    [updateContentStrategy.rejected]: (state, action) => {
      state.loading_update_content_strategy = false;
    },
    //   ---------- || ----------
    [deleteContentStrategy.pending]: (state, { payload }) => {
      state.loading_update_content_strategy = true;
    },
    [deleteContentStrategy.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_content_strategy = false;
    },
    [deleteContentStrategy.rejected]: (state, action) => {
      state.loading_update_content_strategy = false;
    },
    //
    //
    //
    //   Portfolio || ----------
    [getPortfolio.pending]: (state, { payload }) => {
      state.loading_portfolio = true;
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_datas = payload.data;
      } else {
        state.portfolio_datas = [];
      }
      state.loading_portfolio = false;
    },
    [getPortfolio.rejected]: (state, action) => {
      state.loading_portfolio = false;
    },
    [createPortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [createPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [createPortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [updatePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [updatePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [updatePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [deletePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [deletePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [deletePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore } = marketingSlice.actions;

export default marketingSlice.reducer;
