/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { ImagesUploadCss } from "../../styles/admin/images-upload_css";
import "../../loader-plugin";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BackdropLoading from "./BackdropLoading";
import { toast } from "react-toastify";

ImagesUpload.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.array,
  box: PropTypes.bool,
  uploadFunc: PropTypes.func,
  loading: PropTypes.bool,
  loadingData: PropTypes.bool,
};

export default function ImagesUpload({
  title,
  subtitle,
  data = [],
  box = true,
  uploadFunc,
  loading = false,
  loadingData = false,
}) {
  const MySwal = withReactContent(Swal);
  const settingOwl = {
    loop: false,
    margin: 20,
    items: 5,
    autoplay: false,
    autoWidth: true,
    dots: false,
    center: !box ? true : false,
  };

  const [imageFiles, setImageFiles] = React.useState([]);

  React.useEffect(() => {
    if (data.length > 0) {
      let arrayImage = [];
      arrayImage = data.map((el, i) => {
        return el.image;
      });
      setImageFiles(() => () => arrayImage);
    }
  }, [data]);

  const handleFileRead = async (event, index, id, img_dimension) => {
    const file = event.target.files[0];
    let arr_img = imageFiles;
    arr_img[index] = URL.createObjectURL(file);
    setImageFiles(arr_img);

    const onUpload = async () => {
      const base64 = await convertBase64(file);
      uploadFunc({ id: id, image: base64 });
    };

    const img_dimension_split = img_dimension
      .replace(" ", "")
      .replace(" ", "")
      .split("x");

    if (file.size > 2 * (1024 * 1024)) {
      toast.error("Size terlalu besar, maksimal 2 MB!");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const { height, width } = image;
          if (
            height !== Number(img_dimension_split[1]) &&
            width !== Number(img_dimension_split[0])
          ) {
            toast.error("Ukuran dimensi gambar tidak sesuai!");
            event.target.value = null;
            return false;
          } else {
            onUpload();
          }
        };
      };
    }
  };

  const deleteImage = (id) => {
    MySwal.fire({
      title: (
        <p
          css={css`
            color: #000000;
            font-size: 22px;
          `}
        >
          Are you sure to delete this content?
        </p>
      ),
      confirmButtonText: "Delete",
      confirmButtonColor: "#FF0000",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      customClass: {
        container: "",
        header: "",
      },
    }).then((res) => {
      if (res.isDismissed) {
        return false;
      } else {
        return uploadFunc({ id: id, image: "" });
      }
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return (
    <div css={ImagesUploadCss.self}>
      <BackdropLoading loading={loading} />
      <div className="title-subtitle">
        <h3>{title}</h3>
        <p>
          <small>{subtitle}</small>
        </p>
      </div>
      <div
        className="trunk-uploader"
        css={css`
          border: 0 ${!box ? "!important" : ""};
          position: relative;
        `}
      >
        {loadingData ? (
          <h3>Loading...</h3>
        ) : (
          <OwlCarousel className="owl-theme" {...settingOwl}>
            {data?.map((el, i) => {
              return (
                <div className="trunk-uploader__crate" key={i}>
                  {el.image ? (
                    <div
                      className="trunk-uploader__crate--img-src"
                      css={css`
                        background-image: url("${el.image}");
                      `}
                    ></div>
                  ) : (
                    <img
                      src={
                        imageFiles[i]
                          ? imageFiles[i]
                          : "/icons/admin/image-upload-dum.svg"
                      }
                      css={css`
                        border-radius: 8px;
                      `}
                      alt=""
                    />
                  )}

                  {el.image ? (
                    ""
                  ) : (
                    <p className="trunk-uploader__crate--detail-img">
                      <small>
                        Image Dimension : {el.img_dimension} <br /> Maximum File
                        Size : {el.max_size} <br /> Supported File :{" "}
                        {el.supported_file}
                      </small>
                    </p>
                  )}
                  {el.image ? (
                    <div>
                      <div
                        css={css`
                          position: relative;
                        `}
                      >
                        <Button variant="btn-admin-green">New Image</Button>
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(e) =>
                            handleFileRead(e, i, el.id, el.img_dimension)
                          }
                          css={css`
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            cursor: pointer;
                          `}
                        />
                      </div>
                      <h5
                        className="trunk-uploader__crate--delete-btn"
                        onClick={() => deleteImage(el.id)}
                      >
                        Delete
                      </h5>
                    </div>
                  ) : (
                    <div
                      css={css`
                        position: relative;
                      `}
                    >
                      <Button variant="btn-admin-outline-orange">
                        Upload Image
                      </Button>
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e) =>
                          handleFileRead(e, i, el.id, el.img_dimension)
                        }
                        css={css`
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          top: 0;
                          left: 0;
                          opacity: 0;
                          cursor: pointer;
                        `}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </OwlCarousel>
        )}
      </div>
    </div>
  );
}
