/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import ArticleTopSection from "../../components/landing/ArticleTopSection";
import Sponsors from "../../components/landing/Sponsors";
import Portfolio from "../../components/landing/Portfolio";
import Contact from "../../components/landing/Contact";
import { useSelector, useDispatch } from "react-redux";
import {
  getBannerPublicRelation,
  getDetail,
  getBrandLogo,
  getPortfolio,
} from "../../redux/features/landing/public-relation";

export default function PublicRelation() {
  const banner_data = useSelector(
    (state) => state.publicRelationLandingReducer.banner_data
  );
  const loading_banner = useSelector(
    (state) => state.publicRelationLandingReducer.loading_banner
  );

  const detail_data = useSelector(
    (state) => state.publicRelationLandingReducer.detail_data
  );
  const loading_detail = useSelector(
    (state) => state.publicRelationLandingReducer.loading_detail
  );

  const brand_logo_data = useSelector(
    (state) => state.publicRelationLandingReducer.brand_logo_data
  );
  const loading_brand_logo = useSelector(
    (state) => state.publicRelationLandingReducer.loading_brand_logo
  );

  const portfolio_data = useSelector(
    (state) => state.publicRelationLandingReducer.portfolio_data
  );
  const loading_portfolio = useSelector(
    (state) => state.publicRelationLandingReducer.loading_portfolio
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBannerPublicRelation());
    dispatch(getDetail());
    dispatch(getBrandLogo());
    dispatch(getPortfolio(6));
  }, [dispatch]);

  const getInTouchRef = React.useRef();
  const executeScroll = () =>
    getInTouchRef?.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  return (
    <div
      css={css`
        margin-top: 60px;
        @media only screen and (max-width: 720px) {
          margin-top: 15px;
        }
      `}
    >
      <ArticleTopSection
        image={banner_data ? banner_data[0]?.image : ""}
        video=""
        title={detail_data?.title}
        desc={detail_data?.description}
        loadingBanner={loading_banner}
        loadingDetail={loading_detail}
        linkButton={"/getintouch"}
        reverseGrid={false}
        breadcrumbData={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: "Public Relations",
            link: "",
          },
        ]}
        getInTouchTo={() => executeScroll()}
      />
      <Sponsors data={brand_logo_data} loading={loading_brand_logo} />
      <Portfolio
        data={portfolio_data}
        loading={loading_portfolio}
        colorTheme="#4285F4"
        baseLink="/public-relation/portfolio/"
        loadHide={(e) => dispatch(getPortfolio(e === "load" ? 999 : 6))}
      />
      <div ref={getInTouchRef}>
        <Contact />
      </div>
    </div>
  );
}
