import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getNewsBySlug = createAsyncThunk(
  "get/NewsBySlugNewsLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/slug/${arg}`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const newsLandingSlice = createSlice({
  name: "newsLandingSlice",
  initialState: {
    loading_news_slug: false,
    news_slug_data: null,
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    // News By Slug
    [getNewsBySlug.pending]: (state, { payload }) => {
      state.loading_news_slug = true;
    },
    [getNewsBySlug.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.news_slug_data = payload;
      } else {
        state.news_slug_data = null;
      }
      state.loading_news_slug = false;
    },
    [getNewsBySlug.rejected]: (state, action) => {
      state.loading_news_slug = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = newsLandingSlice.actions;

export default newsLandingSlice.reducer;
