/** @jsxImportSource @emotion/react */
import { Container } from "@mui/system";
import React from "react";
import { css } from "@emotion/react";
import { ProductsCss } from "../../../styles/landing/home_css";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBannerMarketing } from "../../../redux/features/landing/marketing";
import { getBannerPromotion } from "../../../redux/features/landing/promotion";
import { getBannerPublicRelation } from "../../../redux/features/landing/public-relation";

export default function Products() {
  const banner_data_marketing = useSelector(
    (state) => state.marketingLandingReducer.banner_data
  );
  const banner_data_promotion = useSelector(
    (state) => state.promotionLandingReducer.banner_data
  );
  const banner_data_public = useSelector(
    (state) => state.publicRelationLandingReducer.banner_data
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBannerMarketing());
    dispatch(getBannerPromotion());
    dispatch(getBannerPublicRelation());
  }, [dispatch]);

  const cardImages = [
    {
      id: 1,
      img: banner_data_marketing ? banner_data_marketing[0]?.image : "",
      title: "Marketing Communications",
      link: "/marketing-communications",
    },
    {
      id: 2,
      img: banner_data_public ? banner_data_public[0]?.image : "",
      title: "Public Relations",
      link: "/public-relation",
    },
    {
      id: 3,
      img: banner_data_promotion ? banner_data_promotion[0]?.image : "",
      title: "Promotion & Production",
      link: "/promotion-production",
    },
  ];
  return (
    <div css={ProductsCss.self}>
      <Container
        css={ProductsCss.container}
        sx={{ maxWidth: "1150px" }}
        maxWidth={false}
      >
        <Grid spacing={3} container>
          {cardImages.map((el) => {
            return (
              <Grid key={el.id} item md={4} sm={12} xs={12}>
                <Link to={el.link} className="container-card-img">
                  <div
                    className="card-img"
                    css={css`
                      background-image: url("${el.img}");
                    `}
                  >
                    <div className="card-img__title">{el.title}</div>
                  </div>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
