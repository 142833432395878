import { css } from "@emotion/react";

export const CarouselCss = {
  self: css`
    .slick-dots {
      right: 40px;
      bottom: 20px;
      width: fit-content;
    }
    .skeleton-wave-carousel {
      width: 100%;
      height: 650px;
      @media only screen and (max-width: 720px) {
        height: 230px;
      }
    }
    .bg-carousel {
      background-position: center;
      background-size: cover;
      height: 677px;
      width: 100%;
      text-align: center;
      padding: 70px 0px;
      @media only screen and (max-width: 720px) {
        height: 230px;
        padding: 20px 50px;
      }
      h1 {
        font-size: 50px;
        letter-spacing: 2px;
        @media only screen and (max-width: 720px) {
          font-size: 20px;
          letter-spacing: 1px;
        }
      }
      p {
        @media only screen and (max-width: 720px) {
          font-size: 12px;
        }
      }
    }
    .slick-dots {
      bottom: 17px;
      li {
        margin: 0px;
        button:before {
          font-size: 10px;
          color: #ffffff;
        }
        &.slick-active {
          button:before {
            color: #ffffff;
          }
        }
      }
    }
  `,
  readMore: css`
    margin: 0 40px;
    p {
      margin: 35px 0px;
      @media only screen and (max-width: 720px) {
        font-size: 13px;
        margin: 20px 0px;
      }
    }
    a {
      color: #de5b28;
      font-style: italic;
      font-weight: 600;
      @media only screen and (max-width: 720px) {
        font-size: 14px;
      }
    }
    img {
      margin-left: 10px;
    }
  `,
};

export const ProductsCss = {
  self: css`
    background-color: #eff3ff;
    margin: 90px 0;
    padding: 55px 0;
    @media only screen and (max-width: 720px) {
      margin: 30px 0;
    }
  `,
  container: css`
    @media only screen and (max-width: 720px) {
      padding: 10px 60px;
    }
    .container-card-img {
      .card-img {
        width: 100%;
        min-height: 440px;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 128% 100%;
        cursor: pointer;
        transition: background-size 0.7s;
        @media only screen and (max-width: 720px) {
          min-height: 310px;
        }
        &__title {
          background: #de5b28;
          padding: 25px 0;
          color: #ffffff;
          text-align: center;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          font-size: 22px;
          transition: all 0.5s;
        }
        &:hover {
          background-size: 148% 120%;
          .card-img__title {
            background: #943a17;
          }
        }
      }
    }
  `,
};

export const VideosCss = {
  self: css`
    margin-bottom: 80px;
  `,

  videotron: css`
    width: 100%;
    height: 580px;
    @media only screen and (max-width: 720px) {
      height: 280px;
    }
  `,

  videos: css`
    margin-top: 5px;
    @media only screen and (max-width: 720px) {
      width: 280px;
    }
    iframe {
      margin-bottom: 10px;
      width: 100%;
      height: 260px;
      @media only screen and (max-width: 720px) {
        height: 190px;
      }
    }
    h3,
    p {
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 4px 0;
    }
    p {
      color: #636363;
      span {
        color: #636363 !important;
      }
    }
  `,
};

export const NewsCss = {
  self: css`
    background-color: #eff3ff;
    padding: 70px 0 0 0;
    h1,
    h4,
    h5,
    p {
      margin-block-start: 0;
      margin-block-end: 0;
      margin: 4px 0;
    }

    @media only screen and (max-width: 720px) {
      h4 {
        font-size: 14px;
      }
      h5 {
        font-size: 12px;
      }
      p {
        font-size: 10px;
      }
      a {
        font-size: 12px;
      }
    }

    h1 {
      margin-bottom: 30px;
    }
    p {
      color: #636363;
      a {
        color: #e35b29;
        font-style: italic;
      }
    }
    .rect-skeleton {
      width: 100%;
      height: 190px;
      margin-right: 10px;
      margin-bottom: 5px;
      @media only screen and (max-width: 720px) {
        height: 150px;
        width: 100%;
      }
    }
  `,

  spiceItUp: css`
    background-color: #ffffff;
    padding: 70px 0;
    .text-box {
      background-color: #e35b29;
      padding: 25px 35px;
      background-image: url("images/logo-spiceitup-bg.png");
      background-size: 100%;
      background-position: 20%;
      background-repeat: no-repeat;
      position: relative;
      width: 40%;
      min-height: 470px;
      font-size: 15px;
      img {
        width: 36%;
      }
      @media only screen and (max-width: 720px) {
        background-position: 10% top;
        background-size: 70%;
        width: 100%;
        min-height: 385px;
      }
      img {
        @media only screen and (max-width: 720px) {
          width: 30%;
        }
      }
      p {
        margin-top: 13px;
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        @media only screen and (max-width: 720px) {
          margin-top: 10px;
          font-size: 13px;
        }
      }
      button {
        border-radius: 0;
        font-size: 12px;
        padding: 8px 20px;
        position: absolute;
        bottom: 25px;
        @media only screen and (max-width: 720px) {
          bottom: unset;
          position: unset;
          margin-top: 15px;
        }
      }
    }
    .img-spice {
      // background-image: url("images/spiceitup-img.png");
      // background-size: cover;
      // background-position: center;
      // width: 100%;
      height: 470px;
      width: 60%;
      .slick-slide {
        height: 470px;
        .bg-carousel {
          height: 470px;
        }
      }
      @media only screen and (max-width: 720px) {
        width: 100% !important;
        height: 230px !important;
        .slick-slide {
          height: 230px;
          .bg-carousel {
            height: 230px;
          }
        }
      }
    }
  `,
};

export const MasonryCss = {
  self: css`
    .img-masonry {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 300px;
      &__tall {
        height: 616px;
      }
      @media only screen and (max-width: 720px) {
        height: 100px;
        &__tall {
          height: 216px;
        }
      }
    }
  `,
};
