/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Grid, Button, Container, Skeleton } from "@mui/material";
import { PortfolioCss } from "../../styles/landing/globals_css";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getId } from "../YoutubeIdGetter";

Portfolio.propTypes = {
  data: PropTypes.object,
  colorTheme: PropTypes.string,
  bgColor: PropTypes.string,
  loading: PropTypes.bool,
  baseLink: PropTypes.string,
  loadHide: PropTypes.func,
};

export default function Portfolio({
  data,
  colorTheme = "#4285F4",
  bgColor = "#EFF3FF",
  loading = false,
  baseLink,
  loadHide,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [loadHideVar, setLoadHideVar] = React.useState("load");

  const loadHideFunc = () => {
    loadHide(loadHideVar);
    setLoadHideVar(loadHideVar === "load" ? "hide" : "load");
  };
  return (
    <React.Fragment>
      {data?.data?.length > 0 ? (
        <div css={PortfolioCss(colorTheme, bgColor).self}>
          <Container>
            <div css={PortfolioCss().titleSection}>
              <h1>Our Portfolio</h1>
              <p>
                The portfolio shown here is a combination of the
                personal/individual of the <b>Molecula Indonesia</b> team and
                serves also as study cases of each communication renditions.
              </p>
            </div>

            {loading
              ? [...Array(2)].map((el, i) => {
                  return (
                    <Grid
                      key={i}
                      container
                      spacing={4}
                      css={css`
                        margin-bottom: 20px;
                      `}
                    >
                      <Grid item md={6} xs={12}>
                        <Skeleton
                          variant="rectangular"
                          height={isMobile ? 200 : 280}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Skeleton width="70%" />
                        <Skeleton />
                        <Skeleton />
                      </Grid>
                    </Grid>
                  );
                })
              : data?.data?.length > 0 &&
                data?.data?.map((el) => {
                  let content = "";
                  if (el.youtube) {
                    content = (
                      <iframe
                        src={`//www.youtube-nocookie.com/embed/${getId(
                          el.youtube
                        )}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="Embedded youtube"
                      />
                    );
                  } else {
                    content = <img src={el.image} alt={el.title} />;
                  }
                  return (
                    <Grid
                      container
                      spacing={isMobile ? 1 : 7}
                      key={el.id}
                      css={css`
                        margin-bottom: 20px;
                      `}
                    >
                      <Grid item md={6} css={PortfolioCss().videoImg}>
                        {content}
                      </Grid>
                      <Grid item md={6} css={PortfolioCss().article}>
                        <h1>{el.title}</h1>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              el.description.length > 500
                                ? `${el.description.substring(0, 500)}...`
                                : el.description?.replaceAll(
                                    "<p></p>",
                                    "<br/>"
                                  ),
                          }}
                        />
                        <Link to={`${baseLink}${el.id}`}>
                          <Button
                            variant="outlined"
                            color="inherit"
                            disableElevation
                          >
                            View More
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  );
                })}

            {data?.meta?.pagination?.total > 6 ? (
              <p className="load-more" onClick={() => loadHideFunc()}>
                {loadHideVar === "hide" ? (
                  <span>
                    Hide
                    <ExpandLessIcon
                      css={css`
                        position: absolute;
                        top: -3px;
                      `}
                    />
                  </span>
                ) : (
                  <span>
                    Load More
                    <ExpandMoreOutlinedIcon
                      css={css`
                        position: absolute;
                        top: -3px;
                      `}
                    />
                  </span>
                )}
              </p>
            ) : (
              ""
            )}
          </Container>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
