/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import Contact from "../../components/landing/Contact";
import ArticleTopSection from "../../components/landing/ArticleTopSection";
import Sponsors from "../../components/landing/Sponsors";
import CarouselStory from "../../components/landing/CarouselStory";
import Portfolio from "../../components/landing/Portfolio";
import { useSelector, useDispatch } from "react-redux";
import {
  getBannerPromotion,
  getPromotionDetail,
  getBrandLogo,
  getDesignStory,
  getPortfolio,
} from "../../redux/features/landing/promotion";

export default function PromotionProduction() {
  const banner_data = useSelector(
    (state) => state.promotionLandingReducer.banner_data
  );
  const loading_banner = useSelector(
    (state) => state.promotionLandingReducer.loading_banner
  );

  const promotion_detail_data = useSelector(
    (state) => state.promotionLandingReducer.promotion_detail_data
  );
  const loading_promotion_detail = useSelector(
    (state) => state.promotionLandingReducer.loading_promotion_detail
  );

  const brand_logo_data = useSelector(
    (state) => state.promotionLandingReducer.brand_logo_data
  );
  const loading_brand_logo = useSelector(
    (state) => state.promotionLandingReducer.loading_brand_logo
  );

  const design_story_data = useSelector(
    (state) => state.promotionLandingReducer.design_story_data
  );
  const loading_design_story = useSelector(
    (state) => state.promotionLandingReducer.loading_design_story
  );

  const portfolio_data = useSelector(
    (state) => state.promotionLandingReducer.portfolio_data
  );
  const loading_portfolio = useSelector(
    (state) => state.promotionLandingReducer.loading_portfolio
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBannerPromotion());
    dispatch(getPromotionDetail());
    dispatch(getBrandLogo());
    dispatch(getDesignStory());
    dispatch(getPortfolio(6));
  }, [dispatch]);

  const getInTouchRef = React.useRef();
  const executeScroll = () =>
    getInTouchRef?.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  return (
    <div
      css={css`
        margin-top: 60px;
        @media only screen and (max-width: 720px) {
          margin-top: 15px;
        }
      `}
    >
      <ArticleTopSection
        image={banner_data ? banner_data[0]?.image : ""}
        video=""
        title={promotion_detail_data?.title}
        desc={promotion_detail_data?.description}
        loadingBanner={loading_banner}
        loadingDetail={loading_promotion_detail}
        linkButton={"/getintouch"}
        reverseGrid={false}
        breadcrumbData={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: "Promotion & Production",
            link: "",
          },
        ]}
        getInTouchTo={() => executeScroll()}
      />
      <Sponsors data={brand_logo_data} loading={loading_brand_logo} />
      <CarouselStory data={design_story_data} loading={loading_design_story} />
      <Portfolio
        data={portfolio_data}
        loading={loading_portfolio}
        colorTheme="#4285F4"
        loadHide={(e) => dispatch(getPortfolio(e === "load" ? 999 : 6))}
        baseLink="/promotion-production/portfolio/"
      />

      <div ref={getInTouchRef}>
        <Contact />
      </div>
    </div>
  );
}
