import { css } from "@emotion/react";

export const TeamsCss = {
  self: css`
    border: 1px solid #dadada;
    padding: 30px 0px;
    margin: 60px 0;
    .owl-carousel .owl-stage-outer {
      padding-left: 70px;
      @media only screen and (max-width: 720px) {
        padding-left: 20px;
      }
    }
    .box-team {
      text-align: center;
      width: fit-content;
      padding: 20px 20px;
      border-radius: 23px;
      margin-top: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0px 5px 25px 0px rgb(66 133 244 / 12%);
      }
      h1 {
        text-transform: uppercase;
        @media only screen and (max-width: 720px) {
          font-size: 15px;
        }
      }
      .position-team {
        @media only screen and (max-width: 720px) {
          font-size: 10px;
        }
      }
      .img-team {
        background-size: cover;
        background-position: center;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        display: block;
        margin: 30px auto;
        @media only screen and (max-width: 720px) {
          width: 80px;
          height: 80px;
          margin: 20px auto;
        }
      }
      .detail-team {
        width: 350px;
        opacity: 0.5;
        margin-bottom: 20px;
        min-height: 85px;
        @media only screen and (max-width: 720px) {
          min-height: 80px;
          width: 150px;
          font-size: 10px;
        }
      }
      .MuiButton-root {
        width: 100%;
        background-color: #000000;
        color: white;
        border-radius: 10px !important;
        padding: 11px !important;
        font-size: 16px;
        @media only screen and (max-width: 720px) {
          padding: 5px !important;
          font-size: 12px;
        }
        &:hover {
          background-color: #4285f4;
        }
      }
    }
  `,
};
