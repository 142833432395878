/** @jsxImportSource @emotion/react */
import React from "react";
import { Container } from "@mui/system";
import { CarouselCss } from "../../../styles/landing/home_css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";

Carousel.propTypes = {
  data: PropTypes.array,
  story: PropTypes.string,
  linkStory: PropTypes.string,
  loading: PropTypes.bool,
};

export default function Carousel({
  data,
  story = "",
  linkStory = "",
  loading,
}) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    fade: true,
    arrows: false,
  };

  const checkDataImage = (data) => {
    return data.image;
  };

  return (
    <div css={CarouselCss.self}>
      {loading ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          className="skeleton-wave-carousel"
        />
      ) : (
        <Slider {...settings}>
          {data?.filter(checkDataImage).map((el) => {
            return (
              <div
                key={el.id}
                css={css`
                  background-image: url("${el.image}");
                  color: ${el.text_color};
                `}
                className="bg-carousel"
              >
                {/* <h1>{el.title}</h1>
              <p>{el.desc}</p> */}
              </div>
            );
          })}
        </Slider>
      )}

      {story ? (
        <Container maxWidth="md">
          <div css={CarouselCss.readMore}>
            <p
              dangerouslySetInnerHTML={{
                __html: story?.replaceAll("<p></p>", "<br/>"),
              }}
            />
            <Link to={linkStory}>
              <span>Read More</span>
              <img src="icons/arrow-long-right.svg" alt="" />
            </Link>
          </div>
        </Container>
      ) : (
        ""
      )}
    </div>
  );
}
