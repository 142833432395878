import { css } from "@emotion/react";

export const NavbarCss = (onHome = false) => ({
  self: css`
    background-color: white;
    border-bottom: 1px solid #949494;
    position: sticky;
    top: 0;
    z-index: 99;
  `,

  container: css`
    display: flex;
    align-items: center;
    position: relative;
    img {
      width: ${onHome ? "9%" : "5.75%"};
      position: absolute;
      top: 10px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 10;
      transition: width 0.2s;
      &.scrolled {
        width: 5.75%;
      }
    }
  `,

  boxListMenu: css`
    width: 38%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 0px;
    a {
      text-align: center;
      font-weight: 600;
      font-size: 15px;
    }
    &.right {
      margin-left: auto;
    }
  `,
});

export const NavbarMobileCss = (close) => ({
  self: css`
    position: fixed;
    width: 100%;
    left: ${close ? "-100%" : "0"};
    transition: all 0.5s;
    bottom: 0;
    height: 100%;
    z-index: 999;
    background-color: white;
    .close-icon {
      border-bottom: 1px solid #f2f2f2;
      svg {
        font-size: 35px;
        display: block;
        margin: 15px 10px 15px auto;
      }
    }
    .menu-list {
      border-bottom: 1px solid #f2f2f2;
      padding: 17px;
    }
  `,
});

export const ContactCss = {
  self: css`
    background-color: #f6f8ff;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (max-width: 720px) {
      flex-direction: column-reverse;
    }
    .MuiFormControl-root {
      margin-bottom: 5px;
      p {
        color: #075494;
        margin-block-start: 0.3em;
        margin-block-end: 0.3em;
        font-weight: 600;
      }
      .MuiInputBase-formControl {
        font-family: "Source Sans Pro", sans-serif;
      }
      input {
        border: 1px solid #075494;
        color: #075494;
        background-color: #f6f8ff;
        &::placeholder {
          font-weight: 600;
        }
      }
      fieldset {
        visibility: hidden;
      }
      textarea {
        border: 1px solid #075494;
        font-family: "Source Sans Pro", sans-serif;
        padding: 16.5px 14px;
        font-size: 16px;
        color: #075494;
        background-color: #f6f8ff;
        &::placeholder {
          color: #a4bbd3;
        }
        &:focus-visible {
          outline: none;
        }
      }
    }
    .MuiLoadingButton-loadingIndicator {
      color: #ffffff;
      top: 10px;
    }
  `,
};

export const FooterCss = {
  sitemap: css`
    background-color: #e35b29;
    padding: 80px 55px;
    color: white;
    display: grid;
    width: 100%;
    h2 {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 20px;
    }
    a {
      color: #eeeeee;
      margin-bottom: 10px;
      &:hover {
        color: white;
      }
      @media only screen and (max-width: 720px) {
        font-size: 13px;
      }
    }
  `,

  aboutUs: css`
    background-color: #b0c438;
    padding: 80px 55px;
    color: white;
    display: grid;
    h2 {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 20px;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 0;
      color: #eeeeee;
      @media only screen and (max-width: 720px) {
        font-size: 13px;
      }
    }
    .socmed {
      display: flex;
      align-items: center;
      margin-top: 20px;
      img {
        margin-right: 15px;
      }
    }
  `,

  contact: css`
    background-color: #ffc701;
    padding: 80px 55px;
    color: #000000;
    display: grid;
    .text-contact {
      display: flex;
      align-items: top;
    }
    h2 {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 20px;
    }
    p {
      margin-block-start: 0;
      margin-block-end: 20px;
      font-weight: bold;
      @media only screen and (max-width: 720px) {
        font-size: 13px;
      }
    }
  `,
};

export const SponsorsCss = {
  self: css`
    .owl-theme {
      padding: 50px 0;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      @media only screen and (max-width: 720px) {
        padding: 30px 0;
      }
    }
    img {
      width: auto !important;
      height: 75px;
      padding: 0 15px;
      display: block;
      margin: 0 auto;
      @media only screen and (max-width: 720px) {
        height: 35px;
        width: auto !important;
        padding: 0 10px;
      }
    }
  `,
};

export const CarouselStoryCss = {
  self: css`
    margin-bottom: 30px;
    .img-story {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 650px;
      @media only screen and (max-width: 720px) {
        height: 200px;
      }
    }
    .desc-box {
      position: relative;
      text-align: center;
      background-color: #eff3ff;
      padding: 45px 0 55px 0;
      min-height: 275px;
      @media only screen and (max-width: 720px) {
        padding: 15px 0 30px 0;
      }
      h1 {
        font-weight: 400;
        color: #0073ff;
        margin-block-start: 0;
        margin-bottom: 20px;
        @media only screen and (max-width: 720px) {
          font-size: 23px;
          margin-bottom: 5px;
        }
      }
      p {
        width: 65%;
        display: block;
        margin: 0 auto;
        @media only screen and (max-width: 720px) {
          width: 70%;
          font-size: 12px;
        }
      }
    }

    .owl-carousel .owl-stage-outer {
      background-color: #eff3ff;
    }

    .owl-theme .owl-nav {
      position: absolute;
      top: calc(11% + 650px);
      width: 100%;
      @media only screen and (max-width: 720px) {
        top: 65%;
        img {
          width: 30px;
        }
      }
      .owl-prev {
        position: absolute;
        left: 2%;
      }
      .owl-next {
        position: absolute;
        right: 2%;
      }
      [class*="owl-"]:hover {
        background: unset;
      }
    }

    .owl-dots {
      position: absolute;
      bottom: 2px;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
      background: #0073ff;
    }
    .owl-theme .owl-dots .owl-dot span {
      background: #cfe1ff;
    }
  `,
};

export const PortfolioCss = (themeColor, bgColor) => ({
  self: css`
    background-color: ${bgColor};
    padding: 40px 0;
    h1 {
      color: ${themeColor};
      font-weight: 400;
      @media only screen and (max-width: 720px) {
        font-size: 23px;
      }
    }
    .MuiButton-root {
      color: ${themeColor};
      border-color: ${themeColor};
    }
    .load-more {
      text-align: center;
      margin: 45px 0;
      color: ${themeColor};
      position: relative;
      cursor: pointer;
    }
    p {
      @media only screen and (max-width: 720px) {
        font-size: 13px;
      }
    }
    svg {
      position: absolute;
      top: -1px;
      color: ${themeColor};
      @media only screen and (max-width: 720px) {
        top: -3px;
      }
    }
  `,

  titleSection: css`
    text-align: center;
    margin-bottom: 60px;
    @media only screen and (max-width: 720px) {
      margin-bottom: 20px;
    }
    h1 {
      margin-bottom: 10px;
    }
    p {
      width: 60%;
      margin: 0 auto;
      @media only screen and (max-width: 720px) {
        font-size: 14px !important;
        width: 90%;
      }
    }
  `,

  videoImg: css`
    width: 100%;
    iframe,
    img {
      width: 100%;
      height: 353px;
      box-shadow: 0px 7px 34px -12px rgba(0, 0, 0, 0.12);
      @media only screen and (max-width: 720px) {
        height: 250px;
      }
    }
  `,

  article: css`
    .MuiGrid-item {
      @media only screen and (max-width: 720px) {
        padding-top: 20px !important;
      }
    }
    h1 {
      font-weight: 600;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
    }
  `,
});

export const ArticleTopSectionCss = {
  self: css`
    margin-bottom: 40px;
    .img-three {
      &__banner {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 283px;
        margin-bottom: 16px;
        @media only screen and (max-width: 720px) {
          height: 230px;
        }
      }
      &__child {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 200px;
        @media only screen and (max-width: 720px) {
          height: 150px;
        }
      }
    }
  `,
};
