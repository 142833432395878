import { css } from "@emotion/react";

export const MessagesCss = {
  self: css`
    p,
    h3,
    h4 {
      margin-block-start: 0em;
      margin-block-end: 0em;
    }
    .header {
      display: flex;
      align-items: center;
      h3 {
        color: #005486;
      }
      p {
        color: #afafaf;
        margin-left: 15px;
      }
      .MuiButton-btn-admin-red {
        margin-left: auto;
        border-radius: 5px !important;
        padding: 5px 20px !important;
        font-weight: bold;
      }
    }

    .list-messages-table {
      margin-top: 20px;
      &__message {
        display: flex;
        align-items: center;
        background: #ffffff;
        border-width: 0px 2px 2px 2px;
        border-style: solid;
        border-color: #efefef;
        padding: 10px;
        cursor: pointer;
        .MuiCheckbox-root {
          .MuiSvgIcon-root {
            color: #0a7aff;
          }
        }
        &.unread {
          background-color: #78ccff;
        }
        &--name {
          margin-left: 15px;
          min-width: 10%;
        }
        p {
          margin-left: 10%;
        }
        img {
          margin-left: auto;
        }
        &--date {
          margin-left: 10px;
        }
      }
    }

    .MuiSkeleton-root {
      margin-bottom: 5px;
    }

    .MuiPagination-root {
      margin-top: 15px;
      margin-left: auto;
      width: fit-content;
    }
  `,
};
