import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const deleteDataById = createAsyncThunk(
  "delete/dataByIdGlobalAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const globalAdminSlice = createSlice({
  name: "globalAdminSlice",
  initialState: {
    loading: false,
  },
  reducers: {
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [deleteDataById.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteDataById.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
      } else {
      }
      state.loading = false;
    },
    [deleteDataById.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { deleteItem } = globalAdminSlice.actions;

export default globalAdminSlice.reducer;
