/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { PropTypes } from "prop-types";

BannerTitleBGg.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  loadingImage: PropTypes.bool,
  loadingTitle: PropTypes.bool,
};

export default function BannerTitleBGg({
  title,
  image,
  loadingImage,
  loadingTitle,
}) {
  return (
    <div>
      <div
        css={css`
          background-image: url("${image}");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          padding: 106px 0;
          text-align: center;
          color: white;
          @media only screen and (max-width: 720px) {
            padding: 25px 0;
          }
        `}
      >
        <h1
          css={css`
            @media only screen and (max-width: 720px) {
              font-size: 20px;
            }
          `}
        >
          {title}
        </h1>
      </div>
    </div>
  );
}
