import React from "react";
import Carousel from "../components/landing/home/Carousel";
import Products from "../components/landing/home/Products";
import Videos from "../components/landing/home/Videos";
import News from "../components/landing/home/News";
import Contact from "../components/landing/Contact";
import { useSelector, useDispatch } from "react-redux";
import {
  getBanner,
  getYoutubeList,
  getNews,
  getBannerSpice,
} from "../redux/features/landing/home";
import { getDetail } from "../redux/features/landing/about-us";
import { getDetailSpiceItUp } from "../redux/features/landing/spice-it-up";

export default function Home() {
  const banner_data = useSelector(
    (state) => state.homeLandingReducer.banner_data
  );
  const loading_banner = useSelector(
    (state) => state.homeLandingReducer.loading_banner
  );

  const youtube_data = useSelector(
    (state) => state.homeLandingReducer.youtube_data
  );
  const loading_youtube = useSelector(
    (state) => state.homeLandingReducer.loading_youtube
  );

  const news_data = useSelector((state) => state.homeLandingReducer.news_data);
  const loading_news = useSelector(
    (state) => state.homeLandingReducer.loading_news
  );

  const banner_spice_data = useSelector(
    (state) => state.homeLandingReducer.banner_spice_data
  );
  const loading_banner_spice = useSelector(
    (state) => state.homeLandingReducer.loading_banner_spice
  );
  const detail_spice_data = useSelector(
    (state) => state.spiceItUpLandingReducer.detail_spice_data
  );

  const detail_data = useSelector(
    (state) => state.aboutUsLandingReducer.detail_data
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBanner());
    dispatch(getYoutubeList());
    dispatch(getNews(4));
    dispatch(getBannerSpice());
    dispatch(getDetail());
    dispatch(getDetailSpiceItUp());
  }, [dispatch]);

  const loadHideNews = (type) => {
    dispatch(getNews(type === "more" ? 999 : 4));
  };

  return (
    <div>
      <Carousel
        data={banner_data}
        loading={loading_banner}
        story={detail_data?.description}
        linkStory="/about-us"
      />
      <Products />
      <Videos data={youtube_data} loading={loading_youtube} />

      <News
        data={news_data}
        loading={loading_news}
        data_spiceitup={banner_spice_data}
        loading_spiceitup={loading_banner_spice}
        detailTextSpiceItUp={detail_spice_data?.description}
        loadHide={(e) => loadHideNews(e)}
      />
      <Contact />
    </div>
  );
}
