import { css } from "@emotion/react";

export const ContentNoDialogCss = {
  self: css`
    margin-top: 20px;
    .Mui-focused {
      border: unset;
    }
    .form-group-control {
      width: 100%;
      margin-bottom: 20px;
      .form-group {
        margin-bottom: 25px !important;
        h3 {
          margin-bottom: 10px;
          color: #005486;
        }
        margin-bottom: 15px;
      }
      .MuiOutlinedInput-root {
        width: 100%;
        background-color: white;
        padding: 1px;
        .MuiInputBase-input {
          padding: 10px 14px;
        }
        .MuiOutlinedInput-input {
          &:focus-visible {
            outline: none;
          }
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: rgb(239 238 238);
        }
      }
    }
    .loading-box {
      position: relative;
      .skeleton-wave-form {
        position: absolute;
        z-index: 99;
        width: 250px;
        top: 10.5px;
        left: 17px;
      }
    }
    .action-content {
      display: flex;
      justify-content: flex-start;
      .MuiButton-btn-admin {
        border-radius: 5px !important;
        padding: 8px 35px !important;
        font-weight: bold;
        margin-right: 10px;
      }
      .MuiButton-btn-admin-outline-red {
        border-radius: 5px !important;
        padding: 8px 35px !important;
        font-weight: bold;
      }
    }
  `,
};
