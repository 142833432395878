/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";

export default function Separator() {
  return (
    <div
      css={css`
        width: 100%;
        height: 50px;
        margin-top: 35px;
      `}
    >
      <div
        css={css`
          position: absolute;
          background-color: #eeeeee;
          width: 100%;
          height: 25px;
          left: 0;
        `}
      ></div>
    </div>
  );
}
