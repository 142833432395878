/** @jsxImportSource @emotion/react */
import React from "react";
import ArticleTopSection from "../components/landing/ArticleTopSection";
import Teams from "../components/landing/about-us/Teams";
import { css } from "@emotion/react";
import Contact from "../components/landing/Contact";
import { useSelector, useDispatch } from "react-redux";
import {
  getMember,
  getBanner,
  getDetail,
} from "../redux/features/landing/about-us";

export default function AboutUs() {
  const member_data = useSelector(
    (state) => state.aboutUsLandingReducer.member_data
  );
  const loading_member = useSelector(
    (state) => state.aboutUsLandingReducer.loading_member
  );
  // Banner
  const banner_data = useSelector(
    (state) => state.aboutUsLandingReducer.banner_data
  );
  const loading_banner = useSelector(
    (state) => state.aboutUsLandingReducer.loading_banner
  );
  // Detail
  const detail_data = useSelector(
    (state) => state.aboutUsLandingReducer.detail_data
  );
  const loading_detail = useSelector(
    (state) => state.aboutUsLandingReducer.loading_detail
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMember());
    dispatch(getBanner());
    dispatch(getDetail());
  }, [dispatch]);

  return (
    <div
      css={css`
        margin-top: 60px;
        @media only screen and (max-width: 720px) {
          margin-top: 15px;
        }
      `}
    >
      <ArticleTopSection
        imgThree={banner_data}
        image={""}
        video=""
        title={"About Us"}
        desc={detail_data?.description}
        linkButton={""}
        reverseGrid={false}
        loadingBanner={loading_banner}
        loadingDetail={loading_detail}
        breadcrumbData={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: "About Us",
            link: "",
          },
        ]}
      />

      <Teams data={member_data} loading={loading_member} />

      <Contact />
    </div>
  );
}
