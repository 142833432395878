/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { css } from "@emotion/react";
import { Container } from "@mui/system";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { NavbarMobileCss } from "../../styles/landing/globals_css";
import { Link, useLocation } from "react-router-dom";

export default function NavbarMobile() {
  const [toggle, setToggle] = useState(true);
  return (
    <React.Fragment>
      <div css={NavbarMobileCss(toggle).self}>
        <div className="close-icon">
          <CloseRoundedIcon onClick={() => setToggle(true)} />
        </div>

        <div className="menu-list">
          <Link
            to="/"
            css={css`
              color: ${useLocation().pathname === "/" ? "#de5b28" : "inherit"};
            `}
            onClick={() => setToggle(true)}
          >
            Home
          </Link>
        </div>
        <div className="menu-list">
          <Link
            to="/promotion-production"
            css={css`
              color: ${useLocation().pathname === "/promotion-production"
                ? "#de5b28"
                : "inherit"};
            `}
            onClick={() => setToggle(true)}
          >
            Promotion & Production
          </Link>
        </div>
        <div className="menu-list">
          <Link
            to="/marketing-communications"
            css={css`
              color: ${useLocation().pathname === "/marketing-communications"
                ? "#de5b28"
                : "inherit"};
            `}
            onClick={() => setToggle(true)}
          >
            Marketing Communications
          </Link>
        </div>
        <div className="menu-list">
          <Link
            to="/public-relation"
            css={css`
              color: ${useLocation().pathname === "/public-relation"
                ? "#de5b28"
                : "inherit"};
            `}
            onClick={() => setToggle(true)}
          >
            Public Relations as Reputation Management
          </Link>
        </div>
        <div className="menu-list">
          <Link
            to="/portfolio"
            css={css`
              color: ${useLocation().pathname === "/portfolio"
                ? "#de5b28"
                : "inherit"};
            `}
            onClick={() => setToggle(true)}
          >
            Portfolio
          </Link>
        </div>
        <div className="menu-list">
          <Link
            to="/about-us"
            css={css`
              color: ${useLocation().pathname === "/about-us"
                ? "#de5b28"
                : "inherit"};
            `}
            onClick={() => setToggle(true)}
          >
            About Us
          </Link>
        </div>
      </div>
      <div
        css={css`
          position: sticky;
          z-index: 99;
          top: 0;
          background-color: white;
          border-bottom: 1px solid #f4f4f4;
        `}
      >
        <Container
          css={css`
            display: flex;
            align-items: center;
            padding: 15px 16px;
          `}
        >
          <MenuIcon onClick={() => setToggle(false)} />
          <Link
            to="/"
            css={css`
              margin-left: auto;
            `}
          >
            <img
              src="/icons/molecula-icon.svg"
              alt="molecula"
              css={css`
                width: 50px;
              `}
            />
          </Link>
        </Container>
      </div>
    </React.Fragment>
  );
}
