/** @jsxImportSource @emotion/react */
import React from "react";
import { Grid } from "@mui/material";
import ImagesUpload from "../../components/admin/ImagesUpload";
import ContentNoDialog from "../../components/admin/ContentNoDialog";
import MediaUploader from "../../components/admin/MediaUploader";
import Separator from "../../components/admin/Separator";
import { css } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import {
  getTitleDesc,
  updateTitleDesc,
  getTitleDescCSM,
  updateTitleDescCSM,
  getBanner,
  putImageBanner,
  getPortfolio,
  createPortfolio,
  updatePortfolio,
  deletePortfolio,
} from "../../redux/features/admin/spice-it-up";

export default function SpiceItUpAdmin() {
  const banner_data_spice = useSelector(
    (state) => state.spiceItUpReducer.banner_data
  );
  const loading_banner = useSelector(
    (state) => state.spiceItUpReducer.loading_banner
  );
  const loading_upload = useSelector(
    (state) => state.spiceItUpReducer.loading_upload_banner
  );
  const title_text = useSelector((state) => state.spiceItUpReducer.title);
  const desc_text = useSelector((state) => state.spiceItUpReducer.description);
  const instagram_text = useSelector(
    (state) => state.spiceItUpReducer.instagram
  );
  const youtube_text = useSelector((state) => state.spiceItUpReducer.youtube);
  const image_spice = useSelector(
    (state) => state.spiceItUpReducer.image_spice
  );
  const loading = useSelector((state) => state.spiceItUpReducer.loading);
  const loading_upload_spice = useSelector(
    (state) => state.spiceItUpReducer.loading_upload
  );

  const title_text_csm = useSelector(
    (state) => state.spiceItUpReducer.title_csm
  );
  const desc_text_csm = useSelector(
    (state) => state.spiceItUpReducer.description_csm
  );
  const image_csm = useSelector((state) => state.spiceItUpReducer.image_csm);
  const loading_csm = useSelector(
    (state) => state.spiceItUpReducer.loading_csm
  );
  const loading_upload_csm = useSelector(
    (state) => state.spiceItUpReducer.loading_upload_csm
  );

  //   Portfolio
  const loading_portfolio = useSelector(
    (state) => state.spiceItUpReducer.loading_portfolio
  );
  const loading_update_portfolio = useSelector(
    (state) => state.spiceItUpReducer.loading_update_portfolio
  );
  const portfolio_datas = useSelector(
    (state) => state.spiceItUpReducer.portfolio_datas
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTitleDesc());
    dispatch(getTitleDescCSM());
    dispatch(getBanner());
    dispatch(getPortfolio());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadImage = async (el) => {
    await dispatch(putImageBanner(el));
    dispatch(getBanner());
  };

  const updateTitleDescFunc = async (values) => {
    await dispatch(updateTitleDesc(values));
    dispatch(getTitleDesc());
  };

  const updateTitleDescCSMFunc = async (values) => {
    await dispatch(updateTitleDescCSM(values));
    dispatch(getTitleDescCSM());
  };

  //   Portfolio
  const addItemPortfolio = async (event) => {
    await dispatch(createPortfolio(event));
    dispatch(getPortfolio());
  };

  const updateItemPortfolio = async (event) => {
    await dispatch(updatePortfolio(event));
    dispatch(getPortfolio());
  };

  const deleteItemPortfolio = async (e) => {
    await dispatch(deletePortfolio(e));
    dispatch(getPortfolio());
  };
  const data_details_banner = [
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
  ];

  const data_img_spice = [
    {
      img_dimension: "1349 x 999",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
  ];

  const data_img_content_management = [
    {
      img_dimension: "1349 x 999",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
  ];

  return (
    <div>
      <ImagesUpload
        title="Upload Banner"
        subtitle="*Maximum upload up to 5 banners"
        data={banner_data_spice?.map((el, i) => {
          return {
            id: el.id,
            image: el.image,
            img_dimension: data_details_banner[i].img_dimension,
            max_size: data_details_banner[i].max_size,
            supported_file: data_details_banner[i].supported_file,
          };
        })}
        loadingData={loading_banner}
        loading={loading_upload}
        uploadFunc={(e) => uploadImage(e)}
      />
      <Separator />
      <h3
        css={css`
          margin-bottom: 13px;
          color: #005486;
        `}
      >
        Spice it up
      </h3>
      <div
        css={css`
          background-color: #ffffff;
          width: 100%;
          padding: 10px 50px 30px 20px;
          border: 3px solid #efefef;
          border-radius: 16px;
        `}
      >
        <Grid container>
          <Grid item md={3} sm={3} xs={3}>
            <ImagesUpload
              data={image_spice?.map((el, i) => {
                return {
                  id: el.id,
                  image: el.image,
                  img_dimension: data_img_spice[i].img_dimension,
                  max_size: data_img_spice[i].max_size,
                  supported_file: data_img_spice[i].supported_file,
                };
              })}
              box={false}
              loadingData={loading}
              loading={loading_upload_spice}
              uploadFunc={(e) =>
                updateTitleDescFunc({
                  title: title_text,
                  desc: desc_text,
                  instagram: instagram_text,
                  youtube: youtube_text,
                  image: e.image,
                })
              }
            />
          </Grid>
          <Grid item md={9} sm={9} xs={9}>
            <ContentNoDialog
              title={{ show: true, value: title_text }}
              description={{ show: true, value: desc_text }}
              instagram={{ show: true, value: instagram_text }}
              youtube={{ show: true, value: youtube_text }}
              loading={loading}
              loadingSave={loading_upload_spice}
              saveFunc={(e) => updateTitleDescFunc(e, { image: null })}
              resetValue={() => dispatch(getTitleDesc())}
            />
          </Grid>
        </Grid>
      </div>
      <Separator />
      <h3
        css={css`
          margin-bottom: 13px;
          color: #005486;
        `}
      >
        Content Strategy and Management
      </h3>
      <div
        css={css`
          background-color: #ffffff;
          width: 100%;
          padding: 10px 50px 30px 20px;
          border: 3px solid #efefef;
          border-radius: 16px;
        `}
      >
        <Grid container>
          <Grid item md={3} sm={3} xs={3}>
            <ImagesUpload
              data={image_csm?.map((el, i) => {
                return {
                  id: el.id,
                  image: el.image,
                  img_dimension: data_img_content_management[i].img_dimension,
                  max_size: data_img_content_management[i].max_size,
                  supported_file: data_img_content_management[i].supported_file,
                };
              })}
              box={false}
              loadingData={loading_csm}
              loading={loading_upload_csm}
              uploadFunc={(e) =>
                updateTitleDescCSMFunc({
                  title: title_text_csm,
                  desc: desc_text_csm,
                  image: e.image,
                })
              }
            />
          </Grid>
          <Grid item md={9} sm={9} xs={9}>
            <ContentNoDialog
              title={{ show: true, value: title_text_csm }}
              description={{ show: true, value: desc_text_csm }}
              instagram={{ show: false, value: "" }}
              youtube={{ show: false, value: "" }}
              loading={loading_csm}
              loadingSave={loading_upload_csm}
              saveFunc={(e) => updateTitleDescCSMFunc(e, { image: null })}
              resetValue={() => dispatch(getTitleDescCSM())}
            />
          </Grid>
        </Grid>
      </div>
      <Separator />
      <MediaUploader
        title="Portfolio"
        data={portfolio_datas}
        loading={loading_portfolio}
        saveDataLoading={loading_update_portfolio}
        formOnDialog={{
          singleImageUpload: {
            show: true,
            title: "Upload Image (Brand Logo)",
            imageDimension: "1029 x 576",
            maxFile: "2.0 MB",
            supportedFile: "PNG,JPG",
          },
          youtubeLink: true,
          brandName: true,
          portfolioDetails: true,
          brandDescription: true,
          masonryImages: true,
        }}
        addItem={(e) => addItemPortfolio(e)}
        deleteItem={(e) => deleteItemPortfolio(e)}
        saveData={(e) => updateItemPortfolio(e)}
        type="image/video"
        imageDimension="1029 x 576"
        maxFile="2.0 MB"
        supportedFile="PNG,JPG"
      />
    </div>
  );
}
