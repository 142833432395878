import { css } from "@emotion/react";

export const MediaUploaderCss = {
  self: css`
    .title-subtitle {
      display: flex;
      color: #005486;
      align-items: center;
      margin-bottom: 10px;
      p {
        margin-left: 15px;
        margin-block-end: 0;
      }
      .trunk-btn {
        margin-left: auto;
        display: flex;
      }
      .MuiButton-root {
        font-weight: bold;
        padding: 7px 20px !important;
      }
      .MuiDivider-root {
        margin: 10px;
      }
    }

    .table-datas {
      &__item {
        background-color: #ffffff;
        border-bottom: 2px solid #efefef;
        display: flex;
        align-items: center;
        padding: 15px;
        &:first-of-type {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:last-of-type {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        &--dum-thumbnail {
          height: 80px;
          width: 100px;
          padding: 10px;
          border: 2px dashed #a6a6a6;
          border-radius: 8px;
          margin-right: 15px;
          cursor: pointer;
        }
        &--thumbnail {
          cursor: pointer;
          background-position: center;
          //   background-size: cover;
          background-size: 100%;
          background-repeat: no-repeat;
          height: 80px;
          width: 100px;
          border-radius: 8px;
          margin-right: 15px;
          position: relative;
          &__icon-status {
            position: absolute;
            right: -5px;
            bottom: -5px;
            width: 15px;
            height: 15px;
            background-color: #b8b8b8;
            border: 2px solid #ffffff;
            border-radius: 50%;
          }
        }
        &--detail {
          font-size: 15px;
          line-height: 1.4;
          color: #616161;
        }
        &--title {
        }
        .MuiButton-root {
          margin-left: auto;
          padding: 5px 30px !important;
        }
        &--delete-btn {
          margin: 0px 35px;
          color: #ff0000;
          cursor: pointer;
        }
      }
    }
  `,
};
