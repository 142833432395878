/** @jsxImportSource @emotion/react */
import React from "react";
import { OutlinedInput, FormHelperText, Alert } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { AuthCss } from "../../styles/admin/auth_css";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchLogin } from "../../redux/features/authentication";
import { useFormik } from "formik";
import * as yup from "yup";

export default function LoginAuth() {
  let loadingLogin = useSelector((state) => state.login.loadingLogin);
  let errorLogin = useSelector((state) => state.login.error);
  let successLogin = useSelector((state) => state.login.success);
  //   let dataRes = useSelector((state) => state.login.dataRes);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (successLogin) {
      window.location.href = "/admin";
    }
  }, [loadingLogin, successLogin]);

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(fetchLogin(values));
    },
  });

  const [eyeOpen, setEyeOpen] = React.useState(false);
  return (
    <div css={AuthCss.formBoard}>
      <div className="title">
        <h2>Welcome</h2>
        <p>Please enter your account data</p>
      </div>

      {errorLogin ? (
        <Alert severity="error">Email atau Kata sandi salah!</Alert>
      ) : (
        ""
      )}

      <form onSubmit={formik.handleSubmit} className="form-group-control">
        <div className="form-group">
          <h5>
            <b> Email</b>
          </h5>
          <OutlinedInput
            id="email"
            name="email"
            label="Email"
            placeholder="Example@mail.com"
            type="email"
            variant="inputauth"
            error={formik.touched.email && Boolean(formik.errors.email)}
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          <FormHelperText id="email">
            <small>{formik.touched.email && formik.errors.email}</small>
          </FormHelperText>
        </div>

        <div className="form-group">
          <h5>
            <b> Kata Sandi</b>
          </h5>
          <OutlinedInput
            id="password"
            name="password"
            label="Password"
            placeholder="Enter your password here"
            type={eyeOpen ? "text" : "password"}
            variant="inputauth"
            error={formik.touched.password && Boolean(formik.errors.password)}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {eyeOpen ? (
            <VisibilityOffOutlinedIcon
              className="visibility-eye"
              onClick={() => setEyeOpen(false)}
            />
          ) : (
            <VisibilityOutlinedIcon
              className="visibility-eye"
              onClick={() => setEyeOpen(true)}
            />
          )}
          <FormHelperText id="password">
            <small>{formik.touched.password && formik.errors.password}</small>
          </FormHelperText>
        </div>

        <LoadingButton
          variant="btn-admin"
          disableElevation
          type="submit"
          loading={loadingLogin}
          disabled={loadingLogin}
        >
          Masuk
        </LoadingButton>
      </form>

      <Link to="/authentication/forgot-password">
        <p className="link-to">
          <small>Forget Your Password?</small>
        </p>
      </Link>
    </div>
  );
}
