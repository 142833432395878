/** @jsxImportSource @emotion/react */
import React from "react";
import {
  FormControl,
  OutlinedInput,
  TextareaAutosize,
  Container,
  FormHelperText,
} from "@mui/material";
import { css } from "@emotion/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { ContactCss } from "../../styles/landing/globals_css";
import { useSelector, useDispatch } from "react-redux";
import { sendMessage } from "../../redux/features/landing/messages";
import { useFormik } from "formik";
import * as yup from "yup";

export default function Contact() {
  let loading = useSelector((state) => state.messagesLandingReducer.loading);

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    name: yup.string("Enter your name").required("Name is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    phoneNumber: yup
      .string("Enter your phone number")
      .min(8, "Phone number should be of minimum 9 characters length")
      .required("Phone number is required"),
    message: yup.string("Enter your message").required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(sendMessage(values));
    },
  });

  return (
    <div css={ContactCss.self}>
      <div
        css={css`
          width: 50%;
          @media only screen and (max-width: 720px) {
            width: 100%;
          }
        `}
      >
        <Container maxWidth="sm">
          <form
            onSubmit={formik.handleSubmit}
            css={css`
              display: grid;
              padding: 30px;
            `}
          >
            <FormControl variant="outlined">
              <p>Name*</p>
              <OutlinedInput
                name="name"
                placeholder="Name"
                autoComplete="off"
                error={formik.touched.name && Boolean(formik.errors.name)}
                value={formik.values.name}
                onChange={formik.handleChange}
              ></OutlinedInput>
              <FormHelperText id="name">
                <small>{formik.touched.name && formik.errors.name}</small>
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined">
              <p>Email*</p>
              <OutlinedInput
                name="email"
                placeholder="Email"
                type="email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                value={formik.values.email}
                onChange={formik.handleChange}
              ></OutlinedInput>
              <FormHelperText id="name">
                <small>{formik.touched.email && formik.errors.email}</small>
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined">
              <p>Phone Number*</p>
              <OutlinedInput
                name="phoneNumber"
                placeholder="Phone Number"
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
              ></OutlinedInput>
              <FormHelperText id="phoneNumber">
                <small>
                  {formik.touched.phoneNumber && formik.errors.phoneNumber}
                </small>
              </FormHelperText>
            </FormControl>
            <FormControl variant="outlined">
              <p>Message*</p>
              <TextareaAutosize
                name="message"
                minRows={10}
                placeholder="Message"
                error={formik.touched.message && Boolean(formik.errors.message)}
                value={formik.values.message}
                onChange={formik.handleChange}
              />
              <FormHelperText id="message">
                <small>{formik.touched.message && formik.errors.message}</small>
              </FormHelperText>
            </FormControl>
            <LoadingButton
              css={css`
                border-radius: 0;
                width: fit-content;
                padding: 8px 45px !important;
                text-transform: uppercase !important;
              `}
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
              loading={loading}
              disabled={loading}
            >
              Send
            </LoadingButton>
          </form>
        </Container>
      </div>
      <div
        css={css`
          background-color: #005486;
          position: relative;
          width: 50%;
          @media only screen and (max-width: 720px) {
            width: 100%;
            min-height: 285px;
          }
        `}
      >
        <Container maxWidth="sm">
          <h1
            css={css`
              color: white;
              font-size: 90px;
              margin: 0;
              position: absolute;
              top: 35%;
              left: 35%;
              transform: translate(-35%, -35%);
              @media only screen and (max-width: 720px) {
                font-size: 40px;
                top: 40%;
                left: 20%;
              }
            `}
          >
            Get <br />
            in touch <br />
            with <br />
            us
          </h1>
        </Container>
      </div>
    </div>
  );
}
