/** @jsxImportSource @emotion/react */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { css } from "@emotion/react";
import { Link, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/authentication";
import { SidebarCss } from "../../styles/admin/sidebar_css";
import { ToastContainer } from "react-toastify";

const drawerWidth = 312;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }} css={SidebarCss.self}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        css={css`
          padding: 24.5px;
          box-shadow: unset;
          background-color: #f5f5f5;
          color: #005486;
          border-bottom: 2px solid #ededed;
          z-index: 10;
        `}
      >
        <Toolbar
          css={css`
            position: relative;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-left: auto;
              cursor: pointer;
            `}
            onClick={() => setAnchorEl(true)}
          >
            <Avatar
              css={css`
                margin-right: 10px;
                background-color: rgba(0, 84, 134, 0.2);
                color: #005486;
              `}
            >
              A
            </Avatar>
            <h4>Admin</h4>
            <img
              src="/icons/admin/icons-sidebar/chevron-down.svg"
              css={css`
                width: 15px;
                margin-left: 8px;
                margin-top: 3px;
              `}
              alt=""
            />
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        open={anchorEl}
        onClose={() => setAnchorEl(false)}
        onClick={() => setAnchorEl(false)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            top: "80px !important",
            left: "90% !important",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 48,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem
          css={css`
            background-color: white !important;
          `}
          onClick={() => dispatch(logout())}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Drawer variant="permanent" open={open} anchor="left">
        <DrawerHeader
          css={css`
            padding: 25px 13px;
            margin-bottom: 20px;
          `}
        >
          {open ? (
            <img
              src="/icons/molecula-icon.svg"
              css={css`
                width: 30%;
              `}
              alt=""
            />
          ) : (
            ""
          )}
          <IconButton
            css={css`
              margin-left: auto;
            `}
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          >
            <MenuRoundedIcon />
          </IconButton>
        </DrawerHeader>
        <List>
          {[
            { title: "Homepage", icon: "homepage", url: "/admin" },
            { title: "About us", icon: "about-us", url: "/admin/about-us" },
            {
              title: "Promotion",
              icon: "promotion",
              url: "/admin/promotion",
            },
            {
              title: "Marketing Communications",
              icon: "marketing-communication",
              url: "/admin/marketing-communication",
            },
            {
              title: "Public Relations",
              icon: "public-relation",
              url: "/admin/public-relation",
            },
            {
              title: "Spice it up",
              icon: "spice-it-up",
              url: "/admin/spice-it-up",
            },
            { title: "Company", icon: "company", url: "/admin/company" },
            { title: "Personal", icon: "personal", url: "/admin/personal" },
            { title: "News", icon: "news", url: "/admin/news" },
          ].map((el, index) => (
            <Link key={index} to={el.url}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={`/icons/admin/icons-sidebar/${el.icon}/${
                        location.pathname === el.url ? "active" : "inactive"
                      }.svg`}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={el.title}
                    sx={{ opacity: open ? 1 : 0 }}
                    css={css`
                      color: ${location.pathname === el.url
                        ? "#de5b28"
                        : "inherit"};
                    `}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <List
          css={css`
            margin-top: 50px;
            margin-bottom: 60px;
          `}
        >
          <ListItem>
            {" "}
            <h4>Other Settings</h4>
          </ListItem>
          {[
            { title: "Member", icon: "member", url: "/admin/member" },
            { title: "Contact", icon: "contact", url: "/admin/contact" },
            { title: "Messages", icon: "messages", url: "/admin/messages" },
          ].map((el, index) => (
            <Link key={index} to={el.url}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={`/icons/admin/icons-sidebar/${el.icon}/${
                        location.pathname === el.url ? "active" : "inactive"
                      }.svg`}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={el.title}
                    sx={{ opacity: open ? 1 : 0 }}
                    css={css`
                      color: ${location.pathname === el.url
                        ? "#de5b28"
                        : "inherit"};
                    `}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
        <div
          css={css`
            color: #5b3f33;
            position: fixed;
            bottom: 0px;
            left: 0;
            background: white;
            width: 311px;
            padding: 10px 25px;
          `}
        >
          <p>
            <small>Joja Creative - 2022</small>
          </p>
        </div>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        css={css`
          min-height: 100vh;
          background-color: #f5f5f5;
          padding: 25px 60px;
        `}
      >
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={1}
        />
        <DrawerHeader />
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
