import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getMessages = createAsyncThunk(
  "get/messagesAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/contact-us?page=${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getDetailMessage = createAsyncThunk(
  "get/detailMessageAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/contact-us/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deleteMessage = createAsyncThunk(
  "delete/messageAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/contact-us/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const messagesAdminSlice = createSlice({
  name: "messagesAdminSlice",
  initialState: {
    messages_data: [],
    meta_data: null,
    loading_message: false,
    loading_delete: false,
  },
  reducers: {
    setReadUnfetch: (state, { payload }) => {
      state.messages_data.filter((el) => el.id === payload)[0].open_count =
        state.messages_data.filter((el) => el.id === payload)[0].open_count + 1;
    },
  },
  extraReducers: {
    //   Message || ----------
    [getMessages.pending]: (state, { payload }) => {
      state.loading_message = true;
    },
    [getMessages.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.messages_data = payload.data;
        state.meta_data = payload.meta;
      } else {
        state.messages_data = [];
        state.meta_data = null;
      }
      state.loading_message = false;
    },
    [getMessages.rejected]: (state, action) => {
      state.loading_message = false;
    },
    //   ---------- || ----------
    [deleteMessage.pending]: (state, { payload }) => {
      state.loading_delete = true;
    },
    [deleteMessage.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_delete = false;
    },
    [deleteMessage.rejected]: (state, action) => {
      state.loading_delete = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setReadUnfetch } = messagesAdminSlice.actions;

export default messagesAdminSlice.reducer;
