import React from "react";
import ImagesUpload from "../../components/admin/ImagesUpload";
import MediaUploader from "../../components/admin/MediaUploader";
import Separator from "../../components/admin/Separator";
import { useSelector, useDispatch } from "react-redux";
import {
  getYoutubeVideos,
  getBanner,
  putImageBanner,
  updateYoutubeVideo,
  createYoutubeVideo,
} from "../../redux/features/admin/homepage";
import { deleteDataById } from "../../redux/features/admin/global";

export default function HomepageAdmin() {
  const youtube_videos = useSelector(
    (state) => state.homePageReducer.youtube_videos
  );
  const banner_data = useSelector((state) => state.homePageReducer.banner_data);
  const loading_banner = useSelector(
    (state) => state.homePageReducer.loading_banner
  );
  const loading_upload = useSelector(
    (state) => state.homePageReducer.loading_upload
  );
  const loading_youtube_video = useSelector(
    (state) => state.homePageReducer.loading_youtube_video
  );
  const loading_save_data = useSelector(
    (state) => state.homePageReducer.loading_save_data
  );
  const loading_delete = useSelector(
    (state) => state.globalAdminReducer.loading
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getYoutubeVideos());
    dispatch(getBanner());
  }, [dispatch, loading_save_data]);

  const uploadImage = async (el) => {
    await dispatch(putImageBanner(el));
    dispatch(getBanner());
  };

  const createDataYoutubeFunc = async (e) => {
    await dispatch(createYoutubeVideo(e));
    dispatch(getYoutubeVideos());
  };

  const saveDataYoutubeFunc = async (el) => {
    await dispatch(updateYoutubeVideo(el));
    dispatch(getYoutubeVideos());
  };

  const deleteItemById = async (el) => {
    await dispatch(deleteDataById(el));
    dispatch(getYoutubeVideos());
  };

  const data_details_banner = [
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "1440 x 677",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
  ];

  return (
    <div>
      <ImagesUpload
        title="Upload Banner"
        subtitle="*Maximum upload up to 5 banners"
        data={banner_data?.map((el, i) => {
          return {
            id: el.id,
            image: el.image,
            img_dimension: data_details_banner[i].img_dimension,
            max_size: data_details_banner[i].max_size,
            supported_file: data_details_banner[i].supported_file,
          };
        })}
        loading={loading_upload}
        loadingData={loading_banner}
        uploadFunc={(e) => uploadImage(e)}
      />

      <Separator />

      <MediaUploader
        title="Youtube Link Videos"
        subtitle="*Click content to choose the active main thumbnails"
        data={youtube_videos}
        type="video"
        formOnDialog={{
          youtubeLink: true,
          headingTitle: true,
          contentDesription: true,
        }}
        loading={loading_youtube_video}
        saveData={(e) => saveDataYoutubeFunc(e)}
        addItem={(e) => createDataYoutubeFunc(e)}
        deleteItem={(e) => deleteItemById(e)}
        mainPost={true}
        mainPostItem={(e) => saveDataYoutubeFunc(e)}
        saveDataLoading={loading_save_data || loading_delete}
      />
    </div>
  );
}
