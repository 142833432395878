/** @jsxImportSource @emotion/react */
import React from "react";
import { OutlinedInput, Button } from "@mui/material";
import PropTypes from "prop-types";
import { FormDataGroupCss } from "../../styles/admin/form-data-group_css";
import Skeleton from "@mui/material/Skeleton";
import BackdropLoading from "./BackdropLoading";

FormDataGroup.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  address: PropTypes.bool,
  telephone: PropTypes.bool,
  email: PropTypes.bool,
  instagram: PropTypes.bool,
  facebook: PropTypes.bool,
  twitter: PropTypes.bool,
  linkedIn: PropTypes.bool,
  youtube: PropTypes.bool,
  loadingData: false,
  loading: false,
  saveData: PropTypes.func,
  resetValue: PropTypes.func,
};

export default function FormDataGroup({
  title,
  data,
  address,
  telephone,
  email,
  instagram,
  facebook,
  twitter,
  linkedIn,
  youtube,
  loading,
  loadingData,
  saveData,
  resetValue,
}) {
  const [addressState, setAddressState] = React.useState("");
  const [telephoneState, setTelephoneState] = React.useState("");
  const [emailState, setEmailState] = React.useState("");
  const [instagramState, setInstagramState] = React.useState("");
  const [facebookState, setFacebookState] = React.useState("");
  const [twitterState, setTwitterState] = React.useState("");
  const [linkedInState, setlinkedInState] = React.useState("");
  const [youtubeState, setyoutubeState] = React.useState();

  React.useEffect(() => {
    setAddressState(data[0]?.value);
    setTelephoneState(data[1]?.value);
    setEmailState(data[2]?.value);
    setTwitterState(data[3]?.value);
    setInstagramState(data[4]?.value);
    setFacebookState(data[5]?.value);
    setlinkedInState(data[6]?.value);
    setyoutubeState(data[7]?.value);
  }, [data]);

  const loading_skeleton = (
    <div className="loading-box">
      <Skeleton animation="wave" className="skeleton-wave-form" />
      <OutlinedInput placeholder="" type="text" />
    </div>
  );

  return (
    <div css={FormDataGroupCss.self}>
      <BackdropLoading loading={loading} />
      <h3>{title}</h3>
      <form className="form-group-control">
        <div className="form-trunk">
          {address ? (
            <div className="form-group">
              <h3>
                <b>Address</b>
              </h3>
              {!loadingData ? (
                <OutlinedInput
                  id="address"
                  value={addressState}
                  onChange={(e) => setAddressState(e.target.value)}
                  placeholder=""
                  type="text"
                />
              ) : (
                loading_skeleton
              )}
            </div>
          ) : (
            ""
          )}

          {telephone ? (
            <div className="form-group">
              <h3>
                <b>Telephone</b>
              </h3>
              {!loadingData ? (
                <OutlinedInput
                  id="telephone"
                  value={telephoneState}
                  onChange={(e) => setTelephoneState(e.target.value)}
                  placeholder=""
                  type="text"
                />
              ) : (
                loading_skeleton
              )}
            </div>
          ) : (
            ""
          )}

          {email ? (
            <div className="form-group">
              <h3>
                <b>Email</b>
              </h3>
              {!loadingData ? (
                <OutlinedInput
                  id="email"
                  value={emailState}
                  onChange={(e) => setEmailState(e.target.value)}
                  placeholder="Email@Molecula.com"
                  type="email"
                />
              ) : (
                loading_skeleton
              )}
            </div>
          ) : (
            ""
          )}

          {instagram ? (
            <div className="form-group">
              <h3>
                <b>Instagram</b>
              </h3>
              {!loadingData ? (
                <OutlinedInput
                  id="instagram"
                  value={instagramState}
                  onChange={(e) => setInstagramState(e.target.value)}
                  placeholder="Paste your instagram link here"
                  type="text"
                />
              ) : (
                loading_skeleton
              )}
            </div>
          ) : (
            ""
          )}

          {facebook ? (
            <div className="form-group">
              <h3>
                <b>Facebook</b>
              </h3>
              {!loadingData ? (
                <OutlinedInput
                  id="facebook"
                  value={facebookState}
                  onChange={(e) => setFacebookState(e.target.value)}
                  placeholder="Paste your facebook link here"
                  type="text"
                />
              ) : (
                loading_skeleton
              )}
            </div>
          ) : (
            ""
          )}

          {twitter ? (
            <div className="form-group">
              <h3>
                <b>Twitter</b>
              </h3>
              {!loadingData ? (
                <OutlinedInput
                  id="twitter"
                  value={twitterState}
                  onChange={(e) => setTwitterState(e.target.value)}
                  placeholder="Paste your twitter link here"
                  type="text"
                />
              ) : (
                loading_skeleton
              )}
            </div>
          ) : (
            ""
          )}

          {linkedIn ? (
            <div className="form-group">
              <h3>
                <b>LinkedIn</b>
              </h3>
              {!loadingData ? (
                <OutlinedInput
                  id="linkedIn"
                  value={linkedInState}
                  onChange={(e) => setlinkedInState(e.target.value)}
                  placeholder="Paste your linkedin link here"
                  type="text"
                />
              ) : (
                loading_skeleton
              )}
            </div>
          ) : (
            ""
          )}

          {youtube ? (
            <div className="form-group">
              <h3>
                <b>Youtube</b>
              </h3>
              <OutlinedInput
                id="youtube"
                value={youtubeState}
                onChange={(e) => setyoutubeState(e.target.value)}
                placeholder="Paste your linkedin link here"
                type="text"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="action-content">
          <Button
            variant="btn-admin"
            onClick={() =>
              saveData({
                address: addressState,
                telephone: telephoneState,
                email: emailState,
                instagram: instagramState,
                facebook: facebookState,
                twitter: twitterState,
                linkedIn: linkedInState,
                youtube: youtubeState,
              })
            }
          >
            Save changes
          </Button>
          <Button variant="btn-admin-outline-red" onClick={() => resetValue()}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}
