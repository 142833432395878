/** @jsxImportSource @emotion/react */
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { css } from "@mui/material";
import { format } from "date-fns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "unset",
    width: "auto",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "850px",
    minHeight: "400px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

DialogMessageDetail.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  data: PropTypes.object,
};

export default function DialogMessageDetail({ open, close, data }) {
  const [openDialog, setOpenDialog] = React.useState(open);

  const closeDialog = () => {
    close();
    setOpenDialog(false);
  };

  React.useEffect(() => {
    if (open) {
      setOpenDialog(true);
    }
  }, [data, open]);

  return (
    <div>
      <BootstrapDialog
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={closeDialog}
          css={css`
            background-color: #005486;
            color: white;
            position: relative;
            .title-name {
              font-size: 27px;
            }
            .text-detail-small {
              display: flex;
              align-items: center;
              p {
                font-size: 12px;
                &.w-50 {
                  width: 50px;
                }
                &.w-10 {
                  width: 10px;
                }
              }
            }
            button {
              right: 10px;
              top: 10%;
              position: absolute;
            }
            svg {
              width: 1.5em;
              height: 1.5em;
              color: white;
            }
          `}
        >
          <div className="title-name">{data?.name}</div>
          <div className="text-detail-small">
            <p className="w-50">From</p>
            <p className="w-10">:</p>
            <p>{data?.email}</p>
          </div>
          <div className="text-detail-small">
            <p className="w-50">Phone</p>
            <p className="w-10">:</p>
            <p>{data?.phone_number}</p>
          </div>
          <div className="text-detail-small">
            <p className="w-50">Date</p>
            <p className="w-10">:</p>
            <p>{data ? format(new Date(data?.created_at), "dd MMM") : ""}</p>
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>{data?.message}</DialogContent>
      </BootstrapDialog>
    </div>
  );
}
