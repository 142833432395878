import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getContact = createAsyncThunk(
  "get/contactAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/configuration?flag=contact_`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const contactUpdate = createAsyncThunk(
  "update/contactAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/configuration`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: [
          {
            key: "contact_address",
            value: arg.address,
          },
          {
            key: "contact_telephone",
            value: arg.telephone,
          },
          {
            key: "contact_email",
            value: arg.email,
          },
          {
            key: "contact_facebook",
            value: arg.facebook,
          },
          {
            key: "contact_twitter",
            value: arg.twitter,
          },
          {
            key: "contact_instagram",
            value: arg.instagram,
          },
          {
            key: "contact_linkedin",
            value: arg.linkedIn,
          },
          {
            key: "contact_youtube",
            value: arg.youtube,
          },
        ],
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState: {
    loading_contact: false,
    contact_data: [],
    loading_update: false,
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [getContact.pending]: (state, { payload }) => {
      state.loading_contact = true;
    },
    [getContact.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.contact_data = payload.data;
      } else {
        state.contact_data = [];
      }
      state.loading_contact = false;
    },
    [getContact.rejected]: (state, action) => {
      state.loading_contact = false;
    },
    //   ---------- || ----------
    [contactUpdate.pending]: (state, { payload }) => {
      state.loading_update = true;
    },
    [contactUpdate.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update = false;
    },
    [contactUpdate.rejected]: (state, action) => {
      state.loading_update = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = contactSlice.actions;

export default contactSlice.reducer;
