/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Container, Grid, Button } from "@mui/material";
import { ContentCss } from "../../../styles/landing/spiceitup_css";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Mobile, Desktop } from "../../ResponsiveMedia";

ContentSpiceItUp.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  socialMedia: PropTypes.bool,
  instagram: PropTypes.string,
  youtube: PropTypes.string,
  image: PropTypes.string,
  reverseGrid: PropTypes.bool,
  themeColor: PropTypes.string,
  buttonShow: PropTypes.bool,
  buttonTitle: PropTypes.string,
  buttonLink: PropTypes.string,
  loading: PropTypes.bool,
};

export default function ContentSpiceItUp({
  title,
  description,
  socialMedia = false,
  instagram,
  youtube,
  image,
  reverseGrid = false,
  themeColor = "#E35B29",
  buttonShow = false,
  buttonTitle,
  buttonLink,
}) {
  let social_media = "";
  if (socialMedia) {
    social_media = (
      <div className="socmed">
        <p>
          <b>Follow our social media account :</b>
        </p>
        <p>
          <small>
            <span
              css={css`
                font-style: italic;
              `}
            >
              Instagram
            </span>

            <span
              css={css`
                color: #000000;
              `}
            >
              {" "}
              :{" "}
              <a
                href={`https://www.instagram.com/${instagram?.replace(
                  "@",
                  ""
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                {instagram}
              </a>
            </span>
          </small>
        </p>
        <p>
          <small>
            <span
              css={css`
                font-style: italic;
              `}
            >
              Youtube
            </span>
            <span
              css={css`
                color: #000000;
              `}
            >
              {" "}
              :{" "}
              <a href={youtube} target="_blank" rel="noreferrer">
                spiceitup!
              </a>{" "}
            </span>
          </small>
        </p>
      </div>
    );
  }

  let Grid1 = (
    <Grid item md={6}>
      <h1>{title}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: description?.replaceAll("<p></p>", "<br/>"),
        }}
      />
      {social_media}
      {buttonShow ? (
        <Link to={buttonLink}>
          <Button variant="contained" color="secondary" disableElevation>
            {buttonTitle}
          </Button>
        </Link>
      ) : (
        ""
      )}
    </Grid>
  );

  let Grid2 = (
    <Grid item md={6}>
      <img
        src={image}
        css={css`
          width: 100%;
        `}
        alt="spice it up"
      />
    </Grid>
  );
  return (
    <div css={ContentCss(themeColor).self}>
      <Container>
        <Grid
          container
          spacing={3}
          css={css`
            margin-bottom: 50px;
            align-items: center;
          `}
        >
          <Desktop>
            {reverseGrid ? Grid2 : Grid1}
            {reverseGrid ? Grid1 : Grid2}
          </Desktop>
          <Mobile>
            {Grid2}
            {Grid1}
          </Mobile>
        </Grid>
      </Container>
    </div>
  );
}
