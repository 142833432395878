import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const fetchLogin = createAsyncThunk(
  "auth/login",
  async (arg, { getState }) => {
    const formData = new FormData();
    formData.append("email", arg.email);
    formData.append("password", arg.password);
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/auth/login`,
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/forgot-password`,
        data: {
          email: arg.email,
        },
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/reset-password`,
        data: {
          token: arg.token,
          password: arg.newPassword,
          password_confirmation: arg.newPasswordConfirm,
        },
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    value: 0,
    loadingLogin: false,
    error: false,
    success: false,
    dataRes: null,
  },
  reducers: {
    logout: (state, data) => {
      localStorage.removeItem("token");
      window.location.reload();
    },
  },
  extraReducers: {
    [fetchLogin.pending]: (state, { payload }) => {
      state.loadingLogin = true;
    },
    [fetchLogin.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        localStorage.setItem("token", payload.data.token);
        state.error = false;
        state.success = true;
      } else {
        state.error = true;
      }
      state.loadingLogin = false;
    },
    [fetchLogin.rejected]: (state, action) => {
      state.error = true;
      state.loadingLogin = false;
    },
    // Forgot Password
    [forgotPassword.pending]: (state, { payload }) => {
      state.loadingLogin = true;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.error = false;
        state.success = true;
      } else {
        state.error = true;
      }
      state.loadingLogin = false;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.error = true;
      state.loadingLogin = false;
    },
    // Reset Password
    [resetPassword.pending]: (state, { payload }) => {
      state.loadingLogin = true;
    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.error = false;
        state.success = true;
      } else {
        state.error = true;
      }
      state.loadingLogin = false;
    },
    [resetPassword.rejected]: (state, action) => {
      state.error = true;
      state.loadingLogin = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logout } = authenticationSlice.actions;

export default authenticationSlice.reducer;
