import { css } from "@emotion/react";

export const ContentCss = (colorTheme) => ({
  self: css`
    h1 {
      font-size: 2.4em;
      color: ${colorTheme};
      margin-bottom: 15px;
      @media only screen and (max-width: 720px) {
        font-size: 1.7em;
      }
    }
    p {
        @media only screen and (max-width: 720px) {
            font-size: 13px;
        }
    }  
    img {
      @media only screen and (max-width: 720px) {
        height: 250px;
        width: 100%:
      }
    }
    .MuiGrid-root {
        width: 100%;
    }
    .socmed {
      margin-top: 20px;
      p {
        color: ${colorTheme};
      }
    }
    .MuiButton-root {
      color: white;
      border-color: ${colorTheme};
      background-color: ${colorTheme};
      border-radius: 0;
      width: fit-content;
      padding: 9px 30px !important;
      margin-top: 15px;
      &:hover {
        background-color: #3461a0;
      }
    }
  `,
});
