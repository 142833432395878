import { css } from "@emotion/react";

export const DialogAdminCss = {
  self: css`
    .css-140puxv-MuiTypography-root-MuiDialogTitle-root {
      background-color: #005486;
      color: #ffffff;
      padding: 23px 20px;
      .MuiButtonBase-root {
        margin-top: 6px;
      }
      .MuiSvgIcon-root {
        color: #ffffff;
        font-size: 30px;
      }
    }
    .MuiOutlinedInput-root {
      width: 100%;
      margin-bottom: 15px;
    }

    .MuiInputBase-input {
      padding: 12px;
    }

    .MuiDialogContent-root {
      border-top: unset;
      border-bottom: unset;
      min-width: 950px;
      .action-content {
        display: flex;
        justify-content: flex-start;
        .MuiButton-btn-admin {
          margin-right: 10px;
        }
      }
    }

    .form-group-control {
      padding-top: 5px;
      .form-group {
        margin: 10px 0;
      }
    }

    .MuiDialogActions-root {
      justify-content: flex-start;
      padding: 10px 15px;
    }

    .MuiButton-btn-admin {
      border-radius: 5px !important;
      padding: 8px 35px !important;
      font-weight: bold;
    }
    .MuiButton-btn-admin-outline-red {
      border-radius: 5px !important;
      padding: 8px 35px !important;
      font-weight: bold;
    }
  `,
};

export const SingleUploadImageInsideDialogCss = {
  self: css`
    margin-bottom: 17px;
    .table-datas {
      &__item {
        background-color: #ffffff;
        display: flex;
        align-items: center;
        margin: 5px 0;
        &:first-of-type {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:last-of-type {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        &--dum-thumbnail {
          height: 80px;
          width: 100px;
          padding: 10px;
          border: 2px dashed #a6a6a6;
          border-radius: 8px;
          margin-right: 15px;
          cursor: pointer;
        }
        &--thumbnail {
          cursor: pointer;
          background-position: center;
          //   background-size: cover;
          background-size: 100%;
          background-repeat: no-repeat;
          height: 80px;
          width: 100px;
          border-radius: 8px;
          margin-right: 15px;
          position: relative;
          &__icon-status {
            position: absolute;
            right: -5px;
            bottom: -5px;
            width: 15px;
            height: 15px;
            background-color: #b8b8b8;
            border: 2px solid #ffffff;
            border-radius: 50%;
          }
        }
        &--detail {
          font-size: 15px;
          line-height: 1.4;
          color: #616161;
        }
        &--title {
        }
        .MuiButton-root {
          margin-left: auto;
          padding: 5px 30px !important;
        }
        &--delete-btn {
          margin: 0px 35px;
          color: #ff0000;
          cursor: pointer;
        }
      }
    }
  `,
};

export const MasonryImagesInsideDialogCss = {
  self: css`
    border: 1px solid #dbdbdb;
    border-radius: 12px;
    padding: 20px 30px;
    margin: 7px 0 20px 0;
    .uploader-box {
      border: 2px dashed #a6a6a6;
      border-radius: 8px;
      padding: 15px 0px;
      position: relative;
      .img-showed {
        cursor: pointer;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        height: 125px;
        border-radius: 8px;
        margin: 0 auto;
        &.grid-8 {
          width: 70%;
        }
        &.grid-4 {
          width: 85%;
        }
      }
      &.tall {
        min-height: 418px;
        img {
          margin-top: 50%;
        }
        .img-showed {
          height: 341px;
        }
      }
      img {
        height: 125px;
        display: block;
        margin: 0 auto;
        &.grid-8 {
          width: 70%;
        }
        &.grid-4 {
          width: 85%;
        }
      }
      &__details-text {
        position: absolute;
        font-size: 11.5px;
        text-align: center;
        transform: translate(-50%, -50%);
        &.grid-8 {
          width: 60%;
          top: 50%;
          left: 50%;
        }
        &.grid-4 {
          width: 80%;
          top: 50%;
          left: 50%;
          &.tall {
            top: 44%;
          }
        }
      }
      .MuiButton-root {
        margin: 0 auto;
        padding: 4px 0 !important;
        font-weight: 700;
        font-size: 12.5px;
        border-radius: 5px !important;
        margin-top: 10px;
        &.grid-8 {
          width: 45%;
        }
        &.grid-4 {
          width: 80%;
        }
      }
    }
  `,
};
