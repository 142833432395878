import { css } from "@emotion/react";

export const ImagesUploadCss = {
  self: css`
    .title-subtitle {
      display: flex;
      color: #005486;
      align-items: center;
      margin-bottom: 10px;
      p {
        margin-left: 15px;
        margin-block-end: 0;
      }
    }
    .trunk-uploader {
      background: #ffffff;
      border: 2px solid #efefef;
      border-radius: 16px;
      padding: 20px;
      display: flex;
      &__crate {
        width: fit-content;
        padding: 10px 15px;
        border: 2px dashed #a6a6a6;
        border-radius: 16px;
        img {
          width: 200px;
        }
        &--img-src {
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          width: 180px;
          height: 130px;
          border-radius: 8px;
        }
        &--detail-img {
          text-align: center;
          font-size: 14px;
          margin-block-end: 0;
          margin-block-start: 0;
          line-height: 1.4;
          margin: 6px 0 20px 0;
        }
        .MuiButton-root {
          width: 100%;
          font-weight: bold;
          margin: 10px 0;
        }
        &--delete-btn {
          text-align: center;
          color: #ff0000;
          margin: 13px 0;
          cursor: pointer;
        }
      }
    }
  `,
};
