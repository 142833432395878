/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";

export default function Notfound() {
  return (
    <div>
      <h2
        css={css`
          margin: 0;
          position: absolute;
          top: 48%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: gray;
        `}
      >
        Page Not Found
      </h2>
    </div>
  );
}
