import { css } from "@emotion/react";

export const AuthCss = {
  self: css`
    font-family: "DM Sans", sans-serif;
    background-image: url("/icons/admin/bg-auth.svg");
    background-position: center 85%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    .header {
      border-bottom: 1px solid #000;
      padding: 25px;
      background-color: #ffffff;
      img {
        width: 4%;
        display: block;
        margin: 0 auto;
      }
    }
    .box-form {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: #ffffff;
      padding: 45px 50px 65px 50px;
      border: 3px solid #eaeaea;
      box-shadow: 0px 91px 86px -63px rgba(0, 0, 0, 0.2);
      border-radius: 16px;
    }
  `,

  formBoard: css`
    display: block;
    .title {
      text-align: center;
      color: #005486;
      margin-bottom: 35px;
      h2 {
        margin-bottom: 10px;
      }
    }
    .form-group-control {
      display: block;
      h5 {
        color: #005486;
        margin-bottom: 8px;
      }
      .form-group {
        margin-bottom: 15px;
        position: relative;
        .visibility-eye {
          position: absolute;
          right: 15px;
          top: 35px;
          font-size: 19px;
          color: #005486;
          cursor: pointer;
        }
      }
      .MuiOutlinedInput-root {
        font-size: 13px;
        width: 400px;
        border: 0;
        fieldset {
          border-width: 0px;
        }
      }
      .MuiButton-btn-admin {
        width: 100%;
      }
      .MuiFormHelperText-root {
        color: #ff0000;
        margin-left: 5px;
      }
    }
    .link-to {
      text-align: center;
      margin-top: 10px;
      color: #f58127;
    }
    .MuiLoadingButton-loadingIndicator {
      color: #ffffff;
      top: 13px;
    }
    .MuiPaper-root {
      padding: 5px 16px;
      margin-bottom: 15px;
    }
  `,
};
