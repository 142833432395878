/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import ArticleTopSection from "../../components/landing/ArticleTopSection";
import Sponsors from "../../components/landing/Sponsors";
import CarouselStory from "../../components/landing/CarouselStory";
import Portfolio from "../../components/landing/Portfolio";
import Contact from "../../components/landing/Contact";
import { useSelector, useDispatch } from "react-redux";
import {
  getBannerMarketing,
  getMarketingDetail,
  getBrandLogo,
  getDesignStory,
  getPortfolio,
} from "../../redux/features/landing/marketing";

export default function MarketingCommunications() {
  const banner_data = useSelector(
    (state) => state.marketingLandingReducer.banner_data
  );
  const loading_banner = useSelector(
    (state) => state.marketingLandingReducer.loading_banner
  );

  const marketing_detail_data = useSelector(
    (state) => state.marketingLandingReducer.marketing_detail_data
  );
  const marketing_loading_detail = useSelector(
    (state) => state.marketingLandingReducer.marketing_loading_detail
  );

  const brand_logo_data = useSelector(
    (state) => state.marketingLandingReducer.brand_logo_data
  );
  const loading_brand_logo = useSelector(
    (state) => state.marketingLandingReducer.loading_brand_logo
  );

  const design_story_data = useSelector(
    (state) => state.marketingLandingReducer.design_story_data
  );
  const loading_design_story = useSelector(
    (state) => state.marketingLandingReducer.loading_design_story
  );

  const portfolio_data = useSelector(
    (state) => state.marketingLandingReducer.portfolio_data
  );
  const loading_portfolio = useSelector(
    (state) => state.marketingLandingReducer.loading_portfolio
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBannerMarketing());
    dispatch(getMarketingDetail());
    dispatch(getBrandLogo());
    dispatch(getDesignStory());
    dispatch(getPortfolio(6));
  }, [dispatch]);

  const getInTouchRef = React.useRef();
  const executeScroll = () =>
    getInTouchRef?.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  return (
    <div
      css={css`
        margin-top: 60px;
        @media only screen and (max-width: 720px) {
          margin-top: 15px;
        }
      `}
    >
      <ArticleTopSection
        image={banner_data ? banner_data[0]?.image : ""}
        video=""
        title={marketing_detail_data?.title}
        desc={marketing_detail_data?.description}
        loadingBanner={loading_banner}
        loadingDetail={marketing_loading_detail}
        linkButton={"/getintouch"}
        reverseGrid={true}
        breadcrumbData={[
          {
            title: "Home",
            link: "/",
          },
          {
            title: "Marketing Communications",
            link: "",
          },
        ]}
        getInTouchTo={() => executeScroll()}
      />
      <Sponsors data={brand_logo_data} loading={loading_brand_logo} />
      <CarouselStory data={design_story_data} loading={loading_design_story} />
      <Portfolio
        data={portfolio_data}
        loading={loading_portfolio}
        colorTheme="#4285F4"
        baseLink="/marketing-communications/portfolio/"
        loadHide={(e) => dispatch(getPortfolio(e === "load" ? 999 : 6))}
      />
      <div ref={getInTouchRef}>
        <Contact />
      </div>
    </div>
  );
}
