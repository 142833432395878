/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { CarouselStoryCss } from "../../styles/landing/globals_css";
import { PropTypes } from "prop-types";
import "../../loader-plugin";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Skeleton } from "@mui/material";
import { useMediaQuery } from "react-responsive";

CarouselStory.protoTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default function CarouselStory({ data, loading }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const settingOwl = {
    loop: true,
    margin: 10,
    items: 1,
    autoWidth: false,
    dots: true,
    nav: true,
    navText: [
      `<img src="icons/arrow-circle-left.svg" alt="arrow right" />`,
      `<img src="icons/arrow-circle-right.svg" alt="arrow right" />`,
    ],
    // dotsContainer: ".desc-box",
    responsive: {
      0: {
        items: 1,
        autoWidth: false,
      },
      600: {
        items: 1,
        autoWidth: false,
      },
      1000: {
        items: 1,
        autoWidth: false,
      },
    },
  };

  return (
    <div css={CarouselStoryCss.self}>
      {loading ? (
        <React.Fragment>
          <Skeleton variant="rectangular" height={isMobile ? 210 : 550} />
          <div
            css={css`
              margin: 10px auto;
              width: 75%;
              .MuiSkeleton-root {
                margin: 0 auto;
              }
            `}
          >
            <Skeleton width="90%" height={30} />
            <Skeleton />
            <Skeleton width="70%" />
            <Skeleton width="80%" />
          </div>
        </React.Fragment>
      ) : (
        <OwlCarousel className="owl-theme" {...settingOwl}>
          {data?.length > 0 &&
            data?.map((el) => {
              return (
                <div key={el.id}>
                  <div
                    css={css`
                      background-image: url("${el.image}");
                    `}
                    className="img-story"
                  ></div>
                  <div className="desc-box">
                    <h1>{el.title}</h1>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: el.description?.replaceAll("<p></p>", "<br/>"),
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </OwlCarousel>
      )}
    </div>
  );
}
