/** @jsxImportSource @emotion/react */
import React from "react";
import MediaUploader from "../../components/admin/MediaUploader";
import { useSelector, useDispatch } from "react-redux";
import {
  getMember,
  createMember,
  updateMember,
  deleteMember,
} from "../../redux/features/admin/member";

export default function MemberAdmin() {
  const loading_member = useSelector(
    (state) => state.memberReducer.loading_member
  );
  const loading_update_member = useSelector(
    (state) => state.memberReducer.loading_update_member
  );
  const member_datas = useSelector((state) => state.memberReducer.member_datas);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMember());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addItemMember = async (event) => {
    await dispatch(createMember(event));
    dispatch(getMember());
  };

  const updateItemMember = async (event) => {
    await dispatch(updateMember(event));
    dispatch(getMember());
  };

  const deleteItemMember = async (e) => {
    await dispatch(deleteMember(e));
    dispatch(getMember());
  };

  return (
    <div>
      <MediaUploader
        title="Member List"
        data={member_datas}
        loading={loading_member}
        saveDataLoading={loading_update_member}
        formOnDialog={{
          singleImageUpload: {
            show: true,
            title: "Upload Image",
            imageDimension: "1024 x 1024",
            maxFile: "2.0 MB",
            supportedFile: "JPG,JPEG,PNG",
          },
          memberName: true,
          positionJob: true,
          linkedIn: true,
          memberDescription: true,
        }}
        addItem={(e) => addItemMember(e)}
        deleteItem={(e) => deleteItemMember(e)}
        saveData={(e) => updateItemMember(e)}
        type="image"
        imageDimension="1024 x 1024"
        maxFile="2.0 MB"
        supportedFile="JPG,JPEG,PNG"
      />
    </div>
  );
}
