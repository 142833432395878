import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const base_url = "https://api.moleculaindonesia.co.id";

export const sendMessage = createAsyncThunk(
  "send/messageLanding",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/web/v1/contact-us`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          name: arg.name,
          email: arg.email,
          phone_number: arg.phoneNumber,
          message: arg.message,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const messagesSlice = createSlice({
  name: "messagesSlice",
  initialState: {
    loading: false,
  },
  reducers: {
    closeSuccess: (state) => {},
  },
  extraReducers: {
    [sendMessage.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [sendMessage.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
        toast.success("Pesan berhasil terkirim");
      } else {
        // failed condition;
        toast.error("Pesan gagal dikirim");
      }
      state.loading = false;
    },
    [sendMessage.rejected]: (state, action) => {
      toast.error("Pesan gagal dikirim");
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { closeSuccess } = messagesSlice.actions;

export default messagesSlice.reducer;
