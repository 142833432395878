import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBannerPublicRelation = createAsyncThunk(
  "get/bannerPublicRelationLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/banner?per_page=5&search=banner_public_relation&banner_type=general`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getDetail = createAsyncThunk(
  "get/detailPublicRelationLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/slug/public-relation`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getBrandLogo = createAsyncThunk(
  "get/brandLogoPublicRelationLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/banner?per_page=17&banner_type=public_relation_brand_logo`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getPortfolio = createAsyncThunk(
  "get/portfolioPublicRelationLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post?per_page=${arg}&post_type=public_relation_portfolio`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getPortfolioById = createAsyncThunk(
  "get/portfolioByIdPublicRelationLanding",
  async (arg, { getState }) => {
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/web/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const publicRelationLandingSlice = createSlice({
  name: "publicRelationLandingSlice",
  initialState: {
    loading_banner: false,
    banner_data: [],

    loading_detail: false,
    detail_data: null,

    loading_brand_logo: false,
    brand_logo_data: [],

    loading_portfolio: false,
    portfolio_data: [],

    loading_portfolio_id: false,
    portfolio_id_data: null,
  },
  reducers: {
    addMore: (state) => {},
    deleteItem: (state, index) => {},
  },
  extraReducers: {
    [getBannerPublicRelation.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBannerPublicRelation.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBannerPublicRelation.rejected]: (state, action) => {
      state.loading_banner = false;
    },

    // Detail
    [getDetail.pending]: (state, { payload }) => {
      state.loading_detail = true;
    },
    [getDetail.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.detail_data = payload.data;
      } else {
        state.detail_data = null;
      }
      state.loading_detail = false;
    },
    [getDetail.rejected]: (state, action) => {
      state.loading_detail = false;
    },

    // Brand Logo
    [getBrandLogo.pending]: (state, { payload }) => {
      state.loading_brand_logo = true;
    },
    [getBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.brand_logo_data = payload.data;
      } else {
        state.brand_logo_data = [];
      }
      state.loading_brand_logo = false;
    },
    [getBrandLogo.rejected]: (state, action) => {
      state.loading_brand_logo = false;
    },

    // Portfolio
    [getPortfolio.pending]: (state, { payload }) => {
      state.loading_portfolio = true;
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_data = payload;
      } else {
        state.portfolio_data = [];
      }
      state.loading_portfolio = false;
    },
    [getPortfolio.rejected]: (state, action) => {
      state.loading_portfolio = false;
    },

    // Portfolio By Id
    [getPortfolioById.pending]: (state, { payload }) => {
      state.loading_portfolio_id = true;
    },
    [getPortfolioById.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_id_data = payload.data;
      } else {
        state.portfolio_id_data = null;
      }
      state.loading_portfolio_id = false;
    },
    [getPortfolioById.rejected]: (state, action) => {
      state.loading_portfolio_id = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore, deleteItem } = publicRelationLandingSlice.actions;

export default publicRelationLandingSlice.reducer;
