import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getBanner = createAsyncThunk(
  "get/bannerPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/banner?flag=banner_promotion`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const putImageBanner = createAsyncThunk(
  "put/imagebannerPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/banner/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateTitleDesc = createAsyncThunk(
  "put/updateTitleDescPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/slug/promotion`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.title,
          description: arg.desc,
          post_type: "single_page",
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getTitleDesc = createAsyncThunk(
  "get/TitleDescPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post/slug/promotion`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const getBrandLogo = createAsyncThunk(
  "get/brandlogoPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/banner?banner_type=promotion_brand_logo`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createBrandLogo = createAsyncThunk(
  "create/brandLogoPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/banner`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: "-",
          banner_type: "promotion_brand_logo",
          image: arg,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateBrandLogo = createAsyncThunk(
  "update/brandLogoPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/banner/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: "promotion-brand-logo",
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deleteBrandLogo = createAsyncThunk(
  "delete/brandLogoPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/banner/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

//
//
// DESIGN STORY
export const getDesignStory = createAsyncThunk(
  "get/designStoryPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post?post_type=promotion_design_story`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createDesignStory = createAsyncThunk(
  "create/designStoryPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/post`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.headingTitle,
          description: arg.contentDesription,
          post_type: "promotion_design_story",
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateDesignStory = createAsyncThunk(
  "update/designStoryPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    const data_params = {
      title: arg.headingTitle,
      description: arg.contentDesription,
      post_type: "promotion_design_story",
      image: arg.image,
    };
    if (data_params.image.includes("https")) {
      delete data_params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data_params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deleteDesignStory = createAsyncThunk(
  "delete/designStoryPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

//
//
// Portfolio
export const getPortfolio = createAsyncThunk(
  "get/portfolioPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post?post_type=promotion_portfolio`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createPortfolio = createAsyncThunk(
  "create/portfolioPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/post`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.brandName,
          description: arg.brandDescription,
          post_type: "promotion_portfolio",
          image: arg.youtubeLink ? "" : arg.image,
          detail: arg.portfolioDetails,
          youtube: arg.youtubeLink,
          post_images: arg.postImages,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updatePortfolio = createAsyncThunk(
  "update/portfolioPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    arg.postImages = arg.postImages.map((el) => {
      if (el.image.includes("https")) {
        return { image: null };
      } else {
        return { image: el.image };
      }
    });
    console.log(arg.postImages);
    const data_params = {
      title: arg.brandName,
      description: arg.brandDescription,
      detail: arg.portfolioDetails,
      post_type: "promotion_portfolio",
      image: arg.image,
      youtube: arg.youtubeLink,
      post_images: arg.postImages,
    };
    if (data_params.image.includes("http")) {
      delete data_params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data_params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deletePortfolio = createAsyncThunk(
  "delete/portfolioPromotionAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const promotionSlice = createSlice({
  name: "promotionSlice",
  initialState: {
    loading_banner: false,
    banner_data: [],
    loading_upload_banner: false,

    loading: false,
    loading_save_description: false,
    title: "",
    description: "",

    brand_logo_datas: [],
    loading_brand_logo: false,
    loading_update_brand_logo: false,

    design_story_datas: [],
    loading_design_story: false,
    loading_update_design_story: false,

    portfolio_datas: [],
    loading_portfolio: false,
    loading_update_portfolio: false,
  },
  reducers: {
    addMoreBrandLogoTempo: (state) => {
      state.brand_logo_datas.push({
        id: "",
        title: "promotion-brand-logo",
        slug: "promotion-brand-logo",
        banner_type: "promotion_brand_logo",
        image: "",
        created_at: "",
      });
    },
  },
  extraReducers: {
    //   Banner || ----------
    [getBanner.pending]: (state, { payload }) => {
      state.loading_banner = true;
    },
    [getBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.banner_data = payload.data;
      } else {
        state.banner_data = [];
      }
      state.loading_banner = false;
    },
    [getBanner.rejected]: (state, action) => {
      state.loading_banner = false;
    },
    [putImageBanner.pending]: (state, { payload }) => {
      state.loading_upload_banner = true;
    },
    [putImageBanner.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
      } else {
      }
      state.loading_upload_banner = false;
    },
    [putImageBanner.rejected]: (state, action) => {
      state.loading_upload_banner = false;
    },
    //
    //
    //
    //   Title Description || ----------
    [updateTitleDesc.pending]: (state, { payload }) => {
      state.loading_save_description = true;
    },
    [updateTitleDesc.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_save_description = false;
    },
    [updateTitleDesc.rejected]: (state, action) => {
      state.loading_save_description = false;
    },
    [getTitleDesc.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getTitleDesc.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.title = payload.data?.title;
        state.description = payload.data?.description;
      } else {
        state.title = "";
        state.description = "";
      }
      state.loading = false;
    },
    [getTitleDesc.rejected]: (state, action) => {
      state.loading = false;
    },
    //
    //
    //
    //   Brand Logo || ----------
    [getBrandLogo.pending]: (state, { payload }) => {
      state.loading_brand_logo = true;
    },
    [getBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.brand_logo_datas = payload.data;
      } else {
        state.brand_logo_datas = [];
      }
      state.loading_brand_logo = false;
    },
    [getBrandLogo.rejected]: (state, action) => {
      state.loading_brand_logo = false;
    },
    [createBrandLogo.pending]: (state, { payload }) => {
      state.loading_update_brand_logo = true;
    },
    [createBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_brand_logo = false;
    },
    [createBrandLogo.rejected]: (state, action) => {
      state.loading_update_brand_logo = false;
    },
    [updateBrandLogo.pending]: (state, { payload }) => {
      state.loading_update_brand_logo = true;
    },
    [updateBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_brand_logo = false;
    },
    [updateBrandLogo.rejected]: (state, action) => {
      state.loading_update_brand_logo = false;
    },
    [deleteBrandLogo.pending]: (state, { payload }) => {
      state.loading_update_brand_logo = true;
    },
    [deleteBrandLogo.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_brand_logo = false;
    },
    [deleteBrandLogo.rejected]: (state, action) => {
      state.loading_update_brand_logo = false;
    },
    //
    //
    //
    //   Design Story || ----------
    [getDesignStory.pending]: (state, { payload }) => {
      state.loading_design_story = true;
    },
    [getDesignStory.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.design_story_datas = payload.data;
      } else {
        state.design_story_datas = [];
      }
      state.loading_design_story = false;
    },
    [getDesignStory.rejected]: (state, action) => {
      state.loading_design_story = false;
    },
    [createDesignStory.pending]: (state, { payload }) => {
      state.loading_update_design_story = true;
    },
    [createDesignStory.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_design_story = false;
    },
    [createDesignStory.rejected]: (state, action) => {
      state.loading_update_design_story = false;
    },
    //   ---------- || ----------
    [updateDesignStory.pending]: (state, { payload }) => {
      state.loading_update_design_story = true;
    },
    [updateDesignStory.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_design_story = false;
    },
    [updateDesignStory.rejected]: (state, action) => {
      state.loading_update_design_story = false;
    },
    //   ---------- || ----------
    [deleteDesignStory.pending]: (state, { payload }) => {
      state.loading_update_design_story = true;
    },
    [deleteDesignStory.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_design_story = false;
    },
    [deleteDesignStory.rejected]: (state, action) => {
      state.loading_update_design_story = false;
    },
    //
    //
    //
    //   Portfolio || ----------
    [getPortfolio.pending]: (state, { payload }) => {
      state.loading_portfolio = true;
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.portfolio_datas = payload.data;
      } else {
        state.portfolio_datas = [];
      }
      state.loading_portfolio = false;
    },
    [getPortfolio.rejected]: (state, action) => {
      state.loading_portfolio = false;
    },
    [createPortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [createPortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [createPortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [updatePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [updatePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [updatePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
    //   ---------- || ----------
    [deletePortfolio.pending]: (state, { payload }) => {
      state.loading_update_portfolio = true;
    },
    [deletePortfolio.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_portfolio = false;
    },
    [deletePortfolio.rejected]: (state, action) => {
      state.loading_update_portfolio = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMoreBrandLogoTempo } = promotionSlice.actions;

export default promotionSlice.reducer;
