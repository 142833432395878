/** @jsxImportSource @emotion/react */
import React from "react";
import NavbarMobile from "./NavbarMobile";
import NavbarDesktop from "./NavbarDesktop";
import { Desktop, Mobile, Tablet } from "../ResponsiveMedia";

export default function NavBar() {
  return (
    <React.Fragment>
      <Desktop>
        <NavbarDesktop />
      </Desktop>
      <Mobile>
        <NavbarMobile />
      </Mobile>
      <Tablet>
        <NavbarMobile />
      </Tablet>
    </React.Fragment>
  );
}
