import { css } from "@emotion/react";

export const FormDataGroupCss = {
  self: css`
    .MuiOutlinedInput-root {
      width: 100%;
      margin-bottom: 15px;
    }

    .MuiInputBase-input {
      padding: 12px;
    }

    h3 {
      color: #005486;
    }

    border-top: unset;
    border-bottom: unset;
    min-width: 950px;
    .rdw-editor-wrapper {
      width: 100%;
      height: 150px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      margin-bottom: 15px;
      .rdw-editor-toolbar {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 1px solid #76ccff;
        background: #76ccff;
        .rdw-option-wrapper {
          background: #76ccff;
          border: 1px solid #76ccff;
        }
      }
      .public-DraftStyleDefault-block {
        margin: 0.3em 1em;
      }
    }
    .action-content {
      display: flex;
      justify-content: flex-start;
      .MuiButton-btn-admin {
        margin-right: 10px;
      }
    }

    .form-group-control {
      padding-top: 5px;
      .form-trunk {
        background-color: white;
        border: 3px solid #efefef;
        border-radius: 16px;
        padding: 30px;
        margin: 15px 0;
      }
      .form-group {
        margin: 10px 0;
      }
    }
    .loading-box {
      position: relative;
      .skeleton-wave-form {
        position: absolute;
        z-index: 99;
        width: 250px;
        top: 10.5px;
        left: 17px;
      }
    }
    .MuiButton-btn-admin {
      border-radius: 5px !important;
      padding: 8px 35px !important;
      font-weight: bold;
    }
    .MuiButton-btn-admin-outline-red {
      border-radius: 5px !important;
      padding: 8px 35px !important;
      font-weight: bold;
    }
  `,
};
