/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { MediaUploaderCss } from "../../styles/admin/media-uploader_css";
import { Button, Divider, Skeleton } from "@mui/material";
import { css } from "@mui/material";
import DialogAdmin from "../../components/admin/DialogAdmin";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BackdropLoading from "./BackdropLoading";
import { toast } from "react-toastify";
import { getId } from "../YoutubeIdGetter";

MediaUploader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string, //image, video, image/video
  youtubeOnImage: PropTypes.bool,
  imageDimension: PropTypes.string,
  maxFile: PropTypes.string,
  supportedFile: PropTypes.string,
  mainPost: PropTypes.bool,
  mainPostItem: PropTypes.func,
  addItem: PropTypes.func,
  deleteItem: PropTypes.func,
  formOnDialog: PropTypes.object,
  saveData: PropTypes.func,
  loading: PropTypes.bool,
  noDialog: PropTypes.bool,
  uploadDataDirect: PropTypes.func,
  saveDataLoading: PropTypes.bool,
};

export default function MediaUploader({
  title = "",
  subtitle = "",
  data = [],
  type = "image",
  youtubeOnImage = false,
  formOnDialog = {
    singleImageUpload: {
      show: false,
      title: "",
      imageDimension: "",
      maxFile: "",
      supportedFile: "",
    },
    youtubeLink: false,
    headingTitle: false,
    brandName: false,
    brandDescription: false,
    contentDesription: false,
    portfolioDetails: false,
    masonryImages: false,
    memberName: false,
    positionJob: false,
    linkedIn: false,
    memberDescription: false,
  },
  mainPost,
  mainPostItem,
  saveData,
  loading = false,
  imageDimension,
  maxFile,
  supportedFile,
  addItem,
  deleteItem,
  noDialog = false,
  uploadDataDirect,
  saveDataLoading = false,
}) {
  let ButtonOnTitle = "";
  if (type === "image" || type === "video") {
    ButtonOnTitle = (
      <div className="trunk-btn">
        <Button
          variant="btn-admin-outline-green"
          onClick={() => (noDialog ? addItem() : openDialogFunc("", type))}
        >
          Add More
        </Button>
      </div>
    );
  } else {
    ButtonOnTitle = (
      <div className="trunk-btn">
        <Button
          variant="btn-admin-outline-orange"
          //   onClick={() => addItem("image")}
          onClick={() => openDialogFunc("", "image")}
        >
          Add Images
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          variant="btn-admin-outline-green"
          //   onClick={() => addItem("youtube")}
          onClick={() => openDialogFunc("", "video")}
        >
          Add Videos
        </Button>
      </div>
    );
  }

  const [openDialogEditor, setOpenDialogEditor] = React.useState(false);
  const [dataDialogEditor, setDataDialogEditor] = React.useState({});

  const [idRefInput, setIdRefInput] = React.useState("");
  const inputFileRef = React.useRef();

  const openDialogFunc = (id = "", post_type = "image") => {
    setOpenDialogEditor(true);
    const data_create = {
      id: "",
      title: "-",
      sub_title: null,
      image: "",
      detail: "",
      description: "",
      instagram: null,
      youtube: post_type === "video" ? "-" : "",
      linkedin: null,
      post_images: [
        {
          image: "",
        },
        {
          image: "",
        },
        {
          image: "",
        },
        {
          image: "",
        },
        {
          image: "",
        },
      ],
    };
    setDataDialogEditor(!id ? data_create : data.filter((e) => e.id === id)[0]);
  };

  const openInputFile = (id) => {
    inputFileRef.current.click();
    setIdRefInput(id);
  };

  const handleInputFile = async (event, img_dimension) => {
    const file = event.target.files[0];

    const onUpload = async () => {
      const base64 = await convertBase64(file);
      uploadDataDirect({ image: base64, id: idRefInput });
    };

    const img_dimension_split = img_dimension
      .replace(" ", "")
      .replace(" ", "")
      .split("x");

    if (file.size > 2 * (1024 * 1024)) {
      toast.error("Size terlalu besar, maksimal 2 MB!");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const { height, width } = image;
          if (img_dimension) {
            if (
              height !== Number(img_dimension_split[0]) &&
              width !== Number(img_dimension_split[1])
            ) {
              toast.error("Ukuran dimensi gambar tidak sesuai!");
              event.target.value = null;
              return false;
            } else {
              onUpload();
            }
          } else {
            onUpload();
          }
        };
      };
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  React.useEffect(() => {
    // console.log(Object.keys(formOnDialog));
  });
  const MySwal = withReactContent(Swal);
  const deleteItemFunc = (id) => {
    MySwal.fire({
      title: (
        <p
          css={css`
            color: #000000;
            font-size: 22px;
          `}
        >
          Are you sure to delete this content?
        </p>
      ),
      confirmButtonText: "Delete",
      confirmButtonColor: "#FF0000",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      customClass: {
        container: "",
        header: "",
      },
    }).then((res) => {
      if (res.isDismissed) {
        setOpenDialogEditor(false);
      } else {
        deleteItem(id);
      }
      // return deleteItem(id);
    });
  };

  return (
    <React.Fragment>
      <BackdropLoading loading={saveDataLoading} />
      <div css={MediaUploaderCss.self}>
        <div className="title-subtitle">
          <h3>{title}</h3>
          <p>
            <small>{subtitle}</small>
          </p>
          {ButtonOnTitle}
        </div>

        {data.length <= 0 && !loading ? (
          <h4
            css={css`
              color: gray;
            `}
          >
            Data is empty
          </h4>
        ) : (
          ""
        )}

        <DialogAdmin
          open={openDialogEditor}
          data={dataDialogEditor}
          close={() => setOpenDialogEditor(false)}
          {...formOnDialog}
          youtubeOnImage={youtubeOnImage}
          saveData={(e) => (e.id ? saveData(e) : addItem(e))}
        />

        {loading ? (
          <div className="table-datas">
            <div className="table-datas__item">
              <Skeleton variant="rectangular" width={100} height={80} />
              <div
                css={css`
                  margin-left: 15px;
                `}
              >
                <Skeleton width={250} />
                <Skeleton width={200} />
              </div>
            </div>
          </div>
        ) : (
          <div className="table-datas">
            {data?.map((el, i) => {
              return (
                <div key={i} className="table-datas__item">
                  {youtubeOnImage ? (
                    el.image ? (
                      <React.Fragment>
                        <div
                          className="table-datas__item--thumbnail"
                          css={css`
                            background-image: url("${el.image}");
                          `}
                          onClick={() => (mainPost ? mainPostItem(el) : false)}
                        >
                          {mainPost ? (
                            <div
                              className="table-datas__item--thumbnail__icon-status"
                              css={css`
                                background-color: ${el.is_main_post
                                  ? "#3CBB00 !important"
                                  : "#B8B8B8"};
                              `}
                            ></div>
                          ) : (
                            ""
                          )}
                        </div>
                        {noDialog ? (
                          ""
                        ) : (
                          <h4 className="table-datas__item--title">
                            {el.title}
                          </h4>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <img
                          src="/icons/admin/image-upload-dum.svg"
                          className="table-datas__item--dum-thumbnail"
                          alt=""
                        />
                        <div className="table-datas__item--detail">
                          {el.title === "" || el.title !== "-" ? (
                            <h4 className="table-datas__item--title">
                              {el.title}
                            </h4>
                          ) : (
                            <div>
                              <p>
                                <small>
                                  Image Dimension : {imageDimension}
                                </small>
                                <br />
                                <small>Maximum File Size : {maxFile}</small>
                                <br />
                                <small>Supported File : {supportedFile}</small>
                              </p>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )
                  ) : !el.youtube ? (
                    el.image ? (
                      <React.Fragment>
                        <div
                          className="table-datas__item--thumbnail"
                          css={css`
                            background-image: url("${el.image}");
                          `}
                          onClick={() => (mainPost ? mainPostItem(el) : false)}
                        >
                          {mainPost ? (
                            <div
                              className="table-datas__item--thumbnail__icon-status"
                              css={css`
                                background-color: ${el.is_main_post
                                  ? "#3CBB00 !important"
                                  : "#B8B8B8"};
                              `}
                            ></div>
                          ) : (
                            ""
                          )}
                        </div>
                        {noDialog ? (
                          ""
                        ) : (
                          <h4 className="table-datas__item--title">
                            {el.title}
                          </h4>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <img
                          src="/icons/admin/image-upload-dum.svg"
                          className="table-datas__item--dum-thumbnail"
                          alt=""
                        />
                        <div className="table-datas__item--detail">
                          {el.title === "" || el.title !== "-" ? (
                            <h4 className="table-datas__item--title">
                              {el.title}
                            </h4>
                          ) : (
                            <div>
                              <p>
                                <small>
                                  Image Dimension : {imageDimension}
                                </small>
                                <br />
                                <small>Maximum File Size : {maxFile}</small>
                                <br />
                                <small>Supported File : {supportedFile}</small>
                              </p>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )
                  ) : el.youtube ? (
                    <React.Fragment>
                      <div
                        className="table-datas__item--thumbnail"
                        css={css`
                          background-image: url("https://img.youtube.com/vi/${el.youtube
                            ? getId(el.youtube)
                                .split("embed")
                                .pop()
                                .split("/")
                                .pop()
                            : ""}/default.jpg");
                        `}
                        onClick={() => (mainPost ? mainPostItem(el) : false)}
                      >
                        {mainPost ? (
                          <div
                            className="table-datas__item--thumbnail__icon-status"
                            css={css`
                              background-color: ${el.is_main_post
                                ? "#3CBB00 !important"
                                : "#B8B8B8"};
                            `}
                          ></div>
                        ) : (
                          ""
                        )}
                      </div>
                      {noDialog ? (
                        ""
                      ) : (
                        <h4 className="table-datas__item--title">{el.title}</h4>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img
                        src="/icons/admin/youtube-dum-icon.svg"
                        className="table-datas__item--dum-thumbnail"
                        alt=""
                      />
                      <div className="table-datas__item--detail">
                        <p>
                          <small>Youtube Video Link</small>
                        </p>
                      </div>
                    </React.Fragment>
                  )}
                  <Button
                    variant="btn-admin-green"
                    onClick={() =>
                      noDialog ? openInputFile(el.id) : openDialogFunc(el.id)
                    }
                  >
                    {noDialog ? "Upload Image" : "Edit"}
                  </Button>
                  <h5
                    className="table-datas__item--delete-btn"
                    onClick={() => deleteItemFunc(el.id)}
                  >
                    Delete
                  </h5>
                </div>
              );
            })}
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              ref={inputFileRef}
              onChange={(e) => handleInputFile(e, imageDimension)}
              css={css`
                opacity: 0;
                cursor: pointer;
              `}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
