/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Container } from "@mui/system";
import { VideosCss } from "../../../styles/landing/home_css";
import "../../../loader-plugin";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PropTypes from "prop-types";
import { getId } from "../../YoutubeIdGetter";
import { Grid, Skeleton } from "@mui/material";
import { useMediaQuery } from "react-responsive";

Videos.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

export default function Videos({ data = [], loading = false }) {
  React.useEffect(() => {}, [data]);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const settingOwl = {
    loop: false,
    margin: 10,
    items: 3,
    autoWidth: false,
    dots: false,
    responsive: {
      0: {
        items: 2,
        autoWidth: true,
      },
      600: {
        items: 3,
        autoWidth: false,
      },
      1000: {
        items: 3,
        autoWidth: false,
      },
    },
  };

  const checkMainPost = (data) => {
    return data.is_main_post;
  };

  const filterDataNoMain = (data) => {
    const main_post =
      data?.filter(checkMainPost).length > 0
        ? data?.filter(checkMainPost)[0]
        : data?.length > 0
        ? data[0]
        : "";
    return data?.filter((el) => el.id !== main_post.id);
  };

  return (
    <div css={VideosCss.self}>
      <Container maxWidth="lg">
        <iframe
          css={VideosCss.videotron}
          src={`//www.youtube-nocookie.com/embed/${
            data?.filter(checkMainPost)[0]?.youtube
              ? getId(data?.filter(checkMainPost)[0]?.youtube)
              : data?.length > 0
              ? getId(data[0].youtube)
              : ""
          }`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="Embedded youtube"
        />

        {loading ? (
          <React.Fragment>
            <Skeleton
              variant="rectangular"
              height={isMobile ? 200 : 350}
              css={css`
                margin-bottom: 10px;
              `}
            />
            <Grid container spacing={2}>
              {[...Array(isMobile ? 2 : 3)].map((el, i) => {
                return (
                  <Grid key={i} item md={4} xs={6}>
                    <Skeleton
                      variant="rectangular"
                      height={isMobile ? 120 : 200}
                      css={css`
                        margin-bottom: 5px;
                      `}
                    />
                    <Skeleton width="60%" />
                    <Skeleton />
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        ) : (
          <OwlCarousel
            className="owl-theme"
            {...settingOwl}
            css={css`
              margin-top: 5px;
            `}
          >
            {data?.length > 0 &&
              filterDataNoMain(data)?.map((el) => {
                return (
                  <div key={el.id} css={VideosCss.videos}>
                    <iframe
                      key={el.id}
                      src={`//www.youtube-nocookie.com/embed/${getId(
                        el.youtube
                      )}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      title="Embedded youtube"
                    />
                    <h3>
                      <b>{el.title}</b>
                    </h3>
                    <p>
                      <small
                        dangerouslySetInnerHTML={{
                          __html:
                            el.description.length > 185
                              ? `${el.description.substring(0, 185)}...`
                              : el.description?.replaceAll("<p></p>", "<br/>"),
                        }}
                      />
                    </p>
                  </div>
                );
              })}
          </OwlCarousel>
        )}
      </Container>
    </div>
  );
}
