/** @jsxImportSource @emotion/react */
import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import PropTypes from "prop-types";

BreadCrumbsLink.propTypes = {
  titleBreadcrumbs: PropTypes.array,
};

export default function BreadCrumbsLink({ titleBreadcrumbs }) {
  return (
    <div>
      <Breadcrumbs
        css={css`
          margin: 13px 0;
          font-size: 0.9rem;
          @media only screen and (max-width: 720px) {
            font-size: 0.7rem;
          }
          .MuiBreadcrumbs-separator {
            color: #4285f4;
          }
        `}
        aria-label="breadcrumb"
      >
        {titleBreadcrumbs.map((el, i) => {
          if (el.link) {
            return (
              <Link
                key={i}
                css={css`
                  color: #4285f4;
                `}
                to={el.link}
              >
                {el.title}
              </Link>
            );
          } else {
            return (
              <p
                css={css`
                  color: #000;
                `}
                key={i}
              >
                {el.title}
              </p>
            );
          }
        })}
      </Breadcrumbs>
    </div>
  );
}
