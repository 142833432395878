/** @jsxImportSource @emotion/react */
import React from "react";
import MediaUploader from "../../components/admin/MediaUploader";
import { useSelector, useDispatch } from "react-redux";
import {
  getPortfolio,
  createPortfolio,
  updatePortfolio,
  deletePortfolio,
} from "../../redux/features/admin/news";

export default function NewsAdmin() {
  //   Portfolio
  const loading_portfolio = useSelector(
    (state) => state.newsReducer.loading_portfolio
  );
  const loading_update_portfolio = useSelector(
    (state) => state.newsReducer.loading_update_portfolio
  );
  const portfolio_datas = useSelector(
    (state) => state.newsReducer.portfolio_datas
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPortfolio());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   Portfolio
  const addItemPortfolio = async (event) => {
    await dispatch(createPortfolio(event));
    dispatch(getPortfolio());
  };

  const updateItemPortfolio = async (event) => {
    await dispatch(updatePortfolio(event));
    dispatch(getPortfolio());
  };

  const deleteItemPortfolio = async (e) => {
    await dispatch(deletePortfolio(e));
    dispatch(getPortfolio());
  };

  return (
    <div>
      <MediaUploader
        title="News List"
        data={portfolio_datas}
        loading={loading_portfolio}
        saveDataLoading={loading_update_portfolio}
        formOnDialog={{
          singleImageUpload: {
            show: true,
            title: "Upload Image",
            imageDimension: "1080 x 513",
            maxFile: "2.0 MB",
            supportedFile: "JPG,JPEG,PNG",
          },
          headingTitle: true,
          contentDesription: true,
          date: true,
          category: true,
          youtubeLink: true,
        }}
        addItem={(e) => addItemPortfolio(e)}
        deleteItem={(e) => deleteItemPortfolio(e)}
        saveData={(e) => updateItemPortfolio(e)}
        type="image"
        youtubeOnImage
        imageDimension="1080 x 513"
        maxFile="2.0 MB"
        supportedFile="JPG,JPEG,PNG"
      />
    </div>
  );
}
