/** @jsxImportSource @emotion/react */
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { TextAreaEditorCss } from "../../styles/admin/text-area-editor_css";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";
import { css } from "@emotion/react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
// import DialogTextEditor from "./DialogTextEditor";

TextAreaEditor.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default function TextAreaEditor({
  value = "",
  handleChange,
  loading = false,
}) {
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(loading ? "" : value))
    )
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    handleChange(
      convertToRaw(state.getCurrentContent()).blocks[0].text
        ? draftToHtml(convertToRaw(state.getCurrentContent()))
        : ""
    );
  };

  React.useEffect(() => {
    if (value) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(loading ? "" : value))
        )
      );
    }
  }, [loading, value]);

  // const [openDialogEditor, setOpenDialogEditor] = React.useState(false);

  const TheEditor = (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        toolbar={{
          options: ["inline"],
          inline: { options: ["bold", "italic", "underline"] },
        }}
      />
    </div>
  );

  return (
    <div css={TextAreaEditorCss().self}>
      {loading ? (
        <div
          css={css`
            position: relative;
          `}
        >
          <Skeleton
            animation="wave"
            css={css`
              position: absolute;
              z-index: 99;
              width: 300px;
              top: 65px;
              left: 17px;
            `}
          />
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: ["inline"],
              inline: { options: ["bold", "italic", "underline"] },
            }}
          />
        </div>
      ) : (
        <React.Fragment>
          {TheEditor}
          {/* <img
            src="/icons/admin/fullscreen-icon.svg"
            onClick={() => setOpenDialogEditor(true)}
            alt=""
            className="btn-screen"
          /> */}
          {/* <DialogTextEditor
            open={openDialogEditor}
            content={TheEditor}
            close={() => setOpenDialogEditor(false)}
          /> */}
        </React.Fragment>
      )}
    </div>
  );
}
