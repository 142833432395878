/** @jsxImportSource @emotion/react */
import React from "react";
import BannerTitleBGg from "../../../components/landing/BannerTitleBGg";
import { css } from "@emotion/react";
import BreadCrumbsLink from "../../../components/landing/BreadCrumbsLink";
import Portfolio from "../../../components/landing/Portfolio";
import Contact from "../../../components/landing/Contact";
import { useSelector, useDispatch } from "react-redux";
import {
  getBanner,
  getDetail,
  getPortfolio,
} from "../../../redux/features/landing/company";

export default function CompanyPortfolio() {
  const banner_data = useSelector(
    (state) => state.companyLandingReducer.banner_data
  );
  const loading_banner = useSelector(
    (state) => state.companyLandingReducer.loading_banner
  );

  const detail_data = useSelector(
    (state) => state.companyLandingReducer.detail_data
  );
  const loading_detail = useSelector(
    (state) => state.companyLandingReducer.loading_detail
  );

  const portfolio_data = useSelector(
    (state) => state.companyLandingReducer.portfolio_data
  );
  const loading_portfolio = useSelector(
    (state) => state.companyLandingReducer.loading_portfolio
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBanner());
    dispatch(getDetail());
    dispatch(getPortfolio(6));
  }, [dispatch]);
  const breadcrumbData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Portfolio",
      link: "/portfolio",
    },
    {
      title: "Company",
      link: "",
    },
  ];

  return (
    <div>
      <BannerTitleBGg
        title={detail_data?.title}
        image={
          banner_data?.length > 0 &&
          banner_data?.filter((el) => el.slug === "banner_company_header")[0]
            ?.image
        }
        loadingTitle={loading_detail}
        loadingImage={loading_banner}
      />
      <div
        css={css`
          display: block;
          width: max-content;
          margin: 25px auto 25px auto;
        `}
      >
        <BreadCrumbsLink titleBreadcrumbs={breadcrumbData} />
      </div>
      <Portfolio
        data={portfolio_data}
        loading={loading_portfolio}
        colorTheme="#4285F4"
        bgColor="#ffffff"
        baseLink="/portfolio/company/portfolio/"
        loadHide={(e) => dispatch(getPortfolio(e === "load" ? 999 : 6))}
      />
      <Contact />
    </div>
  );
}
