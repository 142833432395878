/** @jsxImportSource @emotion/react */
import React from "react";
import { Container } from "@mui/system";
import { css } from "@emotion/react";
import { NewsCss } from "../../../styles/landing/home_css";
import { Button, Grid, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import "../../../loader-plugin";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import PropTypes from "prop-types";
import Carousel from "./Carousel";
import { useMediaQuery } from "react-responsive";

News.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  data_spiceitup: PropTypes.array,
  loading_spiceitup: PropTypes.bool,
  detailTextSpiceItUp: PropTypes.string,
  loadHide: PropTypes.func,
};

export default function News({
  data,
  loading,
  data_spiceitup,
  loading_spiceitup,
  detailTextSpiceItUp,
  loadHide,
}) {
  React.useEffect(() => {}, [data, loading]);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // const settingOwl = {
  //   loop: false,
  //   margin: 10,
  //   items: 4,
  //   autoWidth: false,
  //   dots: false,
  //   responsive: {
  //     0: {
  //       items: 2,
  //       autoWidth: true,
  //     },
  //     600: {
  //       items: 4,
  //       autoWidth: false,
  //     },
  //     1000: {
  //       items: 4,
  //       autoWidth: false,
  //     },
  //   },
  // };

  const [moreHide, setMoreHide] = React.useState("more");

  const loadHideFunc = () => {
    loadHide(moreHide);
    setMoreHide(moreHide === "more" ? "hide" : "more");
  };

  return (
    <div css={NewsCss.self}>
      <Container
        css={css`
          padding-bottom: 60px;
        `}
      >
        <h1 align="right">NEWS</h1>
        {!loading ? (
          data?.data?.length > 0 ? (
            <Grid container spacing={2}>
              {data?.data?.map((el) => {
                return (
                  <Grid item md={3} sm={6} xs={6}>
                    <div
                      key={el.id}
                      css={css`
                        @media only screen and (max-width: 720px) {
                          width: 100%;
                        }
                      `}
                    >
                      <div
                        css={css`
                          background-image: url("${el.image}");
                          background-position: center;
                          background-size: cover;
                          width: 100%;
                          height: 190px;
                          @media only screen and (max-width: 720px) {
                            height: 120px;
                          }
                        `}
                      ></div>
                      <h5>{el.category}</h5>
                      <h4>
                        <b>
                          {el.title.length > 55
                            ? `${el.title.substring(0, 55)}...`
                            : el.title}
                        </b>
                      </h4>
                      <p>
                        <small>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `${
                                el.description.length > 160
                                  ? el.description.substring(0, 160) + "..."
                                  : el.description?.replaceAll(
                                      "<p></p>",
                                      "<br/>"
                                    )
                              }`,
                            }}
                          />
                          <Link to={`/news/${el.slug}`}>Readmore</Link>
                        </small>
                      </p>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            // <OwlCarousel
            //   className="owl-theme"
            //   {...settingOwl}
            //   css={css`
            //     margin-top: 5px;
            //   `}
            // >
            //   {data?.data?.map((el) => {
            //     return (
            //       <div
            //         key={el.id}
            //         css={css`
            //           @media only screen and (max-width: 720px) {
            //             width: 280px;
            //           }
            //         `}
            //       >
            //         <div
            //           css={css`
            //             background-image: url("${el.image}");
            //             background-position: center;
            //             background-size: cover;
            //             width: 100%;
            //             height: 190px;
            //           `}
            //         ></div>
            //         <h5>{el.category}</h5>
            //         <h4>
            //           <b>
            //             {el.title.length > 55
            //               ? `${el.title.substring(0, 55)}...`
            //               : el.title}
            //           </b>
            //         </h4>
            //         <p>
            //           <small>
            //             <span
            //               dangerouslySetInnerHTML={{
            //                 __html: `${
            //                   el.description.length > 160
            //                     ? el.description.substring(0, 160) + "..."
            //                     : el.description.replaceAll("<p></p>", "<br/>")
            //                 }`,
            //               }}
            //             />
            //             <Link to={`/news/${el.slug}`}>Readmore</Link>
            //           </small>
            //         </p>
            //       </div>
            //     );
            //   })}
            // </OwlCarousel>
            ""
          )
        ) : (
          <Grid container spacing={2}>
            {[...Array(isMobile ? 1 : 4)].map((el, i) => {
              return (
                <Grid key={i} item md={3} xs={12}>
                  <Skeleton variant="rectangular" className="rect-skeleton" />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Grid>
              );
            })}
          </Grid>
        )}
        {data?.meta?.pagination?.total > 4 ? (
          <Button
            css={css`
              margin-top: 30px;
              margin-left: auto;
              &:hover {
                background-color: #943a17;
              }
            `}
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => loadHideFunc()}
          >
            {moreHide === "more" ? "Load More" : "Hide"}
          </Button>
        ) : (
          ""
        )}
      </Container>
      <div css={NewsCss.spiceItUp}>
        <Container>
          <div
            css={css`
              display: flex;
              align-items: center;
              @media only screen and (max-width: 720px) {
                flex-flow: column-reverse;
              }
            `}
          >
            <div className="text-box">
              <img src="images/logo-spiceitup.png" alt="" />
              <p dangerouslySetInnerHTML={{ __html: detailTextSpiceItUp }} />
              <Link to="/spiceitup">
                <Button variant="outlined" color="neutral" disableElevation>
                  View All
                </Button>
              </Link>
            </div>
            <div className="img-spice">
              {data_spiceitup?.length > 0 ? (
                <Carousel data={data_spiceitup} loading={loading_spiceitup} />
              ) : (
                ""
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
