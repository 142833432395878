/** @jsxImportSource @emotion/react */
import React from "react";
import ArticleTopSection from "../../components/landing/ArticleTopSection";
import { css } from "@emotion/react";
import { Grid, Skeleton, Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Container } from "@mui/system";
import { MasonryCss } from "../../styles/landing/home_css";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { PropTypes } from "prop-types";

PortfolioDetail.protoTypes = {
  data: PropTypes.shape({}),
  loading: PropTypes.bool,
  backLink: PropTypes.string,
};

export default function PortfolioDetail({ data, loading, backLink }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [openModalImgPreview, setOpenModalImgPreview] = React.useState(false);
  const [imgOnModalImgPreview, setImgOnModalImgPreview] = React.useState("");

  const openAndSetModalImage = (img_param) => {
    setImgOnModalImgPreview(img_param);
    setOpenModalImgPreview(true);
  };
  return (
    <div>
      {!loading ? (
        <div>
          <ArticleTopSection
            image={data?.image}
            video={data?.youtube}
            title={data?.title}
            desc={data?.description}
            linkButton={""}
            reverseGrid={false}
          />
          <Container css={MasonryCss.self}>
            <Grid
              spacing={2}
              container
              css={css`
                margin-bottom: 40px;
              `}
            >
              <Grid item md={9} xs={9}>
                {/* BOX 1 */}
                <Grid spacing={2} container>
                  <Grid item md={8} xs={8}>
                    <div
                      css={css`
                        background-image: url(${data?.post_images[0].image});
                      `}
                      onClick={() =>
                        isMobile
                          ? openAndSetModalImage(data?.post_images[0].image)
                          : ""
                      }
                      className="img-masonry"
                    ></div>
                  </Grid>
                  <Grid item md={4} xs={4}>
                    <div
                      css={css`
                        background-image: url(${data?.post_images[1].image});
                      `}
                      onClick={() =>
                        isMobile
                          ? openAndSetModalImage(data?.post_images[1].image)
                          : ""
                      }
                      className="img-masonry"
                    ></div>
                  </Grid>
                </Grid>
                {/* BOX 2 */}
                <Grid
                  spacing={2}
                  container
                  css={css`
                    margin-top: 0px;
                  `}
                >
                  <Grid item md={4} xs={4}>
                    <div
                      css={css`
                        background-image: url(${data?.post_images[2].image});
                      `}
                      onClick={() =>
                        isMobile
                          ? openAndSetModalImage(data?.post_images[2].image)
                          : ""
                      }
                      className="img-masonry"
                    ></div>
                  </Grid>
                  <Grid item md={8} xs={8}>
                    <div
                      css={css`
                        background-image: url(${data?.post_images[3].image});
                      `}
                      onClick={() =>
                        isMobile
                          ? openAndSetModalImage(data?.post_images[3].image)
                          : ""
                      }
                      className="img-masonry"
                    ></div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={3} xs={3}>
                <div
                  css={css`
                    background-image: url(${data?.post_images[4].image});
                  `}
                  onClick={() =>
                    isMobile
                      ? openAndSetModalImage(data?.post_images[4].image)
                      : ""
                  }
                  className="img-masonry img-masonry__tall"
                ></div>
              </Grid>
            </Grid>
          </Container>

          <Container
            css={css`
              margin-bottom: 50px;
            `}
            maxWidth="md"
          >
            <p
              css={css`
                margin-bottom: 30px;
                @media only screen and (max-width: 720px) {
                  font-size: 13px;
                }
              `}
              dangerouslySetInnerHTML={{
                __html: data?.detail?.replaceAll("<p></p>", "<br/>"),
              }}
            ></p>

            <Link
              to={backLink}
              css={css`
                position: relative;
                padding-left: 10px;
                color: #4285f4;
                &:hover {
                  color: #4285f4;
                }
              `}
            >
              <ArrowBackIosNewOutlinedIcon
                css={css`
                  position: absolute;
                  top: 4px;
                  left: -5px;
                  font-size: 13px;
                  color: #4285f4;
                `}
              />
              <span>Back</span>
            </Link>
          </Container>
        </div>
      ) : (
        <Container
          css={css`
            margin-bottom: 25px;
          `}
        >
          <Grid
            container
            spacing={2}
            css={css`
              margin-bottom: 20px;
            `}
          >
            <Grid item md={6} xs={12}>
              <Skeleton variant="rectangular" height={300} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Skeleton width="60%" />
              <Skeleton />
              <Skeleton />
            </Grid>
          </Grid>
          <Skeleton />
          <Skeleton />
          <Skeleton width="60%" />
        </Container>
      )}

      <Modal
        open={openModalImgPreview}
        onClose={() => setOpenModalImgPreview(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          css={css`
            position: absolute;
            top: 45%;
            width: 95%;
            left: 50%;
            transform: translate(-50%, -50%);
            &:focus-visible {
              outline: unset;
            }
          `}
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpenModalImgPreview(false)}
            sx={{
              position: "relative",
              marginLeft: "auto",
              display: "block",
              svg: {
                color: "white",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={imgOnModalImgPreview}
            css={css`
              width: 100%;
            `}
            alt=""
          />
        </Box>
      </Modal>
    </div>
  );
}
