/** @jsxImportSource @emotion/react */
import React from "react";
import ImagesUpload from "../../components/admin/ImagesUpload";
import ContentNoDialog from "../../components/admin/ContentNoDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  getAboutUsDesc,
  updateAboutUsDesc,
  getBanner,
  putImageBanner,
} from "../../redux/features/admin/about-us";

export default function AboutUsAdmin() {
  const description_text = useSelector(
    (state) => state.aboutUsReducer.description_text
  );
  const loading_description = useSelector(
    (state) => state.aboutUsReducer.loading_description
  );
  const loading_update_description = useSelector(
    (state) => state.aboutUsReducer.loading_update_description
  );
  const banner_data = useSelector((state) => state.aboutUsReducer.banner_data);
  const loading_banner = useSelector(
    (state) => state.aboutUsReducer.loading_banner
  );
  const loading_upload = useSelector(
    (state) => state.aboutUsReducer.loading_upload_banner
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAboutUsDesc());
    dispatch(getBanner());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadImage = async (el) => {
    await dispatch(putImageBanner(el));
    dispatch(getBanner());
  };

  const updateDesc = async (text) => {
    await dispatch(updateAboutUsDesc(text));
    dispatch(getAboutUsDesc());
  };

  const data_details_banner = [
    {
      img_dimension: "1155 x 603",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "556 x 411",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "556 x 411",
      max_size: "2.0 MB",
      supported_file: "JPG,JPEG,PNG",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
    {
      img_dimension: "",
      max_size: "",
      supported_file: "",
    },
  ];
  return (
    <div>
      <ImagesUpload
        title="Upload Banner Image"
        data={banner_data?.map((el, i) => {
          return {
            id: el.id,
            image: el.image,
            img_dimension: data_details_banner[i].img_dimension,
            max_size: data_details_banner[i].max_size,
            supported_file: data_details_banner[i].supported_file,
          };
        })}
        loading={loading_upload}
        loadingData={loading_banner}
        uploadFunc={(e) => uploadImage(e)}
      />

      <ContentNoDialog
        loading={loading_description}
        loadingSave={loading_update_description}
        description={{
          show: true,
          value: loading_description ? "" : description_text,
        }}
        saveFunc={(e) => updateDesc(e)}
        resetValue={() => dispatch(getAboutUsDesc())}
      />
    </div>
  );
}
