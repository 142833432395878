/** @jsxImportSource @emotion/react */
import React from "react";
import { Grid, Button, Skeleton } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import BreadCrumbsLink from "./BreadCrumbsLink";
import { Desktop, Mobile } from "../ResponsiveMedia";
import { ArticleTopSectionCss } from "../../styles/landing/globals_css";
import { getId } from "../YoutubeIdGetter";

ArticleTopSection.propTypes = {
  image: PropTypes.string,
  video: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  linkButton: PropTypes.string,
  reverseGrid: PropTypes.bool,
  breadcrumbData: PropTypes.array,
  imgThree: PropTypes.array,
  loadingBanner: PropTypes.bool,
  loadingDetail: PropTypes.bool,
  getInTouchTo: PropTypes.func,
};

export default function ArticleTopSection({
  image,
  video,
  title,
  desc,
  linkButton,
  reverseGrid = false,
  breadcrumbData = [],
  imgThree = [],
  loadingBanner = false,
  loadingDetail = false,
  getInTouchTo,
}) {
  const createMarkupHtml = (text) => {
    return { __html: text };
  };

  let breadcrumbComponent =
    breadcrumbData.length > 0 ? (
      <BreadCrumbsLink titleBreadcrumbs={breadcrumbData} />
    ) : (
      ""
    );

  let imgOrVid = image ? (
    <img
      src={image}
      alt=""
      css={css`
        display: block;
        margin: 0 auto;
        width: 100%;
        box-shadow: 0px 7px 34px -12px rgba(0, 0, 0, 0.12);
        @media only screen and (max-width: 720px) {
          height: 310px;
        }
      `}
    />
  ) : (
    <iframe
      src={video ? `//www.youtube-nocookie.com/embed/${getId(video)}` : ""}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      title="Embedded youtube"
      css={css`
        width: 100%;
        height: 380px;
        @media only screen and (max-width: 720px) {
          height: 230px;
        }
      `}
    />
  );

  let imgThreeDiv =
    imgThree?.length > 0 ? (
      <div className="img-three">
        <div
          css={css`
            background-image: url("${imgThree[0]?.image}");
          `}
          className="img-three__banner"
        />
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={6}>
            <div
              css={css`
                background-image: url("${imgThree[1]?.image}");
              `}
              className="img-three__child"
            />
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <div
              css={css`
                background-image: url("${imgThree[2]?.image}");
              `}
              className="img-three__child"
            />
          </Grid>
        </Grid>
      </div>
    ) : (
      ""
    );

  let ButtonLink = linkButton ? (
    <Link to="">
      <Button
        css={css`
          margin-top: 30px;
          padding: 10px 25px !important;
          &:hover {
            background-color: #943a17;
          }
        `}
        variant="contained"
        color="secondary"
        disableElevation
        onClick={() => getInTouchTo()}
      >
        Get in touch
      </Button>
    </Link>
  ) : (
    ""
  );

  const Grid1 = (
    <Grid
      item
      md={6}
      css={css`
        width: 100%;
      `}
    >
      {loadingBanner || loadingDetail ? (
        <Skeleton variant="rectangular" height={450} />
      ) : (
        <React.Fragment>
          <Mobile>
            <div
              css={css`
                width: fit-content;
                margin: 0 auto;
              `}
            >
              {breadcrumbComponent}
            </div>
          </Mobile>
          {imgThree?.length > 0 ? imgThreeDiv : imgOrVid}{" "}
        </React.Fragment>
      )}
    </Grid>
  );

  const Grid2 = (
    <Grid item md={6}>
      {loadingBanner || loadingDetail ? (
        <React.Fragment>
          <div
            css={css`
              margin-bottom: 30px;
            `}
          >
            <Skeleton />
            <Skeleton />
            <Skeleton width="60%" />
          </div>
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton width="60%" />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Desktop>{breadcrumbComponent}</Desktop>
          <h1
            css={css`
              color: #4285f4;
              font-weight: 600;
              margin-bottom: 15px;
              font-size: 34px;
              @media only screen and (max-width: 720px) {
                font-size: 25px;
              }
            `}
          >
            {title}
          </h1>
          <p
            dangerouslySetInnerHTML={createMarkupHtml(
              desc?.replaceAll("<p></p>", "<br/>")
            )}
            css={css`
              @media only screen and (max-width: 720px) {
                font-size: 13px;
              }
            `}
          ></p>
          {ButtonLink}
        </React.Fragment>
      )}
    </Grid>
  );

  return (
    <div css={ArticleTopSectionCss.self}>
      <Container>
        <Grid
          container
          css={css`
            align-items: top;
          `}
          spacing={3}
        >
          <Desktop>
            {reverseGrid ? Grid2 : Grid1}
            {reverseGrid ? Grid1 : Grid2}
          </Desktop>
          <Mobile>
            {Grid1}
            {Grid2}
          </Mobile>
        </Grid>
      </Container>
    </div>
  );
}
