import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const base_url = "https://api.moleculaindonesia.co.id";

export const getMember = createAsyncThunk(
  "get/memberAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "get",
        url: `${base_url}/api/admin/v1/post?post_type=member`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const createMember = createAsyncThunk(
  "create/memberAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "post",
        url: `${base_url}/api/admin/v1/post`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          title: arg.memberName,
          sub_title: arg.positionJob,
          linkedin: arg.linkedIn,
          description: arg.memberDescription,
          post_type: "member",
          image: arg.image,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const updateMember = createAsyncThunk(
  "update/memberAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    const data_params = {
      title: arg.memberName,
      sub_title: arg.positionJob,
      linkedin: arg.linkedIn,
      description: arg.memberDescription,
      post_type: "member",
      image: arg.image,
    };
    if (data_params.image.includes("http")) {
      delete data_params.image;
    }
    try {
      var config = {
        method: "put",
        url: `${base_url}/api/admin/v1/post/${arg.id}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data_params,
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const deleteMember = createAsyncThunk(
  "delete/memberAdmin",
  async (arg, { getState }) => {
    const token = localStorage.getItem("token");
    try {
      var config = {
        method: "delete",
        url: `${base_url}/api/admin/v1/post/${arg}`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      const data = await response.data;
      return data;
    } catch (error) {
      //   console.log(error);
      return error;
    }
  }
);

export const memberSlice = createSlice({
  name: "memberSlice",
  initialState: {
    member_datas: [],
    loading_member: false,
    loading_update_member: false,
  },
  reducers: {
    addMore: (state) => {},
  },
  extraReducers: {
    //   Member || ----------
    [getMember.pending]: (state, { payload }) => {
      state.loading_member = true;
    },
    [getMember.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        state.member_datas = payload.data;
      } else {
        state.member_datas = [];
      }
      state.loading_member = false;
    },
    [getMember.rejected]: (state, action) => {
      state.loading_member = false;
    },
    [createMember.pending]: (state, { payload }) => {
      state.loading_update_member = true;
    },
    [createMember.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_member = false;
    },
    [createMember.rejected]: (state, action) => {
      state.loading_update_member = false;
    },
    //   ---------- || ----------
    [updateMember.pending]: (state, { payload }) => {
      state.loading_update_member = true;
    },
    [updateMember.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_member = false;
    },
    [updateMember.rejected]: (state, action) => {
      state.loading_update_member = false;
    },
    //   ---------- || ----------
    [deleteMember.pending]: (state, { payload }) => {
      state.loading_update_member = true;
    },
    [deleteMember.fulfilled]: (state, { payload }) => {
      if (payload.code !== "ERR_BAD_REQUEST") {
        // success condition;
      } else {
        // failed condition;
      }
      state.loading_update_member = false;
    },
    [deleteMember.rejected]: (state, action) => {
      state.loading_update_member = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addMore } = memberSlice.actions;

export default memberSlice.reducer;
